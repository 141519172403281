import React, { useEffect } from "react";
import { Chart } from "react-google-charts";
import { http } from "../Services/api.services";
import { useLocation } from "react-router-dom";

const CandleGraphComponent = () => {
    const location = useLocation();
    const _id = location.pathname.split('/')[3]
    const [historyData, setHistoryData] = React.useState([]);
    const [signalData, setsignalData] = React.useState([]);

    const getChartData = async (_id) => {
        try {
            const response = await http.get(`historic_data/latest?stock_id=1afe661d-4d5f-4674-83a2-aea9bb2007f1&interval=60minute&start_date=2024-09-20&end_date=2024-10-25`);
            let _header = [response?.data?.historic_data[0]?.header];
            let _data = response?.data?.historic_data[0]?.data;
            let final_data = _header.concat(_data);
            setHistoryData(final_data);
            let _sheader = [response?.data?.signal_data[0]?.header];
            let _sdata = response?.data?.signal_data[0]?.data;
            let sfinal_data = _sheader.concat(_sdata);
            setsignalData(sfinal_data);
        } catch (e) {
            console.log(`ERROR WHILE FETCHING GRAPH DATA ${e}`);
        }
    }

    useEffect(() => {
        getChartData(_id);
    }, []);

    const chartEvents = [
        {
            eventName: "select",
            callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length === 1) {
                    const [selectedItem] = selection;
                    const dataTable = chartWrapper.getDataTable();
                    const { row, column } = selectedItem;
                    console.log("You selected:", {
                        row,
                        column,
                        value: row !== null ? dataTable.getValue(row, column) : dataTable.getDistinctValues(column),
                    });
                }
            },
        },
        {
            eventName: "error",
            callback: ({ chartWrapper, eventArgs }) => {
                console.error("Error:", eventArgs);
            },
        },
        {
            eventName: "ready",
            callback: ({ chartWrapper }) => {
                console.log("Chart ready:", chartWrapper);
            },
        },
    ];

    const combinedData = [
        ['Time', 'Low', 'Open', 'Close', 'High', 'Y', { role: 'style' }],
        ...historyData.slice(1).map((row, index) => {
            const scatterRow = signalData[index + 1] || [];
            return [
                row[0],                             // Date/Time (string)
                parseFloat(row[1]),                // Low (number)
                parseFloat(row[2]),                // Open (number)
                parseFloat(row[3]),                // Close (number)
                parseFloat(row[4]),                // High (number)
                scatterRow.length > 1 ? parseFloat(scatterRow[1]) : null, // Y (number or null)
                scatterRow.length > 5 ? scatterRow[5] : null,             // Style (string)
            ];
        }),
    ];

    return (
        <Chart
            chartType="ComboChart"
            width="100%"
            height="1000px"
            data={combinedData} // Use combined data
            chartEvents={chartEvents}
            options={{
                legend: { position: "bottom" },
                hAxis: {
                    title: 'X Axis Title', // Title for X-axis
                    gridlines: { count: 0 },
                    format: 'MMM dd', // Format to display date only
                },
                vAxis: {
                    title: 'Y Axis Title', // Title for Y-axis
                    gridlines: {
                        count: 5, // Adjust the number of gridlines
                        color: '#000', // Color of the gridlines (black)
                    },
                    viewWindow: {
                        min: 250, // Replace with your desired minimum value
                        max: 300, // Replace with your desired maximum value
                    },
                    baselineColor: '#000', // Set the baseline color to black
                },
                series: {
                    0: {
                        type: 'candlesticks',
                        color: 'black',
                        candlestick: {
                            fallingColor: { strokeWidth: 2, stroke: "red", fill: "white" }, // No fill for downward candle
                            risingColor: { strokeWidth: 2, stroke: "green", fill: "white" }, // No fill for upward candle
                        },
                    },
                    1: {
                        type: 'scatter',
                        pointSize: 5,
                        annotations: {
                            textStyle: {
                                fontName: 'Times-Roman',
                                fontSize: 18,
                                bold: true,
                                italic: true,
                                // The color of the text.
                                color: '#871b47',
                                // The color of the text outline.
                                auraColor: '#d799ae',
                                // The transparency of the text.
                                opacity: 0.8
                            }
                        }
                    },
                },
                tooltip: { isHtml: true },
            }}
        />
    );
}

export default CandleGraphComponent;
