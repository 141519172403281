import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import reducer from '../store/Reducer/Reducer'
import MarketReducer from '../store/Reducer/MarketReducer'
import UserReducer from '../store/Reducer/UserReducer'
import UserSubsReducer from '../store/Reducer/UserSubsReducer'
import TickerReducer from '../store/Reducer/TickerReducer'
import EventReducer from '../store/Reducer/EventReducer'
import PlanReducer from '../store/Reducer/PlanReducer'
import RankingReducer from '../store/Reducer/RankingReducer'
import FeaturesReducer from '../store/Reducer/FeaturesReducer'
import AdminReducer from '../store/Reducer/AdminReducer'
import ExpiryReducer from '../store/Reducer/ExpiryReducer'
import SLReducer from '../store/Reducer/SLReducer'
import ToolsReducer from '../store/Reducer/ToolsReducer'
import BasketReducer from '../store/Reducer/BasketReducer'
import EquityReducer from './Reducer/EquityReducer'
import OrderReducer from './Reducer/OrderReducer'

const rootReducer = combineReducers({
    market: MarketReducer,
    tools: ToolsReducer,
    optionHome: reducer,
    admin: AdminReducer,
    user: UserReducer,
    userSubs: UserSubsReducer,
    ticks: TickerReducer,
    events: EventReducer,
    plans: PlanReducer,
    features: FeaturesReducer,
    rankings: RankingReducer,
    allCurrentExpiry: ExpiryReducer,
    SL: SLReducer,
    orders: OrderReducer,
    Baskets: BasketReducer,
    equity: EquityReducer
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})