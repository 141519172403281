import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allWatchlists: null,
    currentWatchlist: null,
    newWatchlist: null,
    currentAlgo: null,
    currentAlgoCompany: null,
    allAlgos: null,
    industries: null,
    currentCompanyData: null,
    currentCompanyAlgos: null,
    opnOrderForm: false,
    currentOrderType: 'buy',
    currentSelectedOrder: null,
    openAddStock: false,
    selectedWatchlists: [],
    selectedCompany: null,
    isOrderUpdated: false
};

export const EquityReducer = createSlice({
    name: 'equity',
    initialState,
    reducers: {
        setAllWatchlists: (state, action) => {
            state.allWatchlists = action.payload;
        },
        setCurrentWatchlist: (state, action) => {
            state.currentWatchlist = action.payload
        },
        setNewWatchlist: (state, action) => {
            state.newWatchlist = action.payload
        },
        setCurrentAlgo: (state, action) => {
            state.currentAlgo = action.payload
        },
        setCurrentAlgoCompany: (state, action) => {
            state.currentAlgoCompany = action.payload
        },
        setCurrentCompanyData: (state, action) => {
            state.currentCompanyData = action.payload
        },
        setCurrentCompanyAlgos: (state, action) => {
            state.currentCompanyAlgos = action.payload
        },
        setAllALgos: (state, action) => {
            state.allAlgos = action.payload
        },
        setIndustries: (state, action) => {
            state.industries = action.payload
        },
        setOpenOrderform: (state, action) => {
            state.opnOrderForm = action.payload;
        },
        setCurrentOrderType: (state, action) => {
            state.currentOrderType = action.payload;
        },
        setCurrentSelectedOrder: (state, action) => {
            state.currentSelectedOrder = action.payload;
        },
        setOpenAddStock: (state, action) => {
            state.openAddStock = action.payload;
        },
        setSelectedWatchlists: (state, action) => {
            state.selectedWatchlists = action.payload;
        },
        setSelectedCompany: (state, action) => {
            state.selectedCompany = action.payload;
        },
        setIsUpdatedOrder: (state, action) => {
            state.isOrderUpdated = action.payload
        }
    }
});

export const {
    setAllWatchlists,
    setCurrentWatchlist,
    setOpenOrderform,
    setCurrentSelectedOrder,
    setCurrentOrderType,
    setCurrentCompanyData,
    setCurrentCompanyAlgos,
    setNewWatchlist,
    setIndustries,
    setCurrentAlgo,
    setCurrentAlgoCompany,
    setAllALgos,
    setOpenAddStock,
    setSelectedWatchlists,
    setSelectedCompany,
    setIsUpdatedOrder
} = EquityReducer.actions;

export default EquityReducer.reducer;