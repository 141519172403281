import React, { useEffect } from "react";
import Menu from '@mui/material/Menu';
import { H5, H6, P } from "../Theme/ThemeComponent";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { Badge, Button, Divider, TextField } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { useThemeContext } from "../Theme/ThemeContext";
import DatePickerComponent from "./DatePickerComponent";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from "react-redux";

const _tradesValues = [
    { key: 'buy', label: 'Long' },
    { key: 'sell', label: 'Short' },
]

const _statusValues = [
    { key: 'open', label: 'Open' },
    { key: 'closed', label: 'Close' },
]

const AlgoFilter = ({ filterDate, setFilterDate, filterData, fCount, onResetFilter, setFilterData, isLoading, onApplyFilter, }) => {
    const { theme } = useThemeContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { industries } = useSelector((state) => state.equity);

    const [_indestriesExpanded, setIndustriesExpanded] = React.useState({});

    // UPDATE INDUSTRIS ACCORDIAN 
    const toggleIndustryExpansion = (index) => {
        setIndustriesExpanded((prevState) => ({
            ...prevState,
            [`industry-${index}`]: { open: !prevState[`industry-${index}`]?.open }
        }));
    };

    // ADD OPEN FLAG FOR ALL INDUSTRIES ACCORDIANS 
    React.useEffect(() => {
        if (industries?.length > 0) {
            // Create an object with all industries set to open: true
            const initialExpandedState = {};
            industries.forEach((_, index) => {
                initialExpandedState[`industry-${index}`] = { open: true };
            });
            setIndustriesExpanded(initialExpandedState);
        }
    }, [industries]);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [tradeExpanded, setTradeExpanded] = React.useState(true);
    const [statusExpanded, setStatusExpanded] = React.useState(true);
    const [expanded, setExpanded] = React.useState(true);

    // HANDLE EXAPNSION 
    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    // ACCORDIAN STYLES 
    const accordianStyle = {
        '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
        '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
        boxShadow: 'none',
        backgroundColor: theme.palette.background.white,
        padding: 0,

    }

    const summeryStyle = { padding: 0, minHeight: '0px' };
    const detailStyle = { padding: 1, marginTop: '-20px' }

    const AccordianHeading = ({ heading }) => (
        <P title={heading} font='bold' color={theme.palette.text.charcolGray} />
    );

    const AccordianCheckboxLabel = ({ label }) => (
        <div className="whitespace-nowrap">
            <P title={label} font='bold' color={theme.palette.text.charcolGray} />
        </div>
    )

    // SELECT INDUSTRIES 
    const handleToggle = (industry) => {
        const currentIndex = filterData?.sectors.indexOf(industry?.industry_key);
        const newSelectedSectors = [...filterData?.sectors];

        if (currentIndex === -1) {
            newSelectedSectors.push(industry?.industry_key);
        } else {
            newSelectedSectors.splice(currentIndex, 1);
        }

        // setSelectedWatchlists(newSelectedWatchlists);
        setFilterData((prevData) => ({
            ...prevData,
            sectors: newSelectedSectors
        }));
    };

    return (
        <>
            <IconButton
                aria-label="Filter"
                size="small"
                onClick={handleClick}
            >
                <Badge badgeContent={fCount} color="primary">
                    <FilterAltIcon sx={{ color: theme.palette.icon.primary }} />
                </Badge>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    maxWidth: 500,
                    width: 500,
                    minWidth: 500
                }}
            >
                <div className="w-full  ">
                    <div className="w-full flex items-center justify-between py-2 px-2 lg:px-4">
                        <H6 title='Filter' color={theme.palette.text.charcolGray} />
                        <IconButton onClick={handleClose} aria-label="close" size="small">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <Divider />
                    <div className="w-full py-4 max-h-[40vh] overflow-y-auto flex flex-col items-start gap-2 px-2 lg:px-4">
                        <div className="w-full">
                            <P title='Date' color={theme.palette.text.coolGray} />
                            <DatePickerComponent filterData={filterDate} setFilterData={setFilterDate} />
                        </div>
                        <div className="w-full">
                            <Accordion
                                expanded={tradeExpanded}
                                onChange={() => {
                                    setTradeExpanded((prevExpanded) => !prevExpanded);
                                }}
                                slots={{ transition: Fade }}
                                slotProps={{ transition: { timeout: 400 } }}
                                sx={{
                                    '& .MuiAccordion-region': { height: tradeExpanded ? 'auto' : 0 },
                                    '& .MuiAccordionDetails-root': { display: tradeExpanded ? 'block' : 'none' },
                                    boxShadow: 'none',
                                    backgroundColor: theme.palette.background.white,
                                    padding: 0,

                                }}
                                size='small'
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon fontSize="small" />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={summeryStyle}
                                >
                                    <AccordianHeading heading='Trade' />
                                </AccordionSummary>
                                <AccordionDetails sx={detailStyle}>
                                    <FormGroup size="small">
                                        {_tradesValues ? (
                                            _tradesValues.map((el, idx) => (
                                                <FormControlLabel
                                                    key={idx}
                                                    size="small"
                                                    control={
                                                        <Checkbox
                                                            onChange={() => {
                                                                setFilterData((prevData) => {
                                                                    // Create a copy of the previous state to avoid direct mutation
                                                                    const updatedData = { ...prevData };
                                                                    // Toggle the value of order_types using bracket notation
                                                                    updatedData.order_types[el.key.toLowerCase()] = !updatedData.order_types[el.key.toLowerCase()];
                                                                    // Return the updated state object
                                                                    return updatedData;
                                                                });
                                                            }}
                                                            checked={filterData?.order_types[el.key.toLowerCase()]} // Dynamically access the state
                                                            size="small"
                                                        />
                                                    }
                                                    label={<AccordianCheckboxLabel label={el.label} />}
                                                />
                                            ))
                                        ) : null}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="w-full">
                            <Accordion
                                expanded={statusExpanded}
                                onChange={() => {
                                    setStatusExpanded((prevExpanded) => !prevExpanded);
                                }}
                                slots={{ transition: Fade }}
                                slotProps={{ transition: { timeout: 400 } }}
                                sx={{
                                    '& .MuiAccordion-region': { height: statusExpanded ? 'auto' : 0 },
                                    '& .MuiAccordionDetails-root': { display: statusExpanded ? 'block' : 'none' },
                                    boxShadow: 'none',
                                    backgroundColor: theme.palette.background.white,
                                    padding: 0,

                                }}
                                size='small'
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon fontSize="small" />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={summeryStyle}
                                >
                                    <AccordianHeading heading='Status' />
                                </AccordionSummary>
                                <AccordionDetails sx={detailStyle}>
                                    <FormGroup size="small">
                                        {_statusValues ? (
                                            _statusValues.map((el, idx) => (
                                                <FormControlLabel
                                                    key={idx}
                                                    size="small"
                                                    control={
                                                        <Checkbox
                                                            onChange={() => {
                                                                setFilterData((prevData) => {
                                                                    // Create a copy of the previous state to avoid direct mutation
                                                                    const updatedData = { ...prevData };
                                                                    // Toggle the value of order_types using bracket notation
                                                                    updatedData.statuses[el.key.toLowerCase()] = !updatedData.statuses[el.key.toLowerCase()];
                                                                    // Return the updated state object
                                                                    return updatedData;
                                                                });
                                                            }}
                                                            checked={filterData?.statuses[el.key.toLowerCase()]} // Dynamically access the state
                                                            size="small"
                                                        />
                                                    }
                                                    label={<AccordianCheckboxLabel label={el.label} />}
                                                />
                                            ))
                                        ) : null}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="w-full">
                            <Accordion
                                expanded={expanded}
                                onChange={handleExpansion}
                                slots={{ transition: Fade }}
                                slotProps={{ transition: { timeout: 400 } }}
                                sx={accordianStyle}
                                size='small'
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon fontSize="small" />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    sx={summeryStyle}
                                >
                                    <AccordianHeading heading='Industry' />
                                </AccordionSummary>
                                <AccordionDetails sx={detailStyle}>
                                    {industries ?
                                        industries?.map((element, index) => {
                                            return (
                                                <Accordion
                                                    expanded={_indestriesExpanded[`industry-${index}`]?.open}
                                                    onChange={() => toggleIndustryExpansion(index)}
                                                    slots={{ transition: Fade }}
                                                    slotProps={{ transition: { timeout: 400 } }}
                                                    sx={{
                                                        '& .MuiAccordion-region': { height: _indestriesExpanded[`industry-${index}`]?.open ? 'auto' : 0 },
                                                        '& .MuiAccordionDetails-root': { display: _indestriesExpanded[`industry-${index}`]?.open ? 'block' : 'none' },
                                                        boxShadow: 'none',
                                                        backgroundColor: theme.palette.background.white,
                                                        padding: 0,
                                                    }}
                                                    size='small'
                                                    key={index}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon fontSize="small" />}
                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                        sx={summeryStyle}
                                                    >
                                                        <AccordianHeading heading={element?.sector} />
                                                    </AccordionSummary>
                                                    <AccordionDetails sx={detailStyle}>
                                                        <FormGroup size='small'>
                                                            {element?.industries ?
                                                                element?.industries?.map((industry, idx) => (
                                                                    <FormControlLabel
                                                                        size="small"
                                                                        key={idx}
                                                                        checked={filterData.sectors.indexOf(industry?.industry_key) !== -1}
                                                                        control={<Checkbox
                                                                            size="small"
                                                                            onChange={() => handleToggle(industry)}
                                                                        />
                                                                        }
                                                                        label={<AccordianCheckboxLabel label={industry?.industry} />}

                                                                    />
                                                                ))
                                                                : null}
                                                        </FormGroup>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })
                                        : null}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <Divider />
                    <div className="w-full flex items-center justify-end gap-3 py-4 px-2 lg:px-4">
                        <Button
                            variant="outlined"
                            onClick={() => {
                                handleClose();
                                onResetFilter();
                            }}
                            autoFocus
                            size="small"
                        >
                            Reset
                        </Button>
                        <LoadingButton
                            sx={{ boxShadow: "none" }}
                            // disabled={disable}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                onApplyFilter();
                            }}
                            loading={isLoading}
                        >
                            Apply
                        </LoadingButton>
                    </div>
                </div>
            </Menu>
        </>
    )
}

export default AlgoFilter;