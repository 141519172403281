import React, { useState } from "react";
import { useThemeContext } from "../../Theme/ThemeContext";
import { H4, H5, H6, P } from "../../Theme/ThemeComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from '@mui/material/Button';
import moment from "moment";
import { Box } from "@mui/material";
import CompanyTable from "../../EquityComponents/CompanyTable";
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import { http } from "../../Services/api.services";
import { BASE_URL } from "../../default.config";
import { useDispatch, useSelector } from "react-redux";
import EquityOrderDetails from "../../EquityComponents/EquityOrderDetails";

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 38,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#1890ff',
                ...theme.applyStyles('dark', {
                    backgroundColor: '#177ddc',
                }),
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
        ...theme.applyStyles('dark', {
            backgroundColor: 'rgba(255,255,255,.35)',
        }),
    },
}));

const DetailReport = () => {
    const dispatch = useDispatch();
    const { theme } = useThemeContext();
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [totalRows, setTotalRows] = React.useState(100);
    const [loadingData, setLoadingData] = React.useState(true);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [showInnerOrders, setShowInnerOrders] = React.useState(false);
    const [currentOrder, setCurrentOrder] = React.useState(null);
    const [innerOrders, setInnerOrders] = React.useState([]);

    //  TABLE COLUMN 
    const columns = [
        {
            field: "date_time",
            headerName: "Date & Time",
            minWidth: 180,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedDate = moment(params?.row?.entry_date).format('DD-MM-YYYY');
                const formattedTime = moment(params?.row?.entry_date).format('HH:mm:ss');
                return (
                    <div className="flex w-fit p-2 flex-col items-start justify-start gap-1">
                        <H6
                            title={formattedDate}
                            color={theme.palette.text.charcolGray}
                            font='normal'
                        />
                        <P
                            title={formattedTime}
                            color={theme.palette.text.silverGray}
                            font='bold'
                        />
                    </div>
                );
            },
        },
        {
            field: "company", // Change field name here
            headerName: "Company",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "type", // Change field name here
            headerName: "Type",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "quantity", // Change field name here
            headerName: "Quantity",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "investment_pl", // Change field name here
            headerName: "Investment P/L",
            renderHeader: () => (
                <Box textAlign="center">
                    <div className="flex w-fit items-center justify-center gap-1">
                        <H6 title={'Investment'} color={theme.palette.text.charcolGray} />
                        <H6 title={'P'} color={theme.palette.text.green} />
                        <H6 title={'/'} />
                        <H6 title={'L'} color={theme.palette.text.red} />
                    </div>
                </Box>
            ),
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? params?.value?.toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
        {
            field: "investment_roi", // Change field name here
            headerName: "Investment ROI",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? Math.abs(params.value).toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
        {
            field: "investment_beta", // Change field name here
            headerName: "Investment Beta",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "position_pl", // Change field name here
            headerName: "Position P/L",
            renderHeader: () => (
                <Box textAlign="center">
                    <div className="flex w-fit items-center justify-center gap-1">
                        <H6 title={'Position'} color={theme.palette.text.charcolGray} />
                        <H6 title={'P'} color={theme.palette.text.green} />
                        <H6 title={'/'} />
                        <H6 title={'L'} color={theme.palette.text.red} />
                    </div>
                </Box>
            ),
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? params?.value?.toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
        {
            field: "my_algo", // Change field name here
            headerName: "My Algo",
            type: "number",
            minWidth: 110,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? params?.value?.toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
    ];

    // GO BACK TO THE PREVIOUS PAGE 
    const goBack = () => {
        navigate(-1);
    };

    // FETCG INNER ORDER
    const fetchInnerorder = async (_order) => {
        try {
            console.log('heyy');

            setShowInnerOrders(true);
            setCurrentOrder(_order);
            // const response = await http.get(`/orders/private/inner/${_order?.id}`);
            // if (response?.status == 200) {
            //     setExchangeOrders(response?.data);
            // }
        } catch (e) {
            console.log("ERROR WHILE FETCHIN INNER ORDER" + e);
        }
    };

    return (
        <>
            <div className="w-full flex-1 flex flex-col gap-4  p-4">
                <div style={{ background: theme.palette.background.white }} className="w-full flex-1 p-4 flex flex-col gap-6  border border-[#9E9E9E24]">
                    <div className="flex items-center gap-2 lg:gap-5">
                        <div className="flex items-center gap-3 lg:gap-4">
                            <ArrowBackIcon
                                sx={{ cursor: "pointer" }}
                                onClick={goBack}
                            />
                            <div className="flex items-center gap-1">
                                <H4
                                    title={'Portfolio Report > Details'}
                                    color={theme.palette.text.charcolGray}
                                    font="bold"
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-3 lg:gap-2">
                            <Button
                                variant="outlined"
                                size="small"
                            >
                                Daily
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                            >
                                Weekly
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                            >
                                Monthly
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                            >

                                Quaterly
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                            >
                                Yearly
                            </Button>
                        </div>
                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                            <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                            <H6
                                title={'My Algo'}

                                color={theme.palette.text.blue} />
                        </Stack>
                    </div>
                    <div className="w-full flex items-center justify-between p-4 lg:p-6 bg-[#5367FF1A]">
                        <div className="">
                            <H5
                                title={'Date & Time'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'August 2024 - August 2023'}
                                color={theme.palette.text.charcolGray}
                            />
                        </div>
                        <div className="">
                            <div className="flex items-center ">
                                <H5
                                    title={'Investment'}
                                    color={theme.palette.text.coolGray}
                                />
                                &nbsp;
                                <H5
                                    title={'P'}
                                    color={theme.palette.text.green}
                                />
                                <H5
                                    title={'/'}
                                    color={theme.palette.text.coolGray}
                                />
                                <H5
                                    title={'L'}
                                    color={theme.palette.text.red}
                                />
                            </div>
                            <H6
                                title={'7421'}
                                color={theme.palette.text.green}
                            />
                        </div>
                        <div className="">
                            <H5
                                title={'Investment ROI'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'7421'}
                                color={theme.palette.text.green}
                            />
                        </div>
                        <div className="">
                            <H5
                                title={'Investment Beta'}
                                color={theme.palette.text.coolGray}
                            />
                            <H6
                                title={'03'}
                                color={theme.palette.text.charcolGray}
                            />
                        </div>
                        <div className="">
                            <div className="flex items-center ">
                                <H5
                                    title={'Position'}
                                    color={theme.palette.text.coolGray}
                                />
                                &nbsp;
                                <H5
                                    title={'P'}
                                    color={theme.palette.text.green}
                                />
                                <H5
                                    title={'/'}
                                    color={theme.palette.text.coolGray}
                                />
                                <H5
                                    title={'L'}
                                    color={theme.palette.text.red}
                                />
                            </div>
                            <H6
                                title={'7462'}
                                color={theme.palette.text.green}
                            />
                        </div>
                        <div className="">
                            <div className="flex items-center ">
                                <H5
                                    title={'Total'}
                                    color={theme.palette.text.coolGray}
                                />
                                &nbsp;
                                <H5
                                    title={'P'}
                                    color={theme.palette.text.green}
                                />
                                <H5
                                    title={'/'}
                                    color={theme.palette.text.coolGray}
                                />
                                <H5
                                    title={'L'}
                                    color={theme.palette.text.red}
                                />
                            </div>
                            <H6
                                title={'7491'}
                                color={theme.palette.text.green}
                            />
                        </div>
                    </div>
                    <div className="w-full flex-1 flex-col flex">
                        <CompanyTable
                            columns={columns}
                            rows={[]}
                            isPagination={true}
                            setLimit={setLimit}
                            limit={limit}
                            skip={skip}
                            setSkip={setSkip}
                            totalRows={totalRows}
                            onRowClickFunction={(params) => {
                                // navigate(`/portfolio/detailed-report/`)
                                fetchInnerorder(params.row);
                            }}
                        />
                    </div>

                </div>
            </div>
        </>
    )
}

export default DetailReport;