import React, { useEffect } from "react";
import AlgoCard from "../../EquityComponents/AlgoCard";
import { http } from "../../Services/api.services";
import { BASE_URL } from "../../default.config";
import Skeleton from "@mui/material/Skeleton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from "react-redux";
import { setAllALgos } from "../../store/Reducer/EquityReducer";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { useThemeContext } from "../../Theme/ThemeContext";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { InputLabel } from "@mui/material";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Algorihtms = () => {
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const { allAlgos } = useSelector((state) => state.equity);
  const [allAlgorithms, setAllAlgorithms] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sort_by, setSortBy] = React.useState('roi')
  const [currentAlgoType, setCurrentAlgoTypep] = React.useState(0);
  const skeletons = Array.from({ length: 12 });
  const [age, setAge] = React.useState('');
  const [totalAlgoCount, setTotalAlgoCount] = React.useState(100)
  const [limit, setLimit] = React.useState(50)
  const [skip, setSkip] = React.useState(0)
  const [page, setPage] = React.useState(1);

  const colors = [
    "bg-[#5367FF0D]",
    "bg-[#F0AD000D]",
    "bg-[#00B3860D]",
    "bg-[#D740400D]",
  ];

  // GET ALL ALGORITHMS

  React.useEffect(() => {
    getAllAlgorithms(skip, limit, sort_by);
  }, [skip, limit]);

  // MY ALGO IMPLIMENTATION PENDING   
  const handleMyAlgoChange = (event, newValue) => {
    setCurrentAlgoTypep(newValue);
    getAllAlgorithms(0, 20, newValue);
  };

  // GET ALL ALGORITHMS
  const getAllAlgorithms = async (skip, limit, sort_by) => {
    try {
      const _body = {
        sort_by: '',
        limit: limit,
        offset: skip,
        sort_by: sort_by
      }
      const response = await http.post(`${BASE_URL}algo`, _body);
      setIsLoading(false);
      if (response.status == 200) {
        dispatch(setAllALgos(response?.data))
      }
    } catch (e) {
      setIsLoading(false);
      console.log(`ERROR WHILE FETCHING ALGORITHMS ${e}`);
    }
  };

  // Handle page change function
  const handlePageChange = (event, value) => {
    setPage(value); // Update current page state
    setSkip(value * limit - limit);
  };

  return (
    <>
      <div className="w-full flex-1 flex flex-col overflow-y-auto  p-2 lg:p-4 mx-auto">
        <div style={{ background: theme.palette.background.white }} className="w-full relative flex-1 flex flex-col p-4 mx-auto ">
          <div className="w-full flex items-center justify-between gap-4 pb-8 ">
            <Tabs
              //   value={value}
              value={currentAlgoType}
              onChange={handleMyAlgoChange}
              aria-label="basic tabs example"
            >
              <Tab label="All Algo" {...a11yProps(0)} />
              <Tab label="My Algo" {...a11yProps(1)} />
            </Tabs>

            <div className=" flex items-center gap-1 lg:gap-2">
              {/* <SwapVertIcon sx={{color:theme.palette.text.coolGray}} />  */}
              <SwapVertIcon sx={{ color: theme.palette.icon.primary }} />
              <FormControl sx={{ width: 180 }} size="small">
                <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
                <Select
                  id="demo-select-small"
                  value={sort_by}
                  label="sort by"
                  size="small"
                  onChange={(e) => {
                    const value = e?.target?.value
                    setSortBy(value)
                    getAllAlgorithms(0, 20, value)
                  }}
                >
                  <MenuItem value={'roi'}>Top ROI</MenuItem>
                  <MenuItem value={'selected_count'}>High Frequency</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="w-full flex flex-1  flex-wrap justify-start gap-4 ">
            {/* CARD  */}
            {!allAlgos
              ? skeletons.map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  width={1500}
                  height={160}
                />
              ))
              : allAlgos?.length > 0 ? allAlgos?.map((algo, index) => {
                const colorIndex = index % colors.length; // This ensures the colors repeat every 4 cards
                return (
                  <AlgoCard
                    key={index}
                    algo={algo}
                    bg={colors[colorIndex]}
                  // bg={algo?.color}
                  />
                );
              }) : <div className="flex items-center justify-center w-full h-full">
                No Algoruithms Found
              </div>}
          </div>
          <div style={{ background: theme.palette.background.white }} className="w-fit fixed hidden shadow rounded right-10 bottom-10 z-50  items-center justify-end p-4 lg:p-2 px-8">
            <Stack spacing={2}>
              <Pagination
                count={totalAlgoCount / limit}
                page={page}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default Algorihtms;
