import { Button, Divider, IconButton } from "@mui/material";
import { green, red, blue } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import PositionTotal from "./PositionTotal";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";
import { useMarketContext } from "../Contexts/MarketContext/MarketContext";
import { useSelector } from "react-redux";

const PositionButton = styled(LoadingButton)(({ theme }) => ({
	minWidth: "22px",
	height: "24px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	fontSize: 12,
	borderRadius: 2,
}));
const OpenOrders = ({
	data,
	currentPosition,
	onModify,
	onCancel,
	placingOrder,
	activeSL,
}) => {
	// const market = useMarketContext()
	const market = useSelector(state => state.market)
	const returnLTP = (instrument) => {
		// console.log(market.positions)
		return market.positions && market.positions?.[instrument]
			? market.positions?.[instrument]?.last_price
			: 0;
	};

	return (
		<div className="lg:h-full w-full  flex flex-col border-l-[0.5px] border-gray-100 min-h-[300px]">
			<div className="h-full overflow-auto px-2 pb-20 flex-1">
				{data ? (
					data?.length ? (
						data?.map((order, index) => (
							<div
								className={
									"px-3 py-2 hover:shadow group transition ease-in-out duration-100 hover:bg-white "
								}
								key={index}
								style={{ borderBottom: "1px solid #eee" }}
							>
								<div className={"flex justify-between mb-1 h-7 items-center"}>
									<div className="flex gap-2">
										<p className="text-[#373737] font-semibold text-xs tracking-wide">
											QTY.{" "}
											<span className="text-blue-600">
												{order?.quantity || 0}
											</span>
										</p>
									</div>
									<div
										className={`gap-2 ${order === currentPosition ? "flex" : "hidden"
											} transition ease-in-out duration-100 ${!1 ? "group-hover:hidden" : "group-hover:flex"
											}`}
									>
										<PositionButton
											size="small"
											variant="contained"
											color="info"
											onClick={() => onModify(order)}
										>
											MODIFY
										</PositionButton>
										<PositionButton
											loading={placingOrder}
											size="small"
											variant="contained"
											color="warning"
											onClick={() => onCancel(order)}
										>
											CANCEL
										</PositionButton>
									</div>
								</div>
								<div className="flex justify-between mb-1 py-1">
									<p
										className={
											"text-blue-600 font-semibold text-sm tracking-wide"
										}
									>
										{order?.tradingsymbol}
									</p>
								</div>
								<div className="w-full flex items-center flex-wrap  gap-3 py-1">
									<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px]  text-[11px] tracking-wide">
										LIMIT{" "}
										<span className="text-black-600 font-bold">
											{order?._limit?.toFixed(2)}
										</span>
									</p>
									<p className="text-[#373737] font-semibold uppercase bg-gray-100 p-1 px-2 rounded-[2px] text-[11px] tracking-wide">
										LTP <span className="text-blue-600 font-bold">{returnLTP(order?.instrument_token)?.toLocaleString("en-IN", { maximumFractionDigits: 2 })}</span>
									</p>
								</div>
							</div>
						))
					) : (
						<div className="w-full h-full flex justify-center items-center">
							<p>NO OPEN ORDERS!</p>
						</div>
					)
				) : (
					<div className="w-full h-full">
						{[1, 2, 3, 4, 5].map((count) => (
							<div
								key={count}
								className="px-3 py-2 lg:max-w-[350px] max-w-full transition ease-in-out duration-100 hover:bg-white"
								style={{ borderBottom: "1px solid #eee" }}
							>
								<div className="flex justify-between mb-1 h-7 items-center">
									<Skeleton
										variant="text"
										width={35}
										sx={{ fontSize: "1rem" }}
									/>
									<div
										className={`gap-2 transition ease-in-out duration-100 flex`}
									>
										<Skeleton variant="rectangular" width={65} height={30} />
										<Skeleton variant="rectangular" width={65} height={30} />
									</div>
								</div>
								<div className="flex justify-between mb-1">
									<Skeleton
										variant="text"
										width={"80%"}
										sx={{ fontSize: "1rem" }}
									/>
									<Skeleton
										variant="text"
										width={50}
										sx={{ fontSize: "1rem" }}
									/>
								</div>
								<div className="flex justify-between mb-1">
									<div className="flex gap-4">
										<Skeleton
											variant="text"
											width={35}
											sx={{ fontSize: "1rem" }}
										/>
										<Skeleton
											variant="text"
											width={35}
											sx={{ fontSize: "1rem" }}
										/>
										<Skeleton
											variant="text"
											width={35}
											sx={{ fontSize: "1rem" }}
										/>
									</div>
									<Skeleton
										variant="text"
										width={75}
										sx={{ fontSize: "1rem" }}
									/>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default OpenOrders;
