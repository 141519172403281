import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import moment from "moment/moment";
import OrdersTable from "../PublicComponents/OrdersTable";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const showIndiaTime = (time) => {
    return new Date(time).getTime() + 19800000;
};

export default function EquityOrderDetails(props) {
    const columns = [
        {
            field: "createdat",
            headerName: <h6 className="font-semibold ">Created At</h6>,
            minWidth: 200,
            flex: 1,
            valueGetter: (params) => {
                return moment(showIndiaTime(params?.value)).format(
                    "DD/MM/YYYY HH:mm a"
                );
            },
        },
        {
            field: "tradingsymbol",
            headerName: <h6 className="font-semibold ">Trading Symbol</h6>,
            minWidth: 220,
            flex: 1,
            // align:'center'
        },
        {
            field: "transaction_type",
            headerName: <h6 className="font-semibold ">Txn Type</h6>,
            minWidth: 100,
            flex: 1,
            // align:'center'
        },
        {
            field: "order_type",
            headerName: <h6 className="font-semibold ">Order Type</h6>,
            flex: 1,
            minWidth: 100,
            // align:'center'
        },
        {
            field: "quantity",
            headerName: <h6 className="font-semibold ">Quantity</h6>,
            minWidth: 100,
            flex: 1,
            // align:'center'
        },
        {
            field: "price",
            headerName: <h6 className="font-semibold ">Price</h6>,
            minWidth: 100,
            flex: 1,
            // align:'center'
            valueGetter: (params) => {
                // console.log(params)
                if (params.value || params.valeu == 0) {
                    return params.value;
                } else {
                    if (!params?.value) {
                        return "--";
                    } else return params?.value;
                }
            },
        },
        {
            field: "strike",
            headerName: <h6 className="font-semibold ">Index Spot</h6>,
            minWidth: 100,
            flex: 1,
            // align:'center'
        },
        {
            field: "status",
            headerName: <h6 className="font-semibold ">Status</h6>,
            minWidth: 300,
            // flex: 1,
            valueGetter: (params) => {
                if (params?.row?.response?.error) {
                    return params?.row?.response?.error;
                } else {
                    if (!params?.value) {
                        return "--";
                    } else return params?.value;
                }
            },
            // align:'center'
        },
    ];

    return (
        <Dialog
            fullScreen
            open={props.open}
            onClose={() => props.onClose()}
            TransitionComponent={Transition}
        >
            <AppBar color="primary" sx={{ position: "relative" }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => props.onClose()}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {props.masterOrder?.tradingsymbol || "ORDER DETAILS"}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className="flex-1 flex overflow-auto px-4 py-6 relative">
                <div className="flex-1 shadow">
                    <OrdersTable
                        data={props.orders}
                        columns={columns}
                        exchange={true}
                        rowClick={(order) => console.log(order)}
                    />
                </div>
            </div>
        </Dialog>
    );
}
