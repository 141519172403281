import React from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { H5, H6, P } from "../Theme/ThemeComponent";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { Badge, Button, Divider, TextField } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { useThemeContext } from "../Theme/ThemeContext";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DatePickerComponent from "./DatePickerComponent";
import EquityOptionSelect from "./EquityOptionSelect";


const EquityOrderFilter = ({ filterDate, fCount, setFilterDate, filterData, handleUpdateData, setFilterData, isLoading, onApplyFilter, resetFilter }) => {
    const { theme } = useThemeContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="delete"
                color="primary"
                onClick={handleClick}
            >
                <Badge badgeContent={fCount} color="primary">
                    <FilterAltIcon sx={{ color: theme.palette.icon.primary }} />
                </Badge>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    maxWidth: 350
                }}
            >
                <div className="w-full">
                    <div className="w-full px-2 lg:px-4 flex items-center justify-between py-2">
                        <H6 title='Filter' color={theme.palette.text.charcolGray} />
                        <IconButton onClick={handleClose} aria-label="close" size="small">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <Divider />
                    <div className="w-full py-4 px-2 lg:px-4 max-h-[50vh] overflow-y-auto flex flex-col items-start gap-2">
                        <div className="w-full">
                            <DatePickerComponent filterData={filterDate} setFilterData={setFilterDate} />
                        </div>
                        <div className="w-full">
                            <EquityOptionSelect
                                Heading={'Transection'}
                                options={[
                                    {
                                        value: 'buy',
                                        label: 'Buy'
                                    },
                                    {
                                        value: 'sell',
                                        label: 'Sell'
                                    },
                                ]}
                                data={filterData?.transaction_type}
                                onUpdateState={(e) => {
                                    handleUpdateData(e, 'transaction_type')
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <EquityOptionSelect
                                Heading={'Order'}
                                options={[
                                    {
                                        value: 'market',
                                        label: 'Market'
                                    },
                                    {
                                        value: 'limit',
                                        label: 'Limit'
                                    },
                                ]}
                                data={filterData?.order_type}
                                onUpdateState={(e) => {
                                    handleUpdateData(e, 'order_type')
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <EquityOptionSelect
                                Heading={'Status'}
                                options={[
                                    {
                                        value: 'completed',
                                        label: 'Completed'
                                    },
                                    {
                                        value: 'success',
                                        label: 'Success'
                                    },
                                    {
                                        value: 'failed',
                                        label: 'Failed'
                                    },
                                    {
                                        value: 'rejected',
                                        label: 'Rejected'
                                    },
                                ]}
                                data={filterData?.status}
                                onUpdateState={(e) => {
                                    handleUpdateData(e, 'status')
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <EquityOptionSelect
                                Heading={'Trigger Mode'}
                                options={[
                                    {
                                        value: 'self',
                                        label: 'Self'
                                    },
                                    {
                                        value: 'target',
                                        label: 'Target'
                                    },
                                    {
                                        value: 'stop_loss',
                                        label: 'Stop Loss'
                                    },
                                ]}
                                data={filterData?.exit_mode}
                                onUpdateState={(e) => {
                                    handleUpdateData(e, 'exit_mode')
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <EquityOptionSelect
                                Heading={'Product'}
                                options={[
                                    {
                                        value: 'intraday',
                                        label: 'Intraday'
                                    },
                                    {
                                        value: 'delivery',
                                        label: 'Delivery'
                                    },
                                ]}
                                data={filterData?.product_type}
                                onUpdateState={(e) => {
                                    handleUpdateData(e, 'product_type')
                                }}
                            />
                        </div>
                    </div>
                    <Divider />
                    <div className="w-full flex items-center justify-end gap-3 py-4 px-2 lg:px-4">
                        <Button
                            variant="outlined"
                            onClick={() => {
                                resetFilter();
                            }}
                            autoFocus
                            size="small"
                        >
                            Reset
                        </Button>
                        <LoadingButton
                            sx={{ boxShadow: "none" }}
                            // disabled={disable}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                onApplyFilter();
                            }}
                            loading={isLoading}
                        >
                            Apply
                        </LoadingButton>
                    </div>
                </div>
            </Menu>
        </>
    )
}

export default EquityOrderFilter;