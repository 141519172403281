import React, {
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { bufferTime, fromEvent } from "rxjs";
import { setAllMarketData, setBasketPositionsData, setInstrumentsData, setMarketCurrentIndex, setPositionsData, setMarketIndexData, setOIFactor } from "../../store/Reducer/MarketReducer";
import { setBasketPositions } from "../../store/Reducer/BasketReducer";

const wsxWorker = new Worker("/websocket.worker.js");
let wsObservable$ = fromEvent(wsxWorker, "message");

// const plWorker = new Worker('/pl.worker.js')
// let pl_observable$ = fromEvent(plWorker, 'message')

// GET EVENT FOR POSISITIONS AND OPEN ORDERS
const MarketStateContext = createContext();

export const useMarketContext = () => {
	return useContext(MarketStateContext);
};

let foreground = true;
const updateForeground = (value) => {
	foreground = value;
};

export const MarketStateProvider = ({ children }) => {
	const dispatch = useDispatch();
	const market = useSelector((state) => state.market);
	const [marketState, setMarketState] = useState({ ...market });
	const [isMarketOpen, setIsMarketOpen] = useState();
	let oiFactor = 0

	useEffect(() => {
		function handleVisibilityChange() {
			if (document.hidden) {
				wsxWorker.postMessage({ status: "pause" });
				updateForeground(false);
			} else {
				wsxWorker.postMessage({ status: "resume" });
				updateForeground(true);
			}
		}
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	// NEW WAY TO UPDATE REDUX FROM WEB WORKER
	useEffect(() => {
		let obj = {}
		/* WITH BUFFER */
		wsObservable$.pipe(bufferTime(250)).subscribe((event) => {
			if (event.length) {
				try {
					for (let index = 0; index < event.length; index++) {
						obj[event[index].data.index] = JSON.parse(event[index].data.data)
						// if (event[index].data.index === 'basketPositions') {
						// 	console.log(JSON.parse(event[index].data.data))
						// }
						if (index == event.length - 1) {
							// console.log(obj)
							for (let i in obj) {
								if (obj[i]) {
									setMarketIndex(i, obj[i])
								}
							}
						}
					}
					// setMarket(_market)
				} catch (e) {
					console.log(`ERROR WHILE SETTING DATA FROM WEB WORKER`, e);
				}
			}
		});

		return () => {
			wsxWorker.terminate();
		};
	}, []);

	// useEffect(() => {
	// 	let _market;
	// 	/* WITH BUFFER */
	// 	wsObservable$.pipe(bufferTime(100)).subscribe((event) => {
	// 		if (!_market) {
	// 			// _market = { ...market };
	// 			// _market = market
	// 			_market = { ...marketState };
	// 		}
	// 		if (event.length) { 
	// 			try {

	// 				for (let index = 0; index < event.length; index++) {
	// 					console.log(_ev.data)
	// 					// if(_ev.data.index == 'nifty') {
	// 					// 	console.log(JSON.parse(_ev.data.data))	
	// 					// }

	// 					_market[_ev.data.index] = JSON.parse(_ev.data.data)

	// 					if (index == event.length - 1) {
	// 						// console.log(data);
	// 						// console.log( 'marlket ---');
	// 						// dispatch(setAllMarketData(_market)); 

	// 						// setMarket({ ..._market });
	// 						// dispatch(setAllMarketData({ ..._market }))
	// 						// setMarket(_market)
	// 					}
	// 				}
	// 				// setMarket(_market)
	// 			} catch (e) {
	// 				console.log(`ERROR WHILE SETTING DATA FROM WEB WORKER`, e);
	// 			}
	// 		}
	// 	});

	// 	return () => {
	// 		wsxWorker.terminate();
	// 	};
	// }, []);

	const setOIFactorContext = (factor) => {
		dispatch(setOIFactor(factor))
	}

	// useEffect(() => {
	//     console.count('MARKET UPDATED')
	// }, [market])

	const setMarketPositions = (positions) => {
		try {
			if (positions && positions.length) {
				// Clone the marketState and positions to ensure they are mutable
				const _market = {
					...marketState,
					positions: { ...marketState.positions }, // Ensure positions object is cloned
				};

				for (let index = 0; index < positions.length; index++) {
					const pos = positions[index];
					_market.positions[pos.instrumenttoken] = {}; // Add new position safely
				}

				// Dispatch the updated positions data
				dispatch(setPositionsData(_market.positions));

				return _market.positions;
			}

			return market.positions;
		} catch (e) {
			console.log(`ERROR WHILE UPDATING POSITION LTP - ${e}`);
		}
	};

	// const setMarketBasketPositions = (basketPositions) => {
	// 	console.log('basketPositions -- ', basketPositions)
	// 	try {
	// 		if (basketPositions && basketPositions.length) {
	// 			const _market = { ...marketState };
	// 			console.log(_market, 'market state redux!');

	// 			for (let index = 0; index < basketPositions.length; index++) {
	// 				const pos = basketPositions[index];
	// 				const posOrders = pos.orders;
	// 				const openOrders = pos.open_orders;
	// 				for (let j = 0; j < posOrders.length; j++) {
	// 					console.log(posOrders, 'posoders');
	// 					const order = posOrders[j];
	// 					console.log(order, 'ord vas--');
	// 					console.log(!_market.basketPositions[order.instrument_token], '_merket bp');

	// 					if (!_market.basketPositions[order.instrument_token])
	// 						_market.basketPositions[order.instrument_token] = {};
	// 				}
	// 				for (let index = 0; index < openOrders.length; index++) {
	// 					const order = openOrders[index];
	// 					if (!_market.basketPositions[parseInt(order.instrument_token)])
	// 						_market.basketPositions[parseInt(order.instrument_token)] = {};
	// 				}
	// 			}
	// 			// dispatch(setAllMarketData({ ..._market }))
	// 			dispatch(setBasketPositionsData(_market?.basketPositions))
	// 			// setMarket({ ..._market });
	// 		}
	// 		// console.log(_market.basketPositions)
	// 		// return _market.basketPositions;
	// 		return market.basketPositions;

	// 	} catch (e) {
	// 		console.log(`ERROR WHILE UPDATING BASKET POSITION LTP - ${e}`);
	// 	}
	// };

	const setMarketBasketPositions = (basketPositions) => {
		return new Promise((resolve, reject) => {
			try {
				const _basketPositions = { ...market.basketPositions }
				if (basketPositions && basketPositions.length) {
					for (let index = 0; index < basketPositions.length; index++) {
						const pos = basketPositions[index];
						const posOrders = pos.orders;
						const openOrders = pos.open_orders;
						for (let j = 0; j < posOrders.length; j++) {
							const order = posOrders[j];
							if (!_basketPositions[order.instrument_token]) {
								_basketPositions[order.instrument_token] = {}; // Safely add new property
							}
						}
						for (let k = 0; k < openOrders.length; k++) {
							const order = openOrders[k];
							if (!_basketPositions[parseInt(order.instrument_token)]) {
								_basketPositions[parseInt(order.instrument_token)] = {}; // Safely add new property
							}
						}
						if (index == basketPositions.length - 1) {
							dispatch(setBasketPositionsData(_basketPositions));
							resolve(_basketPositions)
						}
					}
				}
			}
			catch (e) {
				console.log(`ERROR WHILE SETTING BASKET POSITIONS -- ${e}`, e)
				reject(e)
			}
		})
	}

	const _setMarketBasketPositions = (basketPositions) => {
		try {
			if (basketPositions && basketPositions.length) {
				const _market = {
					...marketState,
					basketPositions: { ...marketState.basketPositions } // Clone to ensure extensibility
				};
				for (let index = 0; index < basketPositions.length; index++) {
					const pos = basketPositions[index];
					const posOrders = pos.orders;
					const openOrders = pos.open_orders;
					for (let j = 0; j < posOrders.length; j++) {
						const order = posOrders[j];
						if (!_market.basketPositions[order.instrument_token]) {
							_market.basketPositions[order.instrument_token] = {}; // Safely add new property
						}
					}
					for (let k = 0; k < openOrders.length; k++) {
						const order = openOrders[k];
						if (!_market.basketPositions[parseInt(order.instrument_token)]) {
							_market.basketPositions[parseInt(order.instrument_token)] = {}; // Safely add new property
						}
					}
				}
				// Dispatch updated state
				dispatch(setBasketPositionsData(_market.basketPositions));

				// return _market.basketPositions;
			}

			return market.basketPositions;

		} catch (e) {
			console.log(`ERROR WHILE UPDATING BASKET POSITION LTP - ${e}`);
		}
	};

	const addMultipleBasketInstruments = (instruments) => {
		try {

			const _bp = { ...market.basketPositions };
			for (let index = 0; index < instruments.length; index++) {
				const instrument = instruments[index];
				if (!_bp[instrument]) {
					_bp[instrument] = {}
				}
				if (index === instruments.length - 1) {
					const _params = {
						updateBasketPositions: true,
						basketPositions: _bp,
					};
					// Dispatch updated basket positions
					dispatch(setBasketPositionsData(_bp));
					// Update WebSocket
					updateBasketPositionsWS(_params);
				}
			}
		}
		catch (e) {
			console.log('ERROR WHILE ADDING MULTIPLE BASKET POSITIONS', e)
		}
	}

	const appendBasketPositions = (instrument) => {
		try {
			// Log the instrument to check its value
			// console.log("Instrument: ", instrument);

			if (!instrument || isNaN(instrument)) {
				throw new Error("Invalid instrument passed: " + instrument);
				// console.log("Invalid instrument passed: " + instrument);
				// return 
			}

			// Clone the current marketState
			const _market = { ...marketState };

			// Clone basketPositions
			const updatedBasketPositions = { ..._market.basketPositions };

			// Check if the instrument is not already present in basketPositions
			if (!updatedBasketPositions[instrument]) {
				updatedBasketPositions[instrument] = {};

				// Update local _market object
				_market.basketPositions = updatedBasketPositions;

				const _params = {
					updateBasketPositions: true,
					basketPositions: updatedBasketPositions,
				};

				console.log('appendBasketPositions -- ', _market.basketPositions);

				// Update the React state
				// setMarketState(_market);

				// Dispatch updated basket positions
				dispatch(setBasketPositionsData(_market.basketPositions));

				// Update WebSocket
				updateBasketPositionsWS(_params);
			}
		} catch (e) {
			console.log(`ERROR WHILE SETTING BASKET POSITIONS INSTRUMENT -- `, e);
		}
	};

	const setSignelMarketBasketPosition = (basketInstrument) => {
		try {
			if (basketInstrument && basketInstrument?.length) {
				const _market = JSON.parse(JSON.stringify(marketState));
				const _basketInstrument = basketInstrument;
				for (let index = 0; index < _basketInstrument.length; index++) {
					const instrument = _basketInstrument[index];
					if (!_market?.basketPositions[instrument]) {
						_market.basketPositions[instrument] = {}
					}
				}
				// setMarket({ ..._market });
				// dispatch(setAllMarketData({ ..._market })) 
				dispatch(setBasketPositionsData(_market?.basketPositions))
			}
			// return _market.basketPositions;
			return market.basketPositions;
		} catch (e) {
			console.log(`ERROR WHLE UPDATNG POSITION INSTRUMENTS ${e}`);
		}
	};

	const setMarketOpen = (value) => {
		try {
			setIsMarketOpen(value);
		} catch (e) {
			console.log(`ERROR WHILE UPDATING MARKET STATUS`);
		}
	};

	const updateAllow = (allow) => {
		wsxWorker.postMessage({ allow });
	};

	const setMarketIndex = (index, data) => {
		try {
			// setMarket({ ..._market });
			dispatch(setMarketIndexData({ index: index, data: data }));
		} catch (e) {
			console.log("ERROR WHILE SETTING INDEX DATA - ", e);
		}
	}

	const setCurrentIndex = (index) => {
		try {
			dispatch(setMarketCurrentIndex(index))
			wsxWorker.postMessage({ indexChange: true, index: index });
		} catch (e) {
			console.log("ERROR WHILE SETTING CURRENT INDEX - ", e);
		}
	};

	const setCurrentExpiry = (expiry) => {
		try {
			// SEND CURRENT EXPIRY TO WEB WORKER
			wsxWorker.postMessage({ expiryChange: true, expiry: expiry });
		} catch (e) {
			console.log("ERROR WHILE SETTING EXPIRY ", e);
		}
	};

	const updateIndex = (index, data) => {
		// UPDATE PARTICULAR INDEX WITH LIVE DATA
		try {
			const _market = { ...marketState };
			_market[index] = { ..._market[index], ...data };
			// setMarket({ ..._market }); 
			dispatch(setAllMarketData({ ..._market }))
			// dispatch(marketReducer({ type: index, payload: data }))
		} catch (e) {
			console.log(`ERROR WHILE UPDATING ${index}`);
		}
	};

	const setExpiryIndex = (index, expiry, data, symbols) => {
		try {
			let _data = { ...market[index] };
			_data[expiry] = data;
			updateIndex(index, _data);
			wsxWorker.postMessage({
				expiryUpdate: true,
				data: _data,
				index: index,
				instruments: symbols,
			});
			// _data = null
		} catch (e) {
			console.log(`ERROR WHILE SETTING ${index} EXPIRY ${expiry}`, e);
		}
	};

	const updateIndexPromise = (index, data) => {
		return new Promise((resolve, reject) => {
			try {
				updateIndex(index, data);
				// console.log('KEY -- MST -- ', self[index])
				resolve(market[index]);
			} catch (e) {
				reject(e);
			}
		});
	};

	const setInstruments = (inst) => {
		// SET INSTRUMENTS FOR ALL MARKET DATA
		try {
			// console.log('UPDATING INSTRUMENTS...',Object.keys(inst).length)
			// dispatch(marketReducer({ type: 'instruments', payload: inst }))
			const _market = { ...marketState };
			_market.instruments = inst;
			// setMarket({ ..._market });
			dispatch(setInstrumentsData(inst))
			// dispatch(setAllMarketData({ ..._market }))
		} catch (e) {
			console.log(`ERROR WHILE UPDATING INSTRUMENTS - `, e);
		}
	};

	const startWebSockets = (params) => {
		// STARTS WEBSOCKET IN WEB WORKER - SEPARATE THREAD
		try {
			// console.log('WSWORKER', params)
			wsxWorker.postMessage(params);
		} catch (e) {
			console.log("ERROR WHILE STARTING WEBSOCKET FOR TICKERS - ", e);
		}
	};

	const updateMarketWs = (params) => {
		// UPDATE WEB WORKER IN BETWEEN - FOR OTHER EXPIRY DATES
		try {
			if (params?.updateTicker) {
				const _params = {
					marketUpdate: true,
					market: params.market,
					instruments: params.instruments,
				};
				// console.log(_params)
				wsxWorker.postMessage(_params);
			} else console.log(`NOT TO UPDATE WEB WORKER`);
		} catch (e) {
			console.log(`ERROR WHILE UPDATING WEB WORKDER SOCKET - ${e}`, e);
		}
	};

	const updatePositionsWS = (params) => {
		try {
			if (params?.updatePositions) {
				const _params = { updatePositions: true, positions: params.positions };
				// console.log(_params)
				wsxWorker.postMessage(_params);
			} else console.log(`NOT TO UPDATE WEB WORKER`);
		} catch (e) {
			console.log(
				`ERROR WHILE SENDING POSITION INSTRUMENTS TO WEBSOCKET - ${e}`
			);
		}
	};

	const appendPositionsWS = (params) => {
		try {
			if (params?.updatePositions) {
				const _market = market
				const _params = { updatePositions: true, positions: { ...market.positions, ...params.positions } };
				// console.log(_params)
				wsxWorker.postMessage(_params);
				console.log(_params.positions)
				dispatch(setPositionsData(_params?.positions));
				// _market['positions'] = _params.positions
				// setMarket({ ..._market });
				// dispatch(setAllMarketData({ ..._market }))
			}
			else console.log(`NOT DONE`);
		}
		catch (e) {
			console.log(`ERROR WHILE APPENDING POSITION INSTRUMENTS TO WEBSOCKET - ${e}`);
		}
	}

	const updateBasketPositionsWS = (params) => {
		try {
			// console.log('UPDATING BASKET POSITION', params)
			if (params?.updateBasketPositions) {
				const _params = {
					updateBasketPositions: true,
					basketPositions: params.basketPositions,
				};
				// console.log(_params)
				wsxWorker.postMessage(_params);
			} else console.log(`NOT TO UPDATE WEB WORKER`);
		} catch (e) {
			console.log(
				`ERROR WHILE SENDING BASKET  POSITION INSTRUMENTS TO WEBSOCKET - ${e}`
			);
		}
	};

	const sendMessageToWorker = (params) => {
		try {
			if (params?.marketStatus) {
				setMarketOpen(true)
			}
			wsxWorker.postMessage(params);
		} catch (e) {
			console.log(`ERROR WHILE SENDIGN MESSAGE TO WEB WORKER -- ${e}`);
		}
	};

	const context = {
		// ...market,
		isMarketOpen,
		startWebSockets,
		updateMarketWs,
		updatePositionsWS,
		appendPositionsWS,
		updateBasketPositionsWS,
		setExpiryIndex,
		updateIndex,
		updateIndexPromise,
		setInstruments,
		setCurrentIndex,
		setMarketOpen,
		setCurrentExpiry,
		updateAllow,
		setOIFactorContext,
		oiFactor,
		setMarketPositions,
		setMarketBasketPositions,
		sendMessageToWorker,
		setSignelMarketBasketPosition,
		appendBasketPositions,
		setMarketIndex,
		addMultipleBasketInstruments
	};

	return (
		<MarketStateContext.Provider value={context}>
			{children}
		</MarketStateContext.Provider>
	);
};