import React from 'react'
import SingleCard from "../PublicComponents/SingleCard"
import { useMarketContext } from '../Contexts/MarketContext/MarketContext';
import { useSelector } from 'react-redux';

const Exchanges = () => {
    const contextMarket = useMarketContext();
    const market = useSelector((state) => state.market)
    // const memoizedMarket = React.useMemo(() => market, [market]);
    const nifty = market?.nifty;
    const banknifty = market?.banknifty;
    const midcpnifty = market?.midcpnifty;
    const finnifty = market?.finnifty;
    const sensex = market?.sensex;
    const indiavix = market?.indiaVix;
    const giftnifty = market?.giftNifty;


    return (
        <>
            <div className='CardDiv w-full flex flex-wrap items-center p-6 gap-5 lg:gap-10 overflow-y-auto'>
                <SingleCard
                    title={'BANK NIFTY/NIFTY'}
                    nifty={nifty}
                    index={banknifty}
                    percentValue={banknifty?.changePercent / nifty?.changePercent}
                />
                <SingleCard
                    title={'FIN/NIFTY'}
                    nifty={nifty}
                    index={finnifty}
                />
                <SingleCard
                    title={'MIDCP NIFTY/NIFTY'}
                    nifty={nifty}
                    index={midcpnifty}
                />
                <SingleCard
                    title={'BANK NIFTY/NIFTY'}
                    nifty={nifty}
                    index={banknifty}
                />
                <SingleCard
                    title={'SENSEX/NIFTY'}
                    nifty={nifty}
                    index={sensex}
                />
                <SingleCard
                    title={'GIFT NIFTY/NIFTY'}
                    nifty={nifty}
                    index={giftnifty}
                />
                <SingleCard
                    title={'INDIA VIX/NIFTY'}
                    nifty={nifty}
                    index={indiavix}
                />
            </div>
        </>
    )
}

export default Exchanges    
