import React, { useCallback, useEffect } from "react";

import TextField from "@mui/material/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import Button from "@mui/material/Button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { debounce, set } from "lodash";
import { http } from "../Services/api.services";
import AddInstrumentInWatchlist from "./AddInstrumentInWatchlist";
import { BASE_URL } from "../default.config";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../store/Actions/Action";
import { useThemeContext } from "../Theme/ThemeContext";
import CircularProgress from '@mui/material/CircularProgress';
import OrderDrawer from "./OrderDrawer";
import { updateSnackbar } from "../store/Reducer/AdminReducer";
import { setAllWatchlists, setCurrentOrderType, setCurrentSelectedOrder, setNewWatchlist, setOpenAddStock, setOpenOrderform, setSelectedCompany } from "../store/Reducer/EquityReducer";
import { Autocomplete, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Searchcomponent = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { user } = useSelector((state) => state.user)
  const { opnOrderForm, openAddStock } = useSelector((state) => state.equity);
  const [searchText, setSearchText] = React.useState("");
  const [results, setResults] = React.useState(null);

  // Function to fetch search results
  const fetchSearchResults = async (query) => {
    setResults(null)
    if (!query) {
      setResults([]);
      return;
    }
    try {
      const response = await http.get(`/search-instruments?search=${query}`);
      if (response?.status == 200) {
        const data = response?.data
        console.log(data, '----s');
        setResults(data);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  // Debounced version of fetchSearchResults
  const debouncedFetchSearchResults = useCallback(debounce(fetchSearchResults, 300), []);

  // HANDLE COMPANY SELECTION 
  const handleAddInstrument = (data) => {
    dispatch(setOpenAddStock(true));
    dispatch(setSelectedCompany(data))
  };

  // SEARCH COMPANIES FUNCTIN 
  useEffect(() => {
    debouncedFetchSearchResults(searchText);
  }, [searchText, debouncedFetchSearchResults]);

  return (
    <>
      <div className="w-full flex-1 flex flex-col ">
        <div className="w-10/12 lg:w-1/2 xl:1/2 flex-1 mx-auto relative">
          <Autocomplete
            id="country-select-demo"
            options={results || []} // Ensure options is never null or undefined
            autoHighlight
            size="small"
            loading={!results}
            fullWidth
            inputValue={searchText}
            getOptionLabel={(option) => {
              // console.log("Current Option:", option); // Log the current option for debugging
              const name = option.name || ''; // Use empty string if name is undefined
              const tradingSymbol = option.tradingsymbol || ''; // Use empty string if tradingsymbol is undefined
              return `${name} ${tradingSymbol}`.trim(); // Concatenate and trim the result
            }}
            onChange={(event, newValue) => {
              if (newValue) {
                navigate(`/equity/company/${newValue?.stock_id}`);
                setSearchText('');
              }
            }}
            renderOption={(props, option) => (
              <Box component="li" sx={{ padding: 0 }} {...props}>
                <div
                  onClick={() => {
                    navigate(`/equity/company/${option?.stock_id}`)
                  }}
                  // key={item?.stock_id} // Adding a key prop for better rendering
                  className="w-full flex items-center justify-between border-b border-b-[#9E9E9E78] py-2"
                >
                  <h6 className="text-[#505050] uppercase text-sm lg:text-base font-semibold">
                    {option?.name || option?.tradingsymbol}
                  </h6>
                  <div className="flex items-center gap-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setOpenOrderform(true))
                        dispatch(setCurrentSelectedOrder(option));
                        dispatch(setCurrentOrderType('buy'));
                      }}
                      sx={{
                        color: "#FFFFFF",
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                        minWidth: "30px",
                        "& .css-1d6wzja-MuiButton-startIcon": {
                          margin: 0,
                        },
                        "&:hover": {
                          backgroundColor: "#00B386",
                        },
                        borderRadius: 0,
                      }}
                      color="buy"
                      variant="contained"
                    >
                      B
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setOpenOrderform(true))
                        dispatch(setCurrentSelectedOrder(option));
                        dispatch(setCurrentOrderType('sell'));
                      }}
                      sx={{
                        color: "#FFFFFF",
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                        minWidth: "30px",
                        "& .css-1d6wzja-MuiButton-startIcon": {
                          margin: 0,
                        },
                        "&:hover": {
                          backgroundColor: "#EB5B3C",
                        },
                        borderRadius: 0,
                      }}
                      color="sell"
                      variant="contained"
                    >
                      S
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: "#373737",
                        color: "#FFFFFF",
                        paddingLeft: 0,
                        paddingRight: 0,
                        minWidth: "30px",
                        "& .css-1d6wzja-MuiButton-startIcon": {
                          margin: 0,
                        },
                        "&:hover": {
                          backgroundColor: "#373737",
                        },
                        borderRadius: 0,
                      }}
                      variant="outlined"
                      startIcon={<CandlestickChartIcon />}
                    />
                    <Button
                      sx={{
                        backgroundColor: "#373737",
                        color: "#FFFFFF",
                        paddingLeft: 0,
                        paddingRight: 0,
                        minWidth: "30px",
                        "& .css-1d6wzja-MuiButton-startIcon": {
                          margin: 0,
                        },
                        "&:hover": {
                          backgroundColor: "#373737",
                        },
                        borderRadius: 0,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddInstrument(option)
                      }}
                      variant="outlined"
                      startIcon={<AddIcon />}
                    />
                  </div>
                </div>
              </Box>
            )}
            sx={{
              position: 'relative'
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search"
                value={searchText}
                fullWidth
                size="small"
                onChange={(e) => setSearchText(e.target.value)}
                autoComplete="new-password"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      {searchText?.length > 0 ?
                        <IconButton
                          sx={{
                            position: 'absolute',
                            right: 0
                          }}
                          onClick={() => setSearchText("")}
                          aria-label="delete">
                          <CloseOutlinedIcon />
                        </IconButton>
                        : null}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <AddInstrumentInWatchlist
            getAllWatchlist={() => {
              // console.log('e');
            }}
          />

          <OrderDrawer
            open={opnOrderForm}
            onClose={() => {
              dispatch(setOpenOrderform(false));
            }}
          />
        </div>
      </div >
    </>
  );
};

export default Searchcomponent;
