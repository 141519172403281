import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import WatchlistCard from "../../EquityComponents/WatchlistCard";
import AddWatchlist from "../../EquityComponents/AddWatchlist";
import { http } from "../../Services/api.services";
import { BASE_URL, WEBSOCKET_URL } from "../../default.config";
import Skeleton from "@mui/material/Skeleton";
import { useThemeContext } from "../../Theme/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { InputAdornment, TextField } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DatePickerComponent from "../../EquityComponents/DatePickerComponent";
import { debounce } from "lodash";
import ConfirmDeleteWatchlist from "../../EquityComponents/ConfirmDeleteWatchlist";
import AddIcon from '@mui/icons-material/Add';
import { setAllWatchlists } from "../../store/Reducer/EquityReducer";
import { H3, H4, H6 } from "../../Theme/ThemeComponent";
import { showToast } from "../../store/Actions/Action";

const Watchlists = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { allWatchlists, newWatchlist } = useSelector((state) => state.equity);
  const { theme } = useThemeContext();
  const [open, setOpen] = React.useState(false);
  const [openAddWatchlilst, setOpenAddWatchlist] = React.useState(false);
  const [watchlistName, setWatchlistName] = React.useState("");
  const [searchText, setSearchText] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [isEditingId, setIsEditingId] = React.useState(null);
  const [isHoverId, setIsHoverId] = React.useState(null)
  const skeletons = Array.from({ length: 6 });
  const [filterData, setFilterData] = React.useState({
    fromDate: '',
    endDate: '',
  })

  React.useEffect(() => {
    xfatchFilterStudent(searchText);
  }, [searchText])


  const xfatchFilterStudent = React.useCallback(debounce((text) => getAllWatchlist(text), 300), []);

  // GET ALL WATCHLIST DATA 

  useEffect(() => {
    // getAllWatchlist();
  }, [newWatchlist]);

  const getAllWatchlist = async (searchText) => {
    try {
      const id = user?.id
      const _apiUrl = searchText ? `${BASE_URL}watchlist/${id}?search=${searchText}` : `${BASE_URL}watchlist/${id}`
      const response = await http.get(_apiUrl);
      if (response.status == 200) {
        dispatch(setAllWatchlists(response?.data));
        setIsLoading(false);
      } else {
        setIsLoading(false)
      }
    } catch (e) {
      console.log(`ERROR WHILE FETCHING WATCHLISTS ${e}`);
      setIsLoading(false)
    }
  };

  // SORT WATCHLIST BY DATE 

  useEffect(() => {
    if (filterData) {
      const { startDate, endDate } = filterData;
      if (startDate && endDate) {
        sortWatchlistByDate();
      }
    }
  }, [filterData]);

  const sortWatchlistByDate = async () => {
    setIsLoading(true)
    try {
      const id = 'ef512e1f-fc39-46f2-bb6c-db54be6e5ed5';
      const _apiUrl = `${BASE_URL}watchlist/${id}?fromDate=${filterData?.startDate ? filterData?.startDate : null}&toDate=${filterData?.endDate ? filterData?.endDate : null}`
      const response = await http.get(_apiUrl)
      if (response.status == 200) {
        dispatch(setAllWatchlists(response?.data));
        setIsLoading(false);
      } else {
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
      console.log(`ERROR WHILE FETCHING WATCHLIST ${e}`);
    }
  }

  // CREATE NEW WATCHLIST 
  const createWatchlist = async () => {
    setLoading(true)
    try {
      if (!watchlistName) {
        const _snack = {
          message: 'Please Enter valid Watchlist name!',
          open: true,
          type: 'erro',
          duration: 3000,
        }
        dispatch(showToast(_snack))
        setLoading(false)
        return;
      }
      const _body = {
        title: watchlistName,
        userId: user?.id,
      };
      const response = await http.post(`${BASE_URL}watchlist`, _body);
      setLoading(false)
      if (response?.status == 200) {
        setWatchlistName('')
        getAllWatchlist()
        setOpenAddWatchlist(false)
        const _snack = {
          message: 'Watchlist successfully created!',
          open: true,
          type: 'success',
          duration: 3000,
        }
        dispatch(showToast(_snack))
      } else {
        const _snack = {
          message: response?.data?.message,
          open: true,
          type: 'error',
          duration: 3000,
        }
        dispatch(showToast(_snack))
      }
    } catch (e) {
      console.log(e);

      setLoading(false)
      const _snack = {
        message: e?.response?.data?.detail,
        open: true,
        type: 'error',
        duration: 3000,
      }
      dispatch(showToast(_snack))
      console.log(`ERROR WHILE CREATING WATCHLISTS ${e}`);
    }
  };

  // REMOVE SELECTED WATCHLIST 

  const removeWatchlist = async (watchlistId) => {
    try {
      const response = await http.delete(`watchlist/${watchlistId}`)
      if (response?.status == 200) {
        setOpen(false);
        setIsEditingId(null);
        setIsHoverId(null)
        getAllWatchlist();
      }
    } catch (e) {
      console.log(`ERROR WHILE DELETING WARTCHLIST ${e}`);

    }
  }

  return (
    <>
      <div className="w-full flex-1 flex flex-col p-4">
        <div style={{ background: theme.palette.background.white }} className={`w-full flex-1 flex flex-col  p-2 lg:p-6 shadow `}>
          {/* HEADER  */}
          <div className="w-full flex  items-center justify-between">
            <div className="flex hidden w-full lg:w-1/3 items-center py-4">
              <TextField
                placeholder="enter name of watchlist to create"
                id="outlined-basic"
                sx={{
                  // borderRadius: 0,
                  outline: 'none',
                  border: 'none',
                  borderRadius: '0 0 0 0', // Only the right side rounded
                  '& fieldset': {
                    borderRadius: '0 0 0 0', // Ensures the border of the TextField is not rounded on the left
                  },
                }}
                onKeyUp={(event) => {
                  if (event.key === "Enter" && watchlistName) {
                    if (isEditingId) {
                      // updateWatchlist(isEditingId);
                    } else {
                      createWatchlist();
                    }
                  }
                }}
                className="!rounded-none"
                // label="Outlined"
                fullWidth
                size="small"
                variant="outlined"
                value={watchlistName}
                onChange={(e) => {
                  setWatchlistName(e.target.value);
                }}
              />
              <LoadingButton
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius: 0,
                  minWidth: 'fit-content',
                  padding: 0,
                  paddingY: 0.85,
                  paddingX: 0.8
                }}
                loading={loading}
                // disabled={!watchlistName}
                size="large"
                variant="contained"
                onClick={() => {
                  // if (isEditingId) {
                  //   updateWatchlist(isEditingId);
                  // } else {
                  //   createWatchlist();
                  // }
                  setOpenAddWatchlist(true)
                }}
              >
                Create Watchlist
              </LoadingButton>
            </div>
            <H3 title='Watchlist' color={theme.palette.text.charcolGray} font='bold' />

            <div>
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setOpenAddWatchlist(true)
                }}
                startIcon={<AddIcon sx={{ color: theme.palette.text.white }} />}
              >
                Create Watchlist
              </Button>
            </div>
            {/* <div className="flex items-center gap-2">
              <TextField
                placeholder="search"
                id="outlined-basic"
                sx={{
                  borderRadius: 0
                }}
                // label="Outlined"
                fullWidth
                variant="outlined"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            DATE PICKER COMPONENT  
              <DatePickerComponent filterData={filterData} setFilterData={setFilterData} />

              <Button
                sx={{
                  backgroundColor: theme.palette.background.whisperGray,
                  color: "#3737378A",
                  border: "none",
                  padding: 1.2,
                  paddingLeft: 1,
                  minWidth: "30px",
                  "& .css-1d6wzja-MuiButton-startIcon": {
                    margin: 0,
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.background.whisperGray,
                    border: "none",
                  },
                }}
                size="large"
                variant="outlined"
                startIcon={<ImportExportIcon sx={{ marginLeft: 2 }} />}
                onClick={() => {
                  setShortBy(shortBy == 'desc' ? 'asc' : 'desc')
                  sortWatchlistData()
                }}
              />
            </div> */}
          </div>
          <div className="w-full flex flex-wrap  items-start justify-start gap-4 py-4">
            {isLoading
              ? skeletons.map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  width={'49%'}
                  height={120}
                />
              ))
              : allWatchlists?.map((watchlist, index) => {
                return (
                  <div className="w-full lg:w-[49%]">
                    <WatchlistCard
                      key={index}
                      watchListData={watchlist} isHoverId={isHoverId}
                      setIsHoverId={setIsHoverId}
                      isEditingId={isEditingId}
                      onEditWatchlist={(watchlist) => {
                        setWatchlistName(watchlist?.title)
                        setIsEditingId(watchlist.id)
                      }}
                      onRemoveWatchlist={(watchlist) => {
                        setOpen(true);
                        setIsEditingId(watchlist.id)
                      }}
                    />
                  </div>
                )
              })}
          </div>
          {allWatchlists?.length == 0 ?
            <div className="flex items-center justify-center w-full h-full flex-1">
              <H6
                title={'No Watchlist Found!'}
              />
            </div>
            : null}
        </div>
      </div>

      <ConfirmDeleteWatchlist
        open={open}
        onCloseWatchlist={() => {
          setOpen(false);
          setIsEditingId(null);
          setIsHoverId(null)
        }}
        onRemoveWatchlist={() => {
          removeWatchlist(isEditingId)
        }}
      />

      <AddWatchlist
        open={openAddWatchlilst}
        onHandleClose={() => {
          setOpenAddWatchlist(false)
        }}
        watchlistName={watchlistName}
        setWatchlistNameFunction={(e) => {
          setWatchlistName(e?.target?.value)
        }}
        onCreateWatchlist={() => createWatchlist()}
        isLoading={loading}
      />
    </>
  );
};

export default Watchlists;
