import React from "react";
import { Chart } from "react-google-charts";
import { H6 } from "../Theme/ThemeComponent";
import { useThemeContext } from "../Theme/ThemeContext";

// Chart options with dynamic colors array
const options = {
    pieHole: 0.4,
    legend: { position: "none" }, // Hide the default legend
    pieSliceText: "none", // Hide percentage on slices
    chartArea: {
        left: 20,
        top: 20,
        width: "100%", // Adjust chart area
        height: "80%",
    },
};


const _optionText = [
    { label: 'Promoters', color: '#F79661' },
    { label: 'FIIs', color: '#3850FB' },
    { label: 'DIIs', color: '#F3A7FF' },
    { label: 'Public', color: '#69FFE4' },
    { label: 'Government', color: '#FFD2A3' },
]

const CompanyPieChart = ({ data, loadingData }) => {
    const { theme } = useThemeContext();

    // Generate dynamic colors based on the data length 
    const generateDynamicColors = (length) => {
        const baseColors = ["#F79661", "#3850FB", "#F3A7FF", "#69FFE4", "#FFD2A3"];
        return Array.from({ length }, (_, index) => baseColors[index % baseColors.length]);
    };

    // Transform data dynamically based on the API response
    const transformedData = [
        ["Investor Type", "Percentage"], // Header row
        ...data?.ShareHoldingPatternPercentage.map(item => {
            const [label, percentage] = Object.entries(item)[0]; // Extract key-value pair
            return [label, percentage];
        }) || []
    ];

    // Generate colors dynamically based on the data length
    options.colors = generateDynamicColors(transformedData.length - 1);

    return (
        <div className="w-full flex flex-col items-center justify-center">
            <div
                style={{
                    height: "345px",
                    background: theme.palette.background.white
                }}
                className="p-4 shadow flex flex-col items-center justify-center w-full rounded-md my-2"
            >
                <div className="w-full h-full flex items-center justify-center">
                    {/* Chart */}
                    {!loadingData && options ?
                        <Chart
                            chartType="PieChart"
                            width="80%" // Allocate half width for the chart
                            height="100%"
                            data={transformedData}
                            options={options}
                        />
                        : 'loading chart'}

                    {/* Custom Legend */}
                    <div className="flex flex-col items-start gap-4 w-2/5 pr-4 ">
                        {
                            data?.ShareHoldingPatternPercentage ? (
                                data?.ShareHoldingPatternPercentage?.map((opt, index) => {
                                    console.log(opt);
                                    let label = '';
                                    let percent = null;

                                    // Iterate over the keys of the object using a for...in loop
                                    for (const key in opt) {
                                        if (Object.hasOwnProperty.call(opt, key)) {
                                            label = key;
                                            percent = opt[key];
                                        }
                                    }

                                    // console.log(label, percent);


                                    // Find the percentage corresponding to the current label (e.g., "Promoters", "FIIs")
                                    const percentageObject = data?.ShareHoldingPatternPercentage.find(
                                        (item) => Object.keys(item)[0] === opt.label
                                    );


                                    return (
                                        <div key={index} className="flex w-full items-center justify-between ">
                                            <div className="flex items-center gap-2">
                                                <span
                                                    className={`w-5 h-5 rounded-full inline-block`}
                                                    style={{ backgroundColor: options?.colors[index] }}
                                                ></span>
                                                <H6
                                                    title={label}
                                                    color={theme.palette.text.coolGray}
                                                    font="normal"
                                                />
                                            </div>
                                            <H6
                                                title={percent !== null ? `${percent}%` : '--'}
                                                color={theme.palette.text.charcolGray}
                                                font="bold"
                                            />
                                        </div>
                                    );
                                })
                            ) : null
                        }
                    </div>
                </div>
                {!loadingData && data?.Quarter && data?.Shareholders ?
                    <H6
                        title={`As on ${data?.Quarter} Total ${data?.Shareholders} shareholders`}
                    />
                    : null}
            </div>
        </div>
    );
};

export default CompanyPieChart;
