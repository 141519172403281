import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Alert, Button, Snackbar, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormLabel from "@mui/material/FormLabel";
import { LoadingButton } from "@mui/lab";
import { useMarketContext } from "../Contexts/MarketContext/MarketContext";

const xIndices = [
    "nifty",
    "banknifty",
    "finnifty",
    "midcpnifty",
    "sensex",
    "bankex",
];

export default function BottomSheet(props) {
    // console.log(props)
    const isLargeScreen = useMediaQuery("(min-width:960px)");
    // const market = useSelector((state) => state.market);
    //   const market = useMarketContext();
    // const market = useMarketContext()
    const market = useSelector((state) => state.market)
    const { currentIndex } = useSelector((state) => state.optionHome);
    const theme = useTheme();
    // drawer
    // console.log(props?.form?.currentIndex, market)
    const [currentTab, setcurrentTab] = React.useState(
        props?.form?.currentIndex || props?.currentIndex
    );
    const user = useSelector((state) => state.user);
    const { admin } = useSelector((state) => state.admin);
    const [value, setValue] = React.useState(
        xIndices.indexOf(props?.form?.currentIndex || props?.currentIndex)
    );
    const [isSell, setIsSell] = React.useState(false);
    const [isLtp, setIsLtp] = React.useState(false);
    const [currentOption, setCurrentOption] = React.useState(
        props?.form?.currentOption
    );
    const [currentStrike, setCurrentStrike] = React.useState(props?.form?.strike);
    const [orderType, setOrderType] = React.useState(props?.form?.orderType);
    const [amount, setAmount] = React.useState(props?.form?.amount || "");
    const [instrument, setInstrument] = React.useState(props?.form?.instrument);
    const [quantity, setQuantity] = React.useState(
        props?.form?.quantity || market[currentTab]["qty"] || 0
    );
    const [txnType, setTxnType] = React.useState(props?.form?.txnType);
    const [exchangeType, setExchangeType] = React.useState(props?.form?.exchange);
    const [tradingsymbolType, setTradingsymbolType] = React.useState(
        props?.form?.tradingsymbol
    );
    // console.log(market[currentTab], 'its auantity');
    const _id = admin ? admin?.id : user?.id;
    const [symbol, setSymbol] = React.useState(props?.form?.symbol);

    // STOPLOSS & TARGET STATES
    const [stopLossTarget, setStopLossTarget] = React.useState({});
    const [sltargetOption, setSLTargetOption] = React.useState("");
    // ERROR
    const [error, setErrors] = React.useState({ open: false, message: "" });

    const [msg, setMsg] = React.useState("");

    // const last_price = market[currentTab]?.option.find(op => (op[orderType].symbol == symbol))

    const [ltp, setLTP] = React.useState(0);

    const [margin, setMargin] = React.useState((ltp || 0) * quantity);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setcurrentTab(xIndices?.[newValue]);
    };

    const _ltp =
        market[currentTab]?.[props?.currentExpiry]?.options[orderType == "call" ? "CE" : "PE"][instrument]
            ?.last_price;

    // console.log(market[currentTab]?.option.find(op => (op[orderType].symbol == symbol))[orderType]['ltp'])
    // console.log(ltp)
    React.useEffect(() => {
        // ON INDEX SELECTION MATCH SL TARGET WITH BELOW

        // ELSE LTP SELECTION USE BELOW VALUE TO MATCH SL TARGET
        if (_ltp) {
            setLTP(_ltp);
            if (!amount) {
                setMargin(_ltp * quantity);
            }
        }
    }, [_ltp, amount]);

    React.useEffect(() => {
        try {
            // console.log(market[currentTab])
            setQuantity(props?.form?.quantity || market[currentTab]["qty"]);
            const _current = props.form?.currentIndex || props?.currentIndex;
            if (currentTab != _current) {
                // TAB CHANGED
                console.log(
                    market[currentTab]?.[props.form.currentExpiry]?.options[orderType == "call" ? "CE" : "PE"][
                    `'${instrument}'`
                    ]
                );
                setCurrentStrike(
                    market[currentTab]?.[props.form.currentExpiry]?.options[orderType == "call" ? "CE" : "PE"][
                    `'${instrument}'`
                    ]["strike"]
                );
                setCurrentOption(
                    market[currentTab]?.[props.form.currentExpiry]?.options[orderType == "call" ? "CE" : "PE"][
                    `'${instrument}'`
                    ]
                );
                // setQuantity(market[currentTab]['qty'])
                setSymbol(
                    market[currentTab]?.[props.form.currentExpiry]?.options[orderType == "call" ? "CE" : "PE"][
                    `'${instrument}'`
                    ]["tradingsymbol"]
                );
                setMargin(
                    market[currentTab]?.[props.form.currentExpiry]?.options[orderType == "call" ? "CE" : "PE"][
                    `'${instrument}'`
                    ]["last_price"] * quantity
                );
                // setAmount('')
            } else {
                // SAME TAB
                setCurrentStrike(props?.form?.strike);
                setCurrentOption(props?.form?.currentOption);
                setSymbol(props?.form?.symbol);
                setMargin(currentOption["ltp"] * quantity);
            }
            if (!props?.form?.amount)
                setAmount("");
        } catch (e) {
            console.log(`ERROW USE EFFECT CURRENT TAB ${e}`);
        }
    }, [currentTab]);

    React.useEffect(() => {
        const strikeFound = findStrike(
            currentStrike,
            market[currentTab]?.[props.form.currentExpiry]?.options[orderType === "call" ? "CE" : "PE"]
        );
        if (strikeFound && !props?.form?.notinoc) {
            setCurrentOption(strikeFound);
            setInstrument(strikeFound?.instrument_token);
            setTradingsymbolType(strikeFound?.tradingsymbol);
            setSymbol(strikeFound?.tradingsymbol);
            setMargin((amount || strikeFound["last_price"]) * quantity);
        }
    }, [orderType]);

    React.useEffect(() => {
        setMargin((amount || currentOption["last_price"]) * quantity);
    }, [amount, quantity]);

    const inputStyle = {
        // color: 'rgba(55, 55, 55, 0.20)',
        "& .MuiInputBase-root": {
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px", // Font size for large screens
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "16px", // Font size for large screens
            },
            bgcolor: "#fff",
        },
    };

    const returnCurrentOption = (strike) => {
        try {
            // RETURNS CALL AND PUT AGAINST STRIKE
            return market[currentTab][props.form.currentExpiry]["options"]?.find(
                (op) => op.strikePrice === strike
            );
        } catch (e) {
            console.log(`ERROR WHILE CURRENT OPTION RETURN ${e}`);
        }
    };

    const findStrike = (strike, object) => {
        try {
            let found = false;
            let first = null;
            for (const key in object) {
                if (!first) first = object[key];
                if (object[key]["strike"] == strike) {
                    found = true;
                    return object[key];
                }
            }
            if (!found) {
                // NOT FOUND
                return first;
            }
        } catch (e) {
            return null;
        }
    };

    // FIND STRIKE INDEX
    const handleStrikeChange = (event, selectedValue) => {
        // Find the index of the selected value in the array
        // console.log('selectedValue -- ', market[currentTab]?.options[orderType === 'call' ? 'CE':'PE'])
        setCurrentStrike(selectedValue);
        const _currentIndex = props?.form?.currentIndex || currentIndex;
        const strikeFound = findStrike(
            selectedValue,
            market[currentTab]?.[props.form.currentExpiry]?.options[orderType === "call" ? "CE" : "PE"]
        );
        if (strikeFound) {
            setExchangeType(strikeFound?.exchange);
            setInstrument(strikeFound?.instrument_token);
            setTradingsymbolType(strikeFound?.tradingsymbol);
            setSymbol(strikeFound?.tradingsymbol);
        }
    };

    const updateStopLossTarget = (value, type) => {
        try {
            const _temp = { ...stopLossTarget };
            if (type === "stoploss") _temp[type] = value;
            else _temp[type] = value;
            setStopLossTarget(_temp);
        } catch (e) {
            console.log(`ERROR WHILE SETTING SL & T ${e}`);
        }
    };

    const onSubmit = () => {
        try {
            // SUBMIT FUNCTION
            if (!currentStrike)
                return setErrors({ open: true, message: "Strike Price is Mandatory!" });
            else if (!orderType)
                return setErrors({ open: true, message: "Order Type is Mandatory!" });
            else if (!txnType)
                return setErrors({
                    open: true,
                    message: "Transaction Type is Mandatory!",
                });
            else if (!quantity)
                return setErrors({ open: true, message: "Quantity is Mandatory!" });
            else {
                // VALID FORM
                const _body = {
                    index: market[currentTab]["tradingsymbol"],
                    tradingsymbol: symbol,
                    transaction_type: txnType?.toUpperCase(), // Ensure that
                    quantity: quantity
                        ? parseInt(Math.abs(quantity))
                        : Math.abs(quantity),
                    product: "MIS",
                    order_type: amount ? "LIMIT" : "MARKET",
                    instrumentToken: instrument,
                    exchange: exchangeType,
                    // ...(admin ? { "id": _id } : { "userId": _id })
                    userId: _id,
                };

                if (amount) {
                    _body["price"] = amount;
                }
                if (sltargetOption) {
                    if (sltargetOption === "index") {
                        // ADD STOPLOSS TARGET -- INDEX
                        _body.stoploss = {
                            stoploss: stopLossTarget.stoploss,
                            target: stopLossTarget.target,
                        };
                    } else {
                        // ADD STOPLOSS TARGET -- LTP
                        _body.ltp_sl = {
                            stoploss: stopLossTarget.stoploss,
                            target: stopLossTarget.target,
                        };
                    }
                }
                props.placeOrder(_body);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const [inputValue, setInputValue] = React.useState("");

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    // Ensure optionsCE and optionsPE are valid arrays
    const optionsCE = market[currentTab]?.[props.form.currentExpiry]?.options?.CE
        ? Object.entries(market[currentTab]?.[props.form.currentExpiry]?.options.CE)
        : [];
    const optionsPE = market[currentTab]?.[props.form.currentExpiry]?.options?.PE
        ? Object.entries(market[currentTab]?.[props.form.currentExpiry]?.options.PE)
        : [];

    // Merge and map the options to extract the strike values
    const allOptions = [...optionsCE, ...optionsPE]
        .map((el) => el[1]?.strike)
        .filter(Boolean);

    // Custom filter logic for full-text search
    const filterOptions = (options, { inputValue }) => {
        return options.filter((option) => option.toString().includes(inputValue));
    };

    return (
        <>
            <div>
                <Drawer
                    // anchor="bottom"
                    // variant="persistent"
                    anchor="bottom"
                    open={props.open}
                    onClose={() => props.onClose()}
                    // height="80vh"
                    width="100%"
                    // disableSwipeToOpen={false}
                    // ModalProps={{
                    //     keepMounted: true,
                    // }}
                    // sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    SlideProps={{
                        sx: {
                            background: "transparent",
                            boxShadow: "none",
                            width: isLargeScreen ? "340px" : "100%",
                            margin: "auto",
                            borderRadius: "12px 12px 0px 0px",
                        },
                        // bgColor: "white"
                    }}
                >
                    <div className="flex items-center justify-center w-full">
                        <div className="w-full flex flex-col items-start gap-4 lg:gap-3 rounded-tl-2xl rounded-tr-2xl bg-white justify-between px-3 pb-2">
                            <div className="flex w-full items-center justify-between ">
                                <Box
                                    sx={{
                                        maxWidth: {
                                            xs: "100%",
                                            sm: "100%",
                                            md: "100%",
                                            lg: "100%",
                                        },
                                        bgcolor: "background.paper",
                                    }}
                                >
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons
                                        allowScrollButtonsMobile
                                        aria-label="scrollable force tabs example"
                                    >
                                        <Tab className="text-[#262626]" label="NIFTY" />
                                        <Tab className="text-[#262626]" label="BANKNIFTY" />
                                        <Tab className="text-[#262626]" label="FINNIFTY" />
                                        <Tab className="text-[#262626]" label="MIDCPNIFTY" />
                                        <Tab className="text-[#262626]" label="SENSEX" />
                                        <Tab className="text-[#262626]" label="BANKEX" />
                                    </Tabs>
                                </Box>
                            </div>
                            <TabPanel className="flex flex-col gap-3">
                                <FormControl fullWidth>
                                    <FormLabel id="order-type" className="mb-3">
                                        Choose Strike
                                    </FormLabel>
                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        sx={inputStyle}
                                        disabled={props?.form.modify}
                                        disablePortal
                                        id="combo-box-demo"
                                        value={currentStrike}
                                        onChange={handleStrikeChange}
                                        options={[
                                            ...Object.entries(market[currentTab]?.[props.form.currentExpiry]?.options?.CE),
                                            // ...Object.entries(market[currentTab]?.options?.PE),
                                        ].map((el) => el[1]["strike"])}
                                        getOptionLabel={(option) => option?.toString() || null}
                                        renderInput={(params) => (
                                            <TextField
                                                placeholder="Strikes"
                                                {...params}
                                                label="Strikes"
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl disabled={props?.form.modify}>
                                    <FormLabel id="order-type">Order type</FormLabel>
                                    <RadioGroup
                                        row
                                        className="gap-2"
                                        aria-labelledby="order-type"
                                        name="order-type-radio"
                                        value={orderType}
                                        onChange={(e) => setOrderType(e?.target?.value)}
                                    >
                                        <FormControlLabel
                                            value="call"
                                            control={<Radio />}
                                            label="Call"
                                        />
                                        <FormControlLabel
                                            value="put"
                                            control={<Radio />}
                                            label="Put"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl disabled={props?.form.modify} fullWidth color={txnType?.toLowerCase()}>
                                    <FormLabel id="txn-type">Transaction type</FormLabel>
                                    <RadioGroup
                                        row
                                        className="gap-2"
                                        aria-labelledby="txn-type"
                                        name="txn-type-radio"
                                        value={txnType}
                                        onChange={(e) => setTxnType(e?.target?.value)}
                                    >
                                        <FormControlLabel
                                            value="buy"
                                            control={<Radio color="buy" />}
                                            label="Buy"
                                        />
                                        <FormControlLabel
                                            value="sell"
                                            control={<Radio color="sell" />}
                                            label="Sell"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <div className="mt-4 flex justify-between items-center">
                                    <p
                                        className={`font-medium text-[14px] ${txnType === "buy"
                                            ? "text-[#00B386]"
                                            : txnType === "sell"
                                                ? "text-[#EB5B3C]"
                                                : "text-[#262626"
                                            }`}
                                    >
                                        {symbol}
                                    </p>
                                    <p className={`font-medium text-[12px] text-[#5367ff]`}>
                                        Margin:{" "}
                                        <span className="text-[14px]">{margin?.toFixed(2)}</span>
                                    </p>
                                </div>
                                <div className="mb-2">
                                    <div className="flex items-center justify-between gap-3">
                                        <TextField
                                            fullWidth
                                            autoFocus
                                            id="quantity"
                                            label="Quantity"
                                            variant="outlined"
                                            type="number"
                                            size="small"
                                            disabled={props?.form.modify}
                                            value={quantity}
                                            onChange={(e) => {
                                                setQuantity(e.target.value);
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            autoFocus
                                            id="amount"
                                            label="Amount"
                                            variant="outlined"
                                            placeholder={amount ? "" : "MARKET"}
                                            type="number"
                                            size="small"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                    </div>
                                    <p className="text-right text-[12px] mt-[2px] text-[#757575] mr-[-4px]">
                                        Fill Amount for <b>LIMIT</b> Order
                                    </p>
                                </div>
                                <FormControl disabled={props?.form.modify} fullWidth>
                                    <FormLabel id="sl-target">SL & Target</FormLabel>
                                    <RadioGroup
                                        row
                                        className="gap-2"
                                        aria-labelledby="sl-target"
                                        name="sl-target-radio"
                                        value={sltargetOption}
                                        onChange={(e) => {
                                            setSLTargetOption(e?.target?.value);
                                            setStopLossTarget({ stoploss: "", target: "" });
                                        }}
                                    >
                                        <FormControlLabel
                                            value="index"
                                            control={<Radio />}
                                            label="Index"
                                        />
                                        <FormControlLabel
                                            value="ltp"
                                            control={<Radio />}
                                            label="LTP"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <div className="flex gap-3">
                                    <TextField
                                        fullWidth
                                        id="sl"
                                        label={"Stop Loss @ " + sltargetOption?.toUpperCase()}
                                        // helperText={singupForm.password?.length > 0 && !passwordRegex?.test(singupForm.password) ? " password should have atleast 5 character" : ""}
                                        FormHelperTextProps={{
                                            style: {
                                                fontSize: 11,
                                                fontWeight: "bold",
                                            },
                                        }}
                                        variant="outlined"
                                        type="number"
                                        size="small"
                                        // defaultValue={0}
                                        disabled={!sltargetOption}
                                        value={
                                            stopLossTarget?.stoploss == undefined
                                                ? 0
                                                : stopLossTarget?.stoploss
                                        }
                                        onChange={(e) =>
                                            updateStopLossTarget(e?.target?.value, "stoploss")
                                        }
                                    />
                                    <TextField
                                        fullWidth
                                        id="target"
                                        label={"Target @ " + sltargetOption?.toUpperCase()}
                                        variant="outlined"
                                        type="number"
                                        size="small"
                                        FormHelperTextProps={{
                                            style: {
                                                fontSize: 11,
                                                fontWeight: "bold",
                                            },
                                        }}
                                        disabled={!sltargetOption}
                                        // defaultValue={0}
                                        value={
                                            stopLossTarget?.target == undefined
                                                ? 0
                                                : stopLossTarget?.target
                                        }
                                        onChange={(e) =>
                                            updateStopLossTarget(e?.target?.value, "target")
                                        }
                                    />
                                </div>
                                <div className="py-2 flex justify-end gap-2">
                                    <LoadingButton
                                        loading={props.isLoading}
                                        variant="contained"
                                        // disabled={!txnType || !stopLossTarget?.stoploss || !stopLossTarget?.target}
                                        sx={{
                                            color: "#fff",
                                            fontWeight: "bolder",
                                            backgroundColor: props?.isModifying
                                                ? "#eb5b3c"
                                                : "#30b69c",
                                        }}
                                        color={txnType?.toLowerCase() || "primary"}
                                        onClick={() => onSubmit()}
                                    >
                                        {props.isModifying ? "MODIFY ORDER" : "PLACE ORDER"}
                                    </LoadingButton>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </Drawer>

                <Snackbar
                    open={error?.open}
                    autoHideDuration={5000}
                    onClose={() => setErrors({ open: false, message: "" })}
                >
                    <Alert
                        onClose={() => setErrors({ open: false, message: "" })}
                        severity="error"
                        sx={{ width: "100%" }}
                    >
                        {error?.message}
                    </Alert>
                </Snackbar>
            </div>
        </>
    );
}

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}
