import * as React from "react";
import Skeleton from '@mui/material/Skeleton';
let wait = false

const OIChange = ({
	high,
	low,
	close,
	open,
	qty,
	oi,
	columnWidth,
	oiFactor,
	updateOIFactor,
}) => {
	const [showContent, setShowContent] = React.useState(wait);
	columnWidth = 100;

	if ((high - low) / columnWidth > oiFactor) {
		updateOIFactor((high - low) / columnWidth);
	}

	// console.log('OI CHANGE ', oiFactor)

	// console.log(oi)
	const wickOne = React.useRef(null);
	const wickTwo = React.useRef(null);
	const redCandle = React.useRef(null);
	const greenCandle = React.useRef(null);

	const xFactor = (oi) => {
		// console.log(oi)
		if (oi <= 100000) return 40000;
		else if (oi > 100000 && oi <= 1000000) return 45000 / 2;
		else if (oi > 1000000 && oi <= 2000000) return 55000 / 2;
		else if (oi > 1000000 && oi <= 2000000) return 65000 / 2;
		else if (oi > 2000000 && oi <= 3000000) return 75000 / 2;
		else if (oi > 3000000 && oi <= 9000000) return 85000 / 2;
		else if (oi > 3000000 && oi <= 9000000) return 85000 / 2;
		else if (oi > 9000000 && oi <= 20000000) return 95000 / 2;
		// else return 1662589000
		else return 50000;
	};

	// const _count = oi <= 100000 ? 4000 : oi > 100000 && oi <= 1000000 ? 5000 : 7500

	const formula = {
		count: xFactor(oi) * qty,
		// count: 250000,
		// count: 400  * qty,
	};

	// console.log(formula.count)

	//   high = 1632250;
	//   low = 2327150;
	//   close = 30047250;
	//   open = 135300;

	//   high = high;
	//   low = low;
	//   close = close > 30047250 ? 30047250 : close;
	//   open = open;

	// console.log('values h l o c q -- ', high, low, open, close, qty, oi)
	// if(oi > (high)) {
	//     console.log(`oi IS HIGHEST -- ${HIG}`)
	// }
	// else {
	//     console.log(`HIGH IS GREATER THAN oi`)
	// }

	const [db, setDb] = React.useState(null);

	let datasource = [];
	React.useEffect(() => {
		// createData()
		createLine();
		// console.log('OI HAS CHANGED')
	}, [high, low, open, close, qty, oiFactor]);

	React.useEffect(() => {
		let timer = setTimeout(() => {
			setShowContent(true);
			clearTimeout(timer)
			timer = null
			wait = true
		}, 5000);

		// Cleanup timer if the component unmounts before the timeout
		return () => clearTimeout(timer);
	}, []);

	/**
	 * STEPS OR ALGO
	 * 1. CREATE A LINE EQUIVALENT TO THE PIXELS OF THE HIGH
	 * 2. SHOW THE LINE WITH LIMIT AS HIGH
	 * 3. CROP THE LINE STARTING LOW POINT AND SHOW THE ACTUAL RESULT
	 * 4. CALCULATE THE MEAN OF THE LINE - MARK THE MEAN
	 * 5. GET THE CURRENT OI AND DRAW A CANDLESTICK FROM MEAN TO CURRENT VALUE WITH APPROPRIATE COLOR
	 */

	const createLine = () => {
		// BRAIN FCUK!
		try {
			// console.log(`open ${open}`)
			// console.log('oi val', open, close, high, low, oiFactor)
			const properties = {
				w1: open > close ? (close - low) / oiFactor : (open - low) / oiFactor,
				cr: open > close ? (open - close) / oiFactor : 0,
				cg: open > close ? 0 : (close - open) / oiFactor,
				w2: open > close ? (high - open) / oiFactor : (high - close) / oiFactor,
			};

			// if(properties.cg === 100) {
			// 	console.log('OI CHANGE ', open, high, low, close, oiFactor)
			// }
			// console.log(properties)
			wickOne.current.style.height = Math.abs(open - close) ? "1.25px" : "0px";
			wickTwo.current.style.height = Math.abs(open - close) ? "1.25px" : "0px";
			wickOne.current.style.width = Math.abs(open - close)
				? properties["w1"] + "px"
				: "0px";
			wickTwo.current.style.width = Math.abs(open - close)
				? properties["w2"] + "px"
				: "0px";
			redCandle.current.style.width = properties["cr"] + "px";
			greenCandle.current.style.width = properties["cg"] + "px";
			// redCandle.current.style.width =
			//   properties["cr"] && properties["cr"] < 100
			//     ? properties["cr"] + "px"
			//     : "100px";
			// greenCandle.current.style.width =
			//   properties["cg"] && properties["cg"] < 100
			//     ? properties["cg"] + "px"
			//     : "100px";
		} catch (e) {
			console.log("CREATE LINE ERROR -- ", e);
		}
	};

	// console.log(wickTwo?.current?.style?.width, greenCandle?.current?.style?.width)

	return (
		<>
			<div className={`w-full my-2 justify-center overflow-x-auto ${showContent ? 'flex' : 'hidden'}`}>
				<div className="text-right !w-2/4 flex justify-end items-center">
					<div className="bg-[#757575]" ref={wickOne}></div>
					<div
						className={
							low == close ? "bg-red-800 h-[10px]" : "bg-red-500 h-[10px]"
						}
						ref={redCandle}
					></div>
				</div>
				<div className="text-left !w-2/4 flex justify-start items-center">
					<div
						className={
							high == close
								? "bg-green-800 h-[10px]"
								: "bg-green-500 h-[10px]"
						}
						ref={greenCandle}
					></div>
					<div className="bg-[#757575]" ref={wickTwo}></div>
				</div>
			</div>
			{!showContent ? <Skeleton variant="rectangular" width={100} height={10} /> : null}
		</>
	);
};

export default OIChange;
