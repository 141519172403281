import { AppBar, Avatar, Box, Button, Container, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDimensions } from '../Contexts/DimensionContext/DimensionsContext';
import HomeIcon from "@mui/icons-material/Home";
import { ToolsProvider } from '../Contexts/ToolsContext/ToolsContext';
import { LightbulbRounded, Logout, Newspaper } from '@mui/icons-material';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import Searchcomponent from '../EquityComponents/Searchcomponent';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SignalDrawer from '../EquityComponents/SignalDrawer';
import DoneIcon from "@mui/icons-material/Done";
import Menu from "@mui/material/Menu";
import ErrorIcon from "@mui/icons-material/Error";
import MenuItem from "@mui/material/MenuItem";
import { EditOutlined, SearchOutlined } from "@material-ui/icons";
import Badge from '@mui/material/Badge';
import { WebSocketProvider } from '../Contexts/WebsocketContext/WebSocketContext';
import { ApiService, http } from '../Services/api.services';
import { useThemeContext } from '../Theme/ThemeContext';
import AddIcon from '@mui/icons-material/Add';
import StepperDialogue from "../PublicComponents/StepperDialogue";
import BrokerForm from '../PublicComponents/BrokerForm';
import { showToast } from '../store/Actions/Action';
import { WEBSOCKET_URL } from '../default.config';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Logo from "../assets/fastr_logo.png";
import { debounce } from 'lodash';
import { count } from 'rxjs';

const api = new ApiService();


export default function PublicLayout() {
    const { theme } = useThemeContext();
    const { user } = useSelector((state) => state.user);
    const home = useSelector((state) => state.optionHome);

    const selectedRoute =
        "text-[#373737] font-semibold text-[13px] uppercase m-0";
    const notSelected =
        "text-[#757575] font-normal uppercase text-[13px] m-0 cursor-pointer";
    const disabledRoute =
        "text-[#C0C0C0CA] font-normal uppercase text-[13px] m-0";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let defualtpage = { page: 0, pageSize: 20 }
    const { xs, sm, md, lg, xl, xxl } = useDimensions();
    const [openSignal, setOpenSignal] = useState(false);
    const [data, setData] = useState([]);
    const [signalPagination, setSignalPagination] = useState(defualtpage);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [openStepper, setOpenStepper] = React.useState(false);
    const [brokers, setBrokers] = React.useState(home.brokers);
    const [openBroker, setOpenBroker] = React.useState(false);
    let _data = []
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const open1 = Boolean(anchorEl1);
    const [skip, setSkip] = React.useState(0)
    const [limit, setLimit] = React.useState(20)
    const [socketData, setSocketData] = React.useState(null);

    //    getSignal function for get Signals 

    const getSignal = async (skip, limit) => {
        try {
            const res = await http.get(`signals/?skip=${skip}&limit=${limit}`)
            if (res?.status === 200) {
                // console.log(res)
                _data = res?.data
                setData(res?.data);
            } else {
                console.log('error occour')
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING SIGNALS ${e}`);
        }
    }

    useEffect(() => {
        getSignal(skip, limit);
    }, [skip, limit]);

    React?.useEffect(() => {
        try {

            const socket = new WebSocket(
                `${WEBSOCKET_URL}/ws/signal`
            );

            socket.onopen = () => {
                console.log("WebSocket connection established");
            };

            socket.onmessage = (event) => {
                const message = JSON.parse(event.data);

                if (message?.data) {
                    let element = { ...message?.data }
                    let array = [..._data];
                    array.unshift(element);
                    // Ensure the array length does not exceed 20
                    if (array.length > 20) {
                        array.pop();
                    }
                    _data = array
                    setData(array)

                }

            };

            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
            };

            socket.onclose = (event) => {
                console.log("WebSocket connection closed:", event);
            };

            return () => {
                socket.close();
            };
        } catch (e) {
            console.log(e);
        }
    }, []);

    // let count = 0;
    // useEffect(() => {
    //     // console.log(count++, 'scount --');
    //     console.log(socketData, '-socketDat ---');
    // }, [socketData])

    // Debounced version of getsignals
    const debouncedGetSignals = React.useCallback(debounce(getSignal, 100), []);

    // React.useEffect(() => {
    //     debouncedGetSignals(skip, limit);
    // }, [skip, limit, socketData, debouncedGetSignals]);

    // GET NOTIFICATION DATA BY SOCKET EVENT 
    React?.useEffect(() => {

        const socket = new WebSocket(
            `${WEBSOCKET_URL}/ws/notification`
        );

        socket.onopen = () => {
            console.log("WebSocket connection established for positions");

            // Send a message to the server after the connection is open
            socket.send(user?.id);
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            // getSignal(skip, limit);
            setSocketData(data);
            debouncedGetSignals(skip, limit);
            // debouncedGetSignals();
            const _snackbar = {
                message: data?.stock_data?.name
                    ? `${data.stock_data.name} has hit the price of ${data?.new_data?.price || 'an unknown value'}`
                    : 'New notification!',
                duration: 3000,
                open: true,
                type: 'success'
            }
            dispatch(showToast(_snackbar))
        };

        socket.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        socket.onclose = (event) => {
            console.log("WebSocket connection closed for positions:", event);
        };

        return () => {
            socket.close();
        };
    }, []);

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleClick1 = (event) => {
        console.log(event.currentTarget);
        setAnchorEl1(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = async () => {
        const token = localStorage.getItem("token");
        const response = await api.logout({ token });
        localStorage.clear();
        window.location.reload();
    };

    const updateBroker = () => {
        setOpenBroker(true);
        handleCloseUserMenu();
    };

    const connectBroker = () => {
        localStorage.setItem("user_id", user?.id)
        window.location.href = localStorage.getItem("auth-connect");
    };

    return (
        <>
            <div className="flex-1 flex  flex-col h-screen overflow-hidden">
                <AppBar position="static" className="!bg-white !shadow w-full">
                    <Container
                        sx={{ width: "100%" }}
                        className="!px-3 !max-w-full py-0 lg:!px-4"
                    >
                        <Toolbar
                            disableGutters
                            className="flex items-center justify-between"
                        >
                            <img style={{ height: 32 }} src={Logo} alt="fastr.png" />
                            <div className="flex items-center gap-2 mx-2">
                                <Button
                                    id="basic-button"
                                    aria-controls={open1 ? "basic-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open1 ? "true" : undefined}
                                    onClick={handleClick1}
                                    endIcon={<ArrowDropDownIcon />}
                                    size="small"
                                    variant="outlined"
                                >
                                    Equity
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl1}
                                    open={open1}
                                    onClose={handleClose1}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            handleClose1();
                                            navigate("/equity");
                                        }}
                                    >
                                        Equity
                                    </MenuItem>
                                    <Tooltip title='coming soon'>
                                        <MenuItem
                                            onClick={() => {
                                                handleClose1();
                                                const _snackbar = {
                                                    message: 'Options Coming Soon!',
                                                    duration: 1000,
                                                    open: true,
                                                    type: 'info'
                                                }
                                                dispatch(showToast(_snackbar))
                                            }}
                                            sx={{
                                                opacity: 0.5
                                            }}
                                        >
                                            Options
                                        </MenuItem>
                                    </Tooltip>
                                </Menu>
                                <div className="w-[1px] h-8 bg-gray-300"></div>
                            </div>
                            <div className="flex items-center gap-10 lg:gap-4">
                                <Tooltip title="Home">
                                    <IconButton
                                        onClick={() => navigate("/equity")}
                                    >
                                        <HomeIcon
                                            sx={{
                                                color: location?.pathname === "/equity"
                                                    ? theme.palette.text.charcolGray
                                                    : theme.palette.text.silverGray,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                {xs || sm || md ? (
                                    <ToggleButtonGroup sx={{ border: "none" }}>
                                        <Tooltip title="Coming Soon" enterTouchDelay={0}>
                                            <ToggleButton
                                                sx={{ borderWidth: "0px" }}
                                                variant="text"
                                                size="small"
                                                onClick={() => navigate('algorithm')}
                                            >
                                                <h6 className={
                                                    location?.pathname?.includes("/equity/algorithm")
                                                        ? selectedRoute
                                                        : notSelected
                                                }>AL</h6>
                                            </ToggleButton>
                                        </Tooltip>
                                        <Tooltip title="INDEX OPTIONS" enterTouchDelay={0}>
                                            <ToggleButton
                                                sx={{ borderWidth: "0px" }}
                                                variant={"text"}
                                                size="small"
                                                onClick={() => navigate("watchlist")}
                                            >
                                                <h6
                                                    className={
                                                        location?.pathname?.includes("/equity/watchlist")
                                                            ? selectedRoute
                                                            : notSelected
                                                    }
                                                >
                                                    WL
                                                </h6>
                                            </ToggleButton>
                                        </Tooltip>
                                    </ToggleButtonGroup>
                                ) : (
                                    <div className="flex items-center gap-10 lg:gap-4">
                                        <Tooltip title="Algorithms">
                                            <Button variant="text" size="small"
                                                onClick={() => navigate('algorithm')}
                                            >
                                                <h6 className={
                                                    location?.pathname?.includes("/equity/algorithm")
                                                        ? selectedRoute
                                                        : notSelected
                                                }>Algos</h6>

                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Watchlists">
                                            <Button
                                                variant={"text"}
                                                size="small"
                                                onClick={() => navigate("watchlist")}
                                            >
                                                <h6
                                                    className={
                                                        location?.pathname?.includes("/equity/watchlist")
                                                            ? selectedRoute
                                                            : notSelected
                                                    }
                                                >
                                                    watchlist
                                                </h6>
                                            </Button>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                            <div className=" flex items-center w-full">
                                <Searchcomponent />
                            </div>
                            <div className="flex items-center gap-10">
                                <Tooltip
                                    className="lg:flex lg:gap-2"
                                    title="signal"
                                >
                                    <Badge color="primary" variant="dot" >
                                        <IconButton
                                            onClick={() => setOpenSignal(!openSignal)}
                                            variant="text"
                                            size="small"
                                        >
                                            <NotificationsIcon
                                                sx={{
                                                    color: location?.pathname?.includes(
                                                        "/equity/orders"
                                                    )
                                                        ? "#373737"
                                                        : "#757575",
                                                }}
                                                fontSize={lg || xl || xxl ? "small" : "medium"}
                                            />

                                        </IconButton>
                                    </Badge>
                                </Tooltip>
                                <Tooltip
                                    className="lg:flex lg:gap-2"
                                    title="Earn With Us"
                                >
                                    <Button
                                        onClick={() => navigate('orders')}
                                        variant="text"
                                        size="small"
                                    >
                                        <ReceiptLongOutlinedIcon
                                            sx={{
                                                color: location?.pathname?.includes(
                                                    "/equity/orders"
                                                )
                                                    ? "#373737"
                                                    : "#757575",
                                            }}
                                            fontSize={lg || xl || xxl ? "small" : "medium"}
                                        />
                                        {lg || xl || xxl ? (
                                            <h6
                                                className={
                                                    location?.pathname?.includes("/equity/orders")
                                                        ? selectedRoute
                                                        : notSelected
                                                }
                                            >
                                                Orders
                                            </h6>
                                        ) : null}
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    className="lg:flex lg:gap-2"
                                    title="Earn With Us"
                                >
                                    <Button
                                        onClick={() => navigate('portfolio')}
                                        variant="text"
                                        size="small"
                                    >
                                        {/* {console.log(location?.pathname?.includes(
            "/equity/portfolio"
            ))} */}
                                        <WorkOutlineIcon
                                            sx={{
                                                color: location?.pathname?.includes(
                                                    "/equity/portfolio"
                                                )
                                                    ? "#373737"
                                                    : "#757575",
                                            }}
                                            fontSize={lg || xl || xxl ? "small" : "medium"}
                                        />
                                        {lg || xl || xxl ? (
                                            <h6
                                                className={
                                                    location?.pathname?.includes("/equity/portfolio")
                                                        ? selectedRoute
                                                        : notSelected
                                                }
                                            >
                                                Portfolio
                                            </h6>
                                        ) : null}
                                    </Button>
                                </Tooltip>
                                <div className="flex  items-center gap-1 lg:gap-5">
                                    <Box sx={{ flexGrow: 0 }}>
                                        <Tooltip title="Open settings">
                                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                <div className="relative">
                                                    <div
                                                        className={`w-3 h-3 rounded-full absolute top-0 right-0 z-50 ${user?.broker?.length && user?.broker[0]?.active ? "bg-green-600" : "bg-red-600"}`}
                                                    ></div>

                                                    <Avatar
                                                        alt={user?.name}
                                                        src="/static/images/avatar/2.jpg"
                                                    />
                                                </div>
                                            </IconButton>
                                        </Tooltip>
                                        <Menu
                                            sx={{ mt: "45px" }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            {user?.broker?.length ? user?.broker[0]?.active ? (
                                                <MenuItem className="!bg-green-50">
                                                    <ListItemIcon>
                                                        <DoneIcon color="success" fontSize="small" />
                                                    </ListItemIcon>
                                                    <span className="text-slate-500">Broker Connected</span>
                                                </MenuItem>
                                            ) : (
                                                <MenuItem onClick={connectBroker} className="!bg-red-50">
                                                    <ListItemIcon>
                                                        <ErrorIcon color="error" fontSize="small" />
                                                    </ListItemIcon>
                                                    <span className="text-slate-500">Not Connected</span>
                                                </MenuItem>
                                            ) : null}
                                            {user?.broker?.length ? (
                                                <MenuItem onClick={() => updateBroker()}>
                                                    <ListItemIcon>
                                                        <EditOutlined color="primary" fontSize="small" />
                                                    </ListItemIcon>
                                                    <span className="text-slate-500">Update Broker</span>
                                                </MenuItem>
                                            ) : <MenuItem
                                                onClick={() => {
                                                    setOpenStepper(true);
                                                    handleCloseUserMenu();
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <AddIcon color="primary" fontSize="small" />
                                                </ListItemIcon>
                                                <span className="text-slate-500">Add Broker</span>
                                            </MenuItem>}
                                            <MenuItem
                                                onClick={() => {
                                                    navigate('/equity/alerts')
                                                    handleCloseUserMenu();
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <ReportProblemIcon color="red" fontSize="small" />
                                                </ListItemIcon>
                                                Alert's
                                            </MenuItem>
                                            <MenuItem onClick={handleLogout}>
                                                <ListItemIcon>
                                                    <Logout color="error" fontSize="small" />
                                                </ListItemIcon>
                                                <span className="text-red-400">Logout</span>
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </div>
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>
                <WebSocketProvider>
                    <ToolsProvider>
                        <div className='w-full flex-1 overflow-y-auto flex flex-col'>
                            <Outlet />
                        </div>
                    </ToolsProvider>
                </WebSocketProvider>
            </div>

            <SignalDrawer
                data={data}
                openSignal={openSignal}
                setOpenSignal={setOpenSignal}
                list={PublicLayout}
                seeMoreFunction={() => {
                    setLimit((currentLimit) => currentLimit + 20);
                }}
            />

            {openStepper ? (
                <StepperDialogue
                    open={openStepper}
                    setOpenStepper={setOpenStepper}
                    user={user}
                    brokers={brokers}
                    onClose={() => {
                        setOpenStepper(false);
                        handleCloseUserMenu();
                    }}
                />
            ) : null}

            {openBroker ? (
                <BrokerForm
                    open={openBroker}
                    userBroker={user?.broker?.length ? user?.broker[0] : null}
                    onClose={() => setOpenBroker(false)}
                    user={user}
                    brokers={brokers}
                    type={"update"}
                    onSubmit={(response) => dispatch(showToast(response))}
                    isFullScreen={xs || sm || md}
                />
            ) : null}
        </>
    )
}
