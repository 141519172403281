import React, { useCallback, useMemo, useState } from "react";
import { setAdminRedux, setOpenOrders, setPositions, showToast } from "../../store/Actions/Action";
import { useDispatch, useSelector } from "react-redux";
import { useDimensions } from "../../Contexts/DimensionContext/DimensionsContext";
import { ApiService, http } from "../../Services/api.services";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { expiryReducer } from "../../store/Reducer/ExpiryReducer";
import { ENDPOINTS, WEBSOCKET_URL_TICKER } from "../../default.config";
import HomeDrawer from "../../PublicComponents/HomeDrawer";
import BrokerForm from "../../PublicComponents/BrokerForm";
import { updateactivePath, updateActiveSL, updateIsEquity } from "../../store/Reducer/Reducer";
import moment from "moment";
import { useWebSocket } from "../../Contexts/WebsocketContext/WebSocketContext";
import { useMarketContext } from "../../Contexts/MarketContext/MarketContext";
import { setBasketPositions, updateCurrentPosition } from "../../store/Reducer/BasketReducer";
import { setAllMarketData, setMarketCurrentIndex } from "../../store/Reducer/MarketReducer";

const NetworkErrorImg = require("../../assets/NetworkErroImg.png");
const api = new ApiService();

// FIRST SCREEN WHERE POST LOGIN
// INITIALIZE ALL REDUX AND OTHER PROPERTIES TO BE USED IN FUTURE
let initialLoadExpiry = true;
let symbols = {};


// Debounce function
const debounce = (func, delay) => {
	let timeoutId;
	return (...args) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

const AdminAuth = () => {
	const dispatch = useDispatch();
	// WEB SOCKET FOR POSITIONS IS HANDLED THROUGH THIS useWebSocket()
	const { connectWebSocket, receivedMessages, updatePositions, updateBasketPositions, updateOrders } = useWebSocket();
	const contextMarket = useMarketContext(); //MARKET FOR CONTEXT FILE
	/**
	 * CONTEXT MARKET IS USED TO UPDATE THE REDUX 
	 **/
	const navigate = useNavigate();
	const location = useLocation();
	const { xs, sm, md, lg, xl, xxl } = useDimensions();

	// REDUX STORE
	const market = useSelector((state) => state.market);
	const home = useSelector((state) => state.optionHome);
	const { user } = useSelector((state) => state.user);
	const { ticks } = useSelector((state) => state.ticks);
	const { admin } = useSelector((state) => state.admin);
	const { positions, positionType } = useSelector((state) => state.orders);
	const { basketPositions, currentPosition } = useSelector((state) => state.Baskets);
	let rankings = useSelector((state) => state.rankings);
	const allCurrentExpiry = useSelector((state) => state.allCurrentExpiry);

	// STATES
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const [login_url, setLogin_url] = React.useState("");
	const [openBroker, setOpenBroker] = React.useState(false);
	const [planRoute, setPlanRoute] = React.useState(false);
	const [brokers, setBrokers] = React.useState([]);
	const [isDrawerOpen, setDrawerOpen] = React.useState(false);
	const [currentIndex, setCurrentIndex] = React.useState(
		home?.currentIndex || "nifty"
	);
	const pathnameSegments = location.pathname.split("/");
	const route = pathnameSegments[pathnameSegments?.length - 1];
	const [isNetworkError, setIsNetworkError] = React.useState(false);

	const [positionState, setPositionState] = React.useState(positions);
	const [basketPositionState, setBasketPositionState] = React.useState(basketPositions);

	const [allSymbols, setAllSymbols] = useState({
		nifty: { ce: {}, pe: {} },
		banknifty: { ce: {}, pe: {} },
		finnifty: { ce: {}, pe: {} },
		midcpnifty: { ce: {}, pe: {} },
		sensex: { ce: {}, pe: {} },
	});

	var ticker;
	const _id = admin ? admin?.id : user?.id;
	const memoizedTicks = useMemo(() => ticks, [ticks]);

	// USE EFFECT TO UPDATE ACTIVE PATH IN REDUX STORE
	React.useEffect(() => {
		if (location.pathname == '/admin/options') dispatch(updateactivePath(["admin", "options", "strikes"]));
		else dispatch(updateactivePath(location.pathname.split("/")));
		if (location.pathname.includes("equity")) dispatch(updateIsEquity([true, "equity"]));
		else dispatch(updateIsEquity([false, "options"]));
	}, [location.pathname]);

	// CHECK FOR ADMIN LOGIN
	React.useEffect(() => {
		let _admin = localStorage.getItem("admin");
		_admin = _admin ? JSON.parse(_admin) : null;
		if (_admin) fetchAdmin(_admin.user_id);
		else navigate("/admin");
	}, []);

	React.useEffect(() => {
		// SET CURRENT INDEX ON THE CHANGE EVENT TRIGGERED FROM STRIKES SCREEN
		// UPADTE TO REDUX
		if (home.activePath?.includes("strikes")) {
			setCurrentIndex(home?.currentIndex);
			contextMarket.setCurrentIndex(home?.currentIndex);
			// dispatch(setMarketCurrentIndex(home?.currentIndex))
			contextMarket.setCurrentExpiry(allCurrentExpiry[home?.currentIndex]);
		}
	}, [home?.currentIndex]);

	// IF RANKINGS ARE CHANGED - BELOW EVENT IS TRIGGERED
	React.useEffect(() => {
		if (market[currentIndex]?.options && market[currentIndex]?.options?.CE) updateRankings();

	}, [rankings]);

	// UPDATE POSITIONS EVENT
	React.useEffect(() => {
		/**
		 * updatePositions IS UPDATED FROM WebSocketContext.jsx
		 * ON NEW POSITION WEBSOCKET EVENT THIS CALLBACK IS TRIGGERED
		 */
		// console.log(updatePositions)
		if (updatePositions) {
			if (admin) {
				updatePositionsInstruments(updatePositions);
			}
		}
	}, [updatePositions]);

	const updatePositionsInstruments = (position) => {
		try {
			// FUNCTION USED TO UPDATE THE POSITION INSTRUMENT DATA 
			// AND FURTHER SENT TO WEB WORKER FOR MORE PROCESS
			dispatch(setPositions(positionAppend(position))); //UPDATE TO REDUX
			const positions = {
				...market.positions,
				...{ [parseInt(position.instrumenttoken)]: {} },
			};
			// console.log(positions)
			// SEND TO WEB WORKER
			contextMarket.updatePositionsWS({
				updatePositions: true,
				positions,
			});
			// contextMarket.updateIndex("positions", positions);
		}
		catch (e) {
			console.log(`ERROR WHILE UPDATING POSITIONS INSTRUMENTS TO CONTEXT ${e}`);
		}
	}

	const returnCurrentBasketPosition = () => {
		return currentPosition
	}

	const getAllPositions = async (id, _current) => {
		try {
			const response = await http.get(`orders/${id}/basket/position`);
			if (response?.status === 200) {
				dispatch(setBasketPositions(response?.data?.data));
				if (response?.data?.data?.length > 0) {
					// UPDATE CURRENT BASKET POSITION
					for (let index = 0; index < response.data.data.length; index++) {
						const basketPosition = response.data.data[index];
						// console.log(_current?.id, basketPosition.basket_id)
						if (_current) {
							if (_current?.id === basketPosition.basket_id) {
								dispatch(updateCurrentPosition(basketPosition));
							}
						}
					}
				}
			}
			else {
				dispatch(setBasketPositions([]));
			}
		} catch (e) {
			dispatch(setBasketPositions([]));
			console.log(`ERROR WHILE FETCHING POSITIONS ${e}`);
		}
	}

	const debouncedGetAllPositions = useCallback(
		debounce((id, _currentPosition) => {
			// console.timeEnd('START')
			getAllPositions(id, _currentPosition);
		}, 500),
		[]
	)

	// UPDATE BASKET BASKET POSITIONS
	React.useEffect(() => {
		// ADD DEBOUNCE 
		console.log(updateBasketPositions)
		if (updateBasketPositions['instrumenttoken'] && !market.basketPositions[parseInt(updateBasketPositions['instrumenttoken'])]) {
			contextMarket.appendBasketPositions(parseInt(updateBasketPositions['instrumenttoken']))
		}
		if (admin) {
			debouncedGetAllPositions(admin?.id, currentPosition)
		}
	}, [updateBasketPositions]);

	React.useEffect(() => {
		setPositionState(positions);
	}, [positions]);

	React.useEffect(() => {
		if (admin) {
			if (updateOrders)
				fetchOpenOrders(admin?.id);
		}
	}, [updateOrders]);

	/*************************** */

	const positionAppend = (latestPosition) => {
		/**
		 * ADDS AND UPDATES THE LATEST POSITION TO ALREADY STORED POSITIONS
		 */
		try {
			const storedPositions = positionState ? [...positionState] : [];
			if (storedPositions && storedPositions?.length) {
				// FIND THE INDEX OF POSITIONS WITH SAME _id
				const _index = storedPositions.findIndex((pos) => pos._id == latestPosition?._id);
				if (_index > -1) storedPositions[_index] = latestPosition;
				else storedPositions.unshift(latestPosition);
				return storedPositions;
			}
			else {
				storedPositions.push(latestPosition);
				return storedPositions;
			}
		}
		catch (e) {
			console.log(`ERROR WHILE UPDATING POSITION ${e}`);
		}
	};

	const basketPositionAppend = (latestPosition) => {
		/**
		 * SAME AS POSITONS - BASKET POSITONS ARE ALOS UPDATED
		 */
		try {
			const storedPositions = basketPositionState ? [...basketPositionState] : [];
			if (storedPositions && storedPositions?.length) {
				// FIND THE INDEX OF POSITIONS WITH SAME _id
				const _index = storedPositions.findIndex(
					(pos) => pos._id == latestPosition?._id
				);
				if (_index > -1) {
					storedPositions[_index] = latestPosition;
				} else storedPositions.unshift(latestPosition);
				return storedPositions;
			} else {
				storedPositions.push(latestPosition);
				return storedPositions;
			}
		} catch (e) { }
	};

	const wait = (duration) => {
		return new Promise((resolve, reject) => {
			try {
				setTimeout(() => {
					resolve(true);
				}, duration);
			} catch (e) {
				reject(e);
			}
		});
	};

	// FIRST THING FIRST - FETCH ADMIN DATA
	const fetchAdmin = async (id, attempt = 1) => {
		try {
			const url = `admin/fetch-admin/${id}`;
			const response = await http.get(url);
			if (response.status === 200) {

				/** SET ADMIN REDUX */
				dispatch(setAdminRedux(response.data));

				/** UPDATE ACTIVE SL STARTEGY */
				dispatch(updateActiveSL(response?.data?.active_sl));

				// CHECK FOR BROKER CONNECTION FIRST
				if (!response.data.active) {
					const _snackbar = {
						message: "Broker Not Connected!",
						open: true,
						duration: 10000,
						type: "error",
					};
					dispatch(showToast(_snackbar));
				}
				// NETWORK IS WORKING
				setIsNetworkError(false);

				/**
				 * START FETCHING INDEXES
				 * WITH ALL INDEXES
				 * WITH EXPIRY EACH
				 **/
				fetchIndexes(response?.data);

				/**
				 * SET MARKET STATUS
				 * UPDATE THE SAME TO WEB WORKER
				**/
				contextMarket.setMarketOpen(response.data?.is_open);
				contextMarket.sendMessageToWorker({ marketStatus: response.data?.is_open });

				/**
				 * FETCH BASKET POSITIONS
				 * AND CONNECT THE POSITIONS WEB SOCKET
				 */
				fetchBasketPositions(id);
				connectWebSocket(id);
				return;
			} else {
				setIsNetworkError(true);
			}
		} catch (error) {
			// RETRY LOGIC
			console.log(`ERROR WHILE FETCHING ADMIN AND SETTING OTHER DATA -- ${error}`);
			if (error.message === "Network Error" && attempt < 3) {
				console.log(`RETRYING... ATTEMPT ${attempt}`);
				setTimeout(() => fetchAdmin(id, attempt + 1), 1000); // Retry after 1 second
			} else {
				// AFTER 3 ATTEMPTS OR IF A NON-NETWORK ERROR, SHOW ERROR
				setIsNetworkError(true);
				const _snackbar = {
					message: "Network Error! Please check your connection.",
					open: true,
					duration: 10000,
					type: "error",
				};
				dispatch(showToast(_snackbar));
			}
		}
	};

	const fetchIndexes = async (admin) => {
		const initialIndex = "nifty";
		const start = new Date().getTime();
		let xMarketIndexes = []
		const tickerIndex = { t1: 0, t2: 0, t3: 0 };
		let indexes = {};
		try {
			const response = await http.get("ds/indexes");
			if (response.data) {

				// SET INDEX SYMBOLS (INSTRUMENT TOKEN FOR FASTER ACCESS)
				const _oc = await Promise.all(
					returnMergedIndexExp(response.data, indexes, 0)?.map(async (merged, i) => {
						indexes = merged.indexes
						return await optionChain(admin?.id, merged.index, merged.expiry, merged.symbol, merged.margin);
					})
				)
				for (let index = 0; index < _oc.length; index++) {
					const chain = _oc[index];
					const _indexes = JSON.parse(JSON.stringify(indexes))
					_indexes[chain.index][chain.expiry] = {
						options: chain.options,
						symbols: chain.symbols,
					}
					indexes = _indexes
					contextMarket.setMarketIndex(chain?.index, _indexes[chain?.index]);
					// START TICKER LOGIC
					if (chain?.index === "nifty") {
						tickerIndex.t1++;
						if (tickerIndex.t1 == 1) {
							// START T1 TICKER
							delete tickerIndex.t1;
							const params = {
								url: `${WEBSOCKET_URL_TICKER}/ws`,
								market: indexes,
								instruments: symbols,
								ticker: "T1",
								marketStatus: contextMarket.isMarketOpen,
							};
							contextMarket.startWebSockets(params);
						}
					}
					else if (chain?.index === "banknifty" || chain?.index === "finnifty") {
						tickerIndex.t2++;
						if (tickerIndex.t2 == 2) {
							delete tickerIndex.t2;
							const params = {
								url: `${WEBSOCKET_URL_TICKER}/ws`,
								instruments: symbols,
								market: indexes,
								ticker: "T2",
								marketStatus: contextMarket.isMarketOpen,
							};
							contextMarket.startWebSockets(params);
						}
					}
					else {
						tickerIndex.t3++;
						if (tickerIndex.t3 == 3) {
							delete tickerIndex.t3;
							const params = {
								url: `${WEBSOCKET_URL_TICKER}/ws`,
								instruments: symbols,
								market: indexes,
								ticker: "T3",
								marketStatus: contextMarket.isMarketOpen,
							};
							// market.startWebSocket(params);
							// console.log('WSWORKER', params)
							contextMarket.startWebSockets(params);
						}
					}
				}
				/**
				 * ALL INDEXES ARE FETCHED
				 * SET INDEXES
				 * SET EXPIRY
				 * SET OPTION CHAIN
				 * FETCH OC FOR ALL INDEXES AND EXPIRY
				 */
				// SET INITIAL INDEX
				contextMarket.setCurrentIndex(initialIndex);
				// SEND CURRENT EXPIRY TO WEB WORKER
				contextMarket.setCurrentExpiry(response.data[initialIndex.toUpperCase()]?.["expiry"]?.[0]);
				// PROMISE.ALL CALLS ALL AT ONCE
				const oc = await Promise.all(
					returnMergedIndexExp(response.data, indexes, 1)?.map(async (merged) => {
						return await optionChain(admin?.id, merged.index, merged.expiry, merged.symbol, merged.margin);
					})
				)
				for (let i = 0; i < oc.length; i++) {
					const _indexes = JSON.parse(JSON.stringify(indexes))
					const chain = oc[i];
					_indexes[chain.index][chain.expiry] = {
						options: chain.options,
						symbols: chain.symbols,
					}
					indexes = _indexes
					contextMarket.setMarketIndex(chain?.index, _indexes[chain?.index]);
					// contextMarket.setExpiryIndex(
					// 	chain?.index,
					// 	chain.expiry,
					// 	indexes[chain?.index?.toLowerCase()][chain.expiry],
					// 	symbols
					// );
					// dispatch(setAllMarketData(JSON.parse(JSON.stringify(indexes))));
				}
				// console.log(indexes, symbols);
				contextMarket.setInstruments(symbols);
				contextMarket.updateMarketWs({
					updateTicker: true,
					instruments: symbols,
					market: indexes,
				});
				// SET OPTION CHAIN AND OTHER INDEX DATA
				console.log(`EXECUTION TIME -- ${new Date().getTime() - start}`);
				// CALL THIS BELOW FUNCTION ONLY AFTER THE WEBSOCKET IS SET IN STATE FOR ALL FIRST EXPIRY
				// xFetchExpiryIndex(xMarketIndexes, admin.id, indexes, start);
			}
		} catch (e) {
			console.log(e)
			console.log(`ERROR WHILE FETCHING INDEXES ${e}`);
		}
	};

	// console.log(market)

	/**
	 * Takes response from server and merges the required data
	 * in the desired format. If indx is 0, then it sets the
	 * initial data in the state. If indx is 1, then it sets
	 * the expiry data in the state.
	 * @param {object} response - server response
	 * @param {object} indexes - local state of indexes
	 * @param {number} indx - index number
	 * @returns {array} - array of objects with index, expiry, symbol and margin
	 */
	const returnMergedIndexExp = (response, indexes, indx) => {
		try {
			let returner = []
			const array = Object.keys(response);
			for (let index = 0; index < array.length; index++) {
				const key = array[index];
				if (!indx) {
					const instrument = response[key]['instrument']
					symbols[`'${instrument}'`] = {
						index: key?.toLowerCase(),
						type: 'index'
					}
					indexes[key?.toLowerCase()] = response[key];
					dispatch(setAllMarketData(JSON.parse(JSON.stringify(indexes))));
					dispatch(
						expiryReducer({
							type: key?.toLowerCase(),
							payload: indexes[key?.toLowerCase()]?.expiry[0],
						})
					);
					dispatch(
						expiryReducer({
							type: "timeToExpire",
							key: key?.toLowerCase(),
							payload: moment(indexes[key?.toLowerCase()]?.expiry[0]).valueOf() + 24 * 60 * 60 * 999,
						})
					);
				}
				const _exp = indx ? response[key?.toUpperCase()]?.["expiry"].slice(1) : response[key?.toUpperCase()]?.["expiry"].slice(0, 1)
				const expiries = _exp.map((exp) => {
					return {
						index: key?.toLowerCase(),
						expiry: exp,
						symbol: response[key?.toUpperCase()]["tradingsymbol"],
						margin: response[key?.toUpperCase()]["strikeMargin"],
						indexes
					}
				})
				returner = [...returner, ...expiries]
			}
			return returner
		}
		catch (e) {
			console.log(`ERRROR WHILE RETURNING INDEXES DESIRED DATA ${e}`, e);
		}
	}

	const setIndexOptionChain = async (_indexes, _index, key, admin, ts, m, tickerIndex) => {
		/**
		 * @params
		 * _indexes: all indexes
		 * _index: index object with all data againt the index
		 * key: index name in string
		 * admin: admin object 
		 * ts: tradingsymbol string for index
		 * m: margin between strikes
		 **/
		// THIS FUNCTIONS FETHCES THE OPTION CHAIN AND SET MISC DATA TO INDEX
		return new Promise(async (resolve, reject) => {
			try {
				const option = await _fetchOptionChain(admin, _index, ts, m);
				_indexes[key?.toLowerCase()][_index?.expiry[0]]["symbols"] = {
					CE: {},
					PE: {},
				};
				_indexes[key?.toLowerCase()][_index?.expiry[0]]["options"] = option;
				// console.log(symbols)
				symbols = { ...symbols };
				symbols[`'${_index?.instrument}'`] = {
					index: key?.toLowerCase(),
					type: "index",
				};
				symbols = { ...symbols };
				if (_indexes[key?.toLowerCase()][_index?.expiry[0]]["options"]["CE"] &&
					_indexes[key?.toLowerCase()][_index?.expiry[0]]["options"]["PE"]
				) {
					const _ce = Object.keys(
						_indexes[key?.toLowerCase()][_index?.expiry[0]]["options"]["CE"]
					);
					const _pe = Object.keys(
						_indexes[key?.toLowerCase()][_index?.expiry[0]]["options"]["PE"]
					);
					_ce.map((_c) => {
						_indexes[key?.toLowerCase()][_index?.expiry[0]]["symbols"]["CE"][
							_c
						] = true;
						symbols[_c] = {
							index: key?.toLowerCase(),
							type: "CE",
							expiry: _index?.expiry[0],
						};
					});
					_pe.map((_p) => {
						_indexes[key?.toLowerCase()][_index?.expiry[0]]["symbols"]["PE"][
							_p
						] = true;
						symbols[_p] = {
							index: key?.toLowerCase(),
							type: "PE",
							expiry: _index?.expiry[0],
						};
					});
				}
				// console.log('MARKET SNAPSHOT --- ', getSnapshot(market))
				// market.setMarketIndex(key?.toLowerCase(), _indexes[key?.toLowerCase()]);
				contextMarket.setExpiryIndex(
					key?.toLowerCase(),
					_index.expiry[0],
					_indexes[key?.toLowerCase()][_index.expiry[0]],
					symbols
				);
				dispatch(
					expiryReducer({
						type: key?.toLowerCase(),
						payload: _index?.expiry[0],
					})
				);
				dispatch(
					expiryReducer({
						type: "timeToExpire",
						key: key?.toLowerCase(),
						payload: moment(_index?.expiry[0]).valueOf() + 24 * 60 * 60 * 999,
					})
				);
				contextMarket.setInstruments(symbols);
				// if (key?.toLowerCase() == "nifty") {
				// 	tickerIndex.t1++;
				// 	if (tickerIndex.t1 == 1) {
				// 		delete tickerIndex.t1;
				// 		const params = {
				// 			url: `${WEBSOCKET_URL_TICKER}/ws`,
				// 			market: _indexes,
				// 			instruments: symbols,
				// 			ticker: "T1",
				// 			marketStatus: contextMarket.isMarketOpen,
				// 		};
				// 		// market.startWebSocket(params);
				// 		// console.log('WSWORKER', params)
				// 		contextMarket.startWebSockets(params);
				// 	}
				// } else if (
				// 	key?.toLowerCase() == "banknifty" ||
				// 	key?.toLowerCase() == "finnifty"
				// ) {
				// 	tickerIndex.t2++;
				// 	if (tickerIndex.t2 == 2) {
				// 		delete tickerIndex.t2;
				// 		const params = {
				// 			url: `${WEBSOCKET_URL_TICKER}/ws`,
				// 			instruments: symbols,
				// 			market: _indexes,
				// 			ticker: "T2",
				// 			marketStatus: contextMarket.isMarketOpen,
				// 		};
				// 		// market.startWebSocket(params);
				// 		// console.log('WSWORKER', params)
				// 		contextMarket.startWebSockets(params);
				// 	}
				// } else {
				// 	tickerIndex.t3++;
				// 	if (tickerIndex.t3 == 3) {
				// 		delete tickerIndex.t3;
				// 		const params = {
				// 			url: `${WEBSOCKET_URL_TICKER}/ws`,
				// 			instruments: symbols,
				// 			market: _indexes,
				// 			ticker: "T3",
				// 			marketStatus: contextMarket.isMarketOpen,
				// 		};
				// 		// market.startWebSocket(params);
				// 		// console.log('WSWORKER', params)
				// 		contextMarket.startWebSockets(params);
				// 	}
				// }
				resolve(symbols);
			} catch (e) {
				console.log(`ERROR WHILE SETTING VALUES TO INDEX ${key} -- `, e);
				reject(e);
			}
		});
	};

	const xFetchExpiryIndex = async (keys, adminId, indexes, startTime) => {
		try {
			for (let index = 0; index < keys.length; index++) {
				const key = keys[index];
				const oc = await Promise.all(
					indexes[key]["expiry"]?.map(async (exp, i) => {
						if (i)
							return {
								o: await setOtherExpiryOptionChain(
									key,
									exp,
									adminId,
									indexes[key]
								),
								e: exp,
							};
					})
				);
				// console.log(oc)
				oc.map((o) => {
					if (o) {
						// console.log(key, o.e, o.o)
						indexes[key][o.e] = o.o[o.e];
						contextMarket.setExpiryIndex(key, o.e, indexes[key][o.e], symbols);
					}
				});
				// console.log(indexes[key])
			}
			// market.setInstruments(symbols)
			contextMarket.updateMarketWs({
				updateTicker: true,
				instruments: symbols,
				market: indexes,
			});
			contextMarket.updateAllow(false);
			//   console.log(
			//     `ALL EXPIRY OF INDEXES -- EXECUTION TIME -- ${
			//       new Date().getTime() - startTime
			//     }`
			//   );
		} catch (e) {
			console.log(`ERROR IN xFetchExpiryIndex -- `, e);
		}
	};

	const returnMarketbyValue = (key) => {
		// console.log(market[key])
		return JSON.parse(JSON.stringify(market[key]));
	};

	const setOtherExpiryOptionChain = (key, exp, adminId, index) => {
		// const index = market[key]
		return new Promise(async (resolve, reject) => {
			try {
				// console.log(`${key} - ${exp} `, index)
				const options = await fetchOptionChainWithExpiry(
					adminId,
					key,
					exp,
					index["tradingsymbol"],
					index["strikeMargin"]
				);
				index[exp] = {};
				index[exp]["symbols"] = { CE: {}, PE: {} };
				symbols = { ...symbols };
				index[exp]["options"] = options;
				if (index[exp]["options"]["CE"] && index[exp]["options"]["PE"]) {
					const _ce = Object.keys(index[exp]["options"]["CE"]);
					const _pe = Object.keys(index[exp]["options"]["PE"]);
					_ce.map((_c) => {
						index[exp]["symbols"]["CE"][_c] = true;
						symbols[_c] = {
							index: key,
							type: "CE",
							expiry: exp,
						};
					});
					_pe.map((_p) => {
						index[exp]["symbols"]["PE"][_p] = true;
						symbols[_p] = {
							index: key,
							type: "PE",
							expiry: exp,
						};
					});
				}
				// console.log(symbols)
				// market.updateIndexPromise(key, index);
				contextMarket.setInstruments(symbols);
				resolve(index);
			} catch (e) {
				console.log(`ERROR WHILE FETCHING OTHER EXPIRY OPTION CHAIN - `, e);
				reject(e);
			}
		});
	};

	const lastdayTicks = () => {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await http.get("ds/last_tick");
				if (response.status == 200) resolve(response.data);
				else reject(null);
			} catch (e) {
				reject(e);
			}
		});
	};


	// NEW FUNCTION TO FETCH OPTION CHAIN - RETURNS PROMISE
	const _fetchOptionChain = async (usr, indexFuture, tradingsymbol, margin) => {
		return new Promise(async (resolve, reject) => {
			try {
				// console.log(indexFuture)
				const url = `${ENDPOINTS.OPTION_CHAIN}/${usr?.id}?index=${indexFuture.index}&expiry=${indexFuture.expiry[0]}&tradingsymbol=${tradingsymbol}&margin=${margin}`;
				const response = await http.get(url);
				if (response.status == 200) resolve(response.data.data);
				else resolve({});
			} catch (e) {
				console.log("NEW OPTION CHAIN FETCH ERR -- ", e);
				resolve({});
			}
		});
	};

	/**
	 * @function optionChain
	 * @description Fetches option chain data for given index, expiry, symbol and margin.
	 * @param {string} index - Index name.
	 * @param {string} expiry - Expiry date.
	 * @param {string} symbol - Trading symbol.
	 * @param {number} margin - Margin between strikes.
	 * @returns {Promise} Promise which resolves with option chain data.
	 */
	const optionChain = (id, index, expiry, symbol, margin) => {
		return new Promise(async (resolve, reject) => {
			try {
				const queryParams = `index=${index?.toUpperCase()}&expiry=${expiry}&tradingsymbol=${symbol}&margin=${margin}`
				const url = `${ENDPOINTS.OPTION_CHAIN}/${id}?${queryParams}`;
				const response = await http.get(url);
				if (response.status == 200) resolve({
					options: response.data.data,
					symbols: recordSymbols(response.data.data, index, expiry),
					index,
					expiry
				});
				else reject(new Error(400));
			}
			catch (e) {
				console.log(`ERROR WHILE FETCHING OPTION CHAIN -- `, e);
			}
		})
	}
	const recordSymbols = (oc, index, expiry) => {
		const obj = {
			CE: {},
			PE: {}
		}
		try {
			const callInstruments = Object.keys(oc?.CE);
			const putInstruments = Object.keys(oc?.PE);
			callInstruments.map(_c => {
				obj["CE"][_c] = true
				symbols[_c] = {
					type: "CE",
					index,
					expiry,
				}
			})
			putInstruments.map(_p => {
				obj["PE"][_p] = true
				symbols[_p] = {
					type: "PE",
					index,
					expiry,
				}
			})
			return obj
		}
		catch (e) {
			console.log(`ERROR WHILE RECORDING SYMBOLS`)
		}
	}

	const fetchOptionChainWithExpiry = async (
		adminId,
		index,
		expiry,
		symbol,
		margin
	) => {
		return new Promise(async (resolve, reject) => {
			try {
				const OC_URL = `${ENDPOINTS.OPTION_CHAIN
					}/${adminId}?index=${index?.toUpperCase()}&expiry=${expiry}&tradingsymbol=${symbol}&margin=${margin}`;
				const response = await http.get(OC_URL);
				if (response.status == 200) resolve(response.data.data);
				else reject(new Error(400));
			} catch (e) {
				console.log(`ERROR WHILE FETCHING OPTION CHAIN WITH EXPIRIES -- ${e}`);
				reject(e);
			}
		});
	};

	// ON EXPIRY CHANGE
	const fetchOptionChainExpiry = async (currentIndex, expiry) => {
		const ticker = {
			nifty: "T1",
			banknifty: "T2",
			finnifty: "T2",
			midcpnifty: "T3",
			sesex: "T3",
			bankex: "T3",
		};
		try {
			const _market = {
				...market[currentIndex][allCurrentExpiry[currentIndex]],
			};
			_market["options"] = await _fetchOptionChain(
				admin,
				{ index: currentIndex?.toUpperCase(), expiry: [expiry] },
				market[currentIndex]?.tradingsymbol,
				market[currentIndex]?.strikeMargin
			);
			const symbols = { ...market.instruments };
			symbols[`'${_market?.instrument}'`] = {
				index: currentIndex,
				type: "index",
			};
			// console.log(symbols);
			if (_market?.options["CE"] && _market?.options["PE"]) {
				for (const sym in symbols) {
					if (
						symbols[sym]["index"] == currentIndex &&
						symbols[sym]["type"] != "index"
					) {
						delete symbols[sym];
					}
				}
				const _ce = Object.keys(_market["options"]["CE"]);
				const _pe = Object.keys(_market["options"]["PE"]);
				_ce.map((_c) => {
					symbols[_c] = {
						index: currentIndex?.toLowerCase(),
						type: "CE",
					};
				});
				_pe.map((_p) => {
					symbols[_p] = {
						index: currentIndex?.toLowerCase(),
						type: "PE",
					};
				});
			}
			contextMarket.setInstruments(symbols);
			// console.log('MARKET SNAPSHOT --- ', getSnapshot(market))
			contextMarket.setMarketIndex(currentIndex, {
				[allCurrentExpiry[currentIndex]]: _market,
			});
			const params = {
				url: `${WEBSOCKET_URL_TICKER}/ws`,
				instruments: symbols,
				market: JSON.parse(JSON.stringify(market)),
				ticker: ticker[currentIndex],
				update: true,
			};
			contextMarket.startWebSocket(params);
			const _snack = {
				message: "Expiry changed!",
				type: "success",
				duration: 3000,
				open: true,
			};
			dispatch(showToast(_snack));
		} catch (e) {
			console.log("ERROR WHILE FETCHING OPTION CHANIN FOR EXPIRY");
		}
	};

	const fetchBasketPositions = async (id) => {
		try {
			const response = await http.get(`orders/${id}/basket/position`);
			if (response?.status == 200) {
				dispatch(setBasketPositions(response?.data?.data));
				// console.log(response?.data?.data, 'basketPositions -- ');
				const _bp = await contextMarket.setMarketBasketPositions(response.data.data)
				// console.log('BASKET POSITONS -- ', _bp, response.data.data)
				contextMarket.updateBasketPositionsWS({
					updateBasketPositions: true,
					basketPositions: _bp,
				});
			} else {
				dispatch(setBasketPositions([]));
			}
		} catch (e) {
			dispatch(setBasketPositions([]));
			console.log(`ERROR WHILE FETCHING POSITIONS ${e}`);
		}
	};

	const fetchPositions = async (id, _type) => {
		try {
			// setRefresh(true)
			// const userId = localStorage.getItem('user_id');
			const apiUrl = `/orders/positions/${id}?r=a&type=${_type}`;
			const response = await http.get(apiUrl);
			if (response.status === 200) {
				dispatch(setPositions(response.data?.data));
				contextMarket.updatePositionsWS({
					updatePositions: true,
					positions: contextMarket.setMarketPositions(response.data.data),
				});
				fetchOpenOrders(id);
				// // console.log(response.data.data)
				// setPositions(response?.data?.data)
				// setRefresh(false)
				// if (response?.data?.data?.length)
				//     // setErrors({ message: 'Positions Updated!', open: true, type: 'success' })
				//     // DISABLED DUE TO SOCKET.IO REMOVAL
				//     if (ticks && response?.data?.data) {
				//         updatePositions(response?.data?.data)
				//     }
			}
		} catch (e) {
			// setRefresh(false)
			console.log("ERROR WHILE FETCH POSITIONS -- ", e);
		}
	};

	const fetchOpenOrders = async (id) => {
		try {
			if (id) {
				const apiUrl = `/orders/open/${id}?r=a`;
				const response = await http.get(apiUrl);
				if (response.status == 200) {
					// console.log(response.data)
					// setOpenOrders(response.data)
					dispatch(setOpenOrders(response.data));
					if (response.data?.length) {
						const _inst = {}
						for (let index = 0; index < response.data.length; index++) {
							const _order = response.data[index];
							_inst[_order?.instrument_token] = {}
							if (index == response.data?.length - 1) {
								contextMarket.appendPositionsWS({ updatePositions: true, positions: _inst })
							}
						}
					}
				}
				// else setOpenOrders([])
			}
		} catch (e) {
			setOpenOrders([]);
			console.log("error while orders ", e);
		}
	};

	// HANDLE LOGOUT
	const handleLogout = async () => {
		const token = localStorage.getItem("token");
		const response = await api.logout({ token });
		// console.log(response);
		localStorage.clear();
		window.location.reload();
	};

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	// CONTECT BROKER
	const connectBroker = () => {
		if (user?.broker?.length) window.location.href = login_url;
	};

	// UPDATE BROKER
	const updateBroker = () => {
		setOpenBroker(true);
		handleCloseUserMenu();
	};

	const updateRankings = async () => {
		try {
			const _market = JSON.parse(JSON.stringify(market));
			// console.log(_market)
			// const _rankings = JSON.parse(JSON.stringify(rankings.ranking))
			// for (let i = 0; i < ALL_RANKINGS.value.length; i++) {
			//     const _ranking = ALL_RANKINGS.value[i];
			//     // console.log(_rankings[currentIndex], _ranking)
			//     if (_rankings[currentIndex]?.includes(_ranking)) {
			//         const __market = xFindRankOC(_market, currentIndex, ALL_RANKINGS.keyInChain[i], ALL_RANKINGS.key[i], ALL_RANKINGS.htb[i])
			//         // dispatch(marketReducer({ type: currentIndex, payload: __market[currentIndex] }))
			//         market.setMarketIndex(currentIndex, __market)
			//     }
			// }
		} catch (e) {
			console.log("ERROR WHILE UPDATING RANKINGS --", e);
		}
	};

	React.useEffect(() => {
		if (admin || user) {
			const __id = admin ? admin?.id : user?.id;
			fetchPositions(__id, positionType);
		}
	}, [positionType, user, admin]);

	return (
		<div className="w-full h-full flex flex-col">
			{/* {console.log(market[currentIndex])} */}
			{admin ? (
				<div className="flex-1 flex flex-col overflow-hidden">
					<HomeDrawer
						isOpen={isDrawerOpen}
						currentRoute={location.pathname}
						onClose={() => setDrawerOpen(false)}
						openBroker={() => setOpenBroker(true)}
						onConnectBroker={() => connectBroker}
						earnwithus={() => navigate(`${planRoute}`)}
						learnwithus={() => navigate(`/home/learn-with-us`)}
						onNewsClick={() => navigate(`/home/news`)}
						onUpdateBroker={() => setOpenBroker(true)}
						onLogout={() => handleLogout()}
					/>
					{openBroker ? (
						<BrokerForm
							open={openBroker}
							userBroker={user?.broker?.length ? user?.broker[0] : null}
							onClose={() => setOpenBroker(false)}
							user={user}
							brokers={brokers}
							type={"update"}
							onSubmit={(response) => dispatch(showToast(response))}
							isFullScreen={xs || sm || md}
						/>
					) : null}
					<Outlet />
				</div>
			) : (
				<div className="w-full h-full flex justify-center items-center">
					{isNetworkError ? (
						<div className="w-full flex flex-col items-center justify-center gap-2">
							<img src={NetworkErrorImg} alt="NetworkErrorImg" />
							<h1 className="text-2xl uppercase font-bold text-[#f6908e]">
								Network Error
							</h1>
						</div>
					) : null}
				</div>
			)}
		</div>
	);
};

export default AdminAuth;
