import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useThemeContext } from "../Theme/ThemeContext";
import { LoadingButton } from "@mui/lab";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import { Box, InputAdornment, Skeleton, Switch, TextField, Tooltip } from "@mui/material";
import { H6 } from "../Theme/ThemeComponent";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { http } from "../Services/api.services";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { duration } from "moment";
import { BASE_URL } from "../default.config";
import { showToast } from "../store/Actions/Action";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    
    &:hover {
      border-color: ${grey[900]};
    }

    &:focus {
      border-color: ${grey[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? grey[600] : grey[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 14,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(18px)",
            color: "#000",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: "transparent",
                border: '1px solid #000'
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 10,
        height: 10,
        color: '#000',
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        // backgroundColor:
        //     theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: "border-box",
        backgroundColor: "#fff",
        border: '1px solid #000',
    },
}));


const filter = createFilterOptions();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const __formData = {
    stock_id: '',
    target_price: null,
    always: true
}

export default function CreateAlert({
    open,
    onHandleClose,
    getAllAlerts
}) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { theme } = useThemeContext();
    const [searchText, setSearchText] = React.useState('');
    const [companies, setCompanies] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [formData, setFormData] = React.useState({ ...__formData })
    const switchRef = React.useRef();

    // Function to fetch search results
    const fetchSearchResults = async (query) => {
        setCompanies(null)
        if (!query) {
            setCompanies([]);
            return;
        }
        try {
            const response = await http.get(`/search-instruments?search=${query}`);
            const data = response?.data
            setCompanies(data);
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    };

    // Debounced version of fetchSearchResults
    const debouncedFetchSearchResults = React.useCallback(debounce(fetchSearchResults, 300), []);

    React.useEffect(() => {
        debouncedFetchSearchResults(searchText);
    }, [searchText, debouncedFetchSearchResults]);

    // CREATE A NEW ALERTS  
    const createAlertFunction = async () => {
        setIsLoading(true);
        try {

            const _body = { ...formData }
            _body.user_id = user?.id;
            const response = await http.post(`${BASE_URL}alerts`, _body)
            if (response?.status == 200) {
                const _snackbar = {
                    message: response?.data?.message,
                    duration: 3000,
                    open: true,
                    type: 'success'
                }
                setFormData({ ...__formData })
                setSearchText('');
                dispatch(showToast(_snackbar))
                onHandleClose()
                getAllAlerts();
            } else {
                const _snackbar = {
                    message: response?.data?.message,
                    duration: 3000,
                    open: true,
                    type: 'error'
                }
                dispatch(showToast(_snackbar))
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            const _snackbar = {
                message: e?.response?.data?.detail?.message,
                duration: 3000,
                open: true,
                type: 'error'
            }
            dispatch(showToast(_snackbar))
            console.log(`ERROR WHILE CREATING WATCHLIST - `, e);

        }
    }

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={onHandleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, fontSize: '16px', color: theme.palette.text.charcolGray }} id="customized-dialog-title">
                    Create Alert
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        onHandleClose();
                        setFormData({ ...__formData });
                    }}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div className="w-96 flex flex-col items-start gap-2 lg:gap-4 py-4">
                        <Autocomplete
                            id="country-select-demo"
                            options={companies || []} // Ensure options is never null or undefined
                            autoHighlight
                            size="small"
                            loading={!companies}
                            fullWidth
                            inputValue={searchText}
                            getOptionLabel={(option) => {
                                // console.log("Current Option:", option); // Log the current option for debugging
                                const name = option.name || ''; // Use empty string if name is undefined
                                const tradingSymbol = option.tradingsymbol || ''; // Use empty string if tradingsymbol is undefined
                                return `${name} ${tradingSymbol}`.trim(); // Concatenate and trim the result
                            }}
                            onChange={(event, newValue) => {
                                setFormData((prevData) => ({
                                    ...prevData,
                                    stock_id: newValue?.stock_id,
                                }))
                            }}
                            // onInputChange={((event, newValue) => {
                            //     setSearchText(newValue)
                            // })}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ padding: 0 }} {...props}>
                                    <CheckBoxIcon
                                        fontSize="small"
                                        sx={{
                                            opacity: option?.in_watchlist ? 1 : 0,
                                            fontSize: '11px',
                                            marginRight: '5px'
                                        }}
                                    />
                                    <H6
                                        title={option?.name || option?.tradingsymbol}
                                        font="normal"
                                    />
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Search"
                                    value={searchText}
                                    fullWidth
                                    size="small"
                                    onChange={(e) => setSearchText(e.target.value)}
                                    autoComplete="new-password"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AddIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                {searchText?.length > 0 ?
                                                    <IconButton
                                                        sx={{
                                                            position: 'absolute',
                                                            right: 0
                                                        }}
                                                        onClick={() => setSearchText("")}
                                                        aria-label="delete">
                                                        <CloseIcon />
                                                    </IconButton>
                                                    : null}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <TextField
                            placeholder="price"
                            id="outlined-basic"
                            // label="Outlined"
                            fullWidth
                            variant="outlined"
                            type="number"
                            value={formData?.target_price}
                            size="small"
                            onChange={(e) => setFormData((prevData) => ({
                                ...prevData,
                                target_price: parseFloat(e.target.value)
                            }))}
                        />
                        <Textarea
                            placeholder="description..."
                            id="outlined-basic"
                            minRows={3}
                            maxRows={4}
                            aria-label="minimum height"
                            // label="Outlined"
                            fullWidth
                            sx={{
                                width: '100%'
                            }}
                            variant="outlined"
                            type="text"
                            value={formData?.title}
                            size="small"
                            onChange={(e) => setFormData((prevData) => ({
                                ...prevData,
                                title: e.target.value
                            }))}
                        />
                        <div className="flex items-center gap-2 rounded">
                            <H6 title="Once" font="medium" color={!formData?.always ? theme.palette.text.charcolGray : theme.palette.text.silverGray} />
                            {/* Assuming AntSwitch is correctly imported and setFormData is defined */}
                            <AntSwitch
                                onChange={(e) =>
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        always: !prevData?.always,
                                    }))
                                }
                                ref={switchRef}
                                checked={formData?.always}
                            />
                            <H6 title="Always" font="medium" color={formData?.always ? theme.palette.text.charcolGray : theme.palette.text.silverGray} />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        size="small"
                        variant="contained"
                        onClick={() => {
                            createAlertFunction();
                        }}
                        loading={isLoading}
                        disabled={!formData?.target_price || !formData?.stock_id}
                    >
                        Create
                    </LoadingButton>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
