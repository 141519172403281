import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    nifty: {},
    banknifty: {},
    finnifty: {},
    midcpnifty: {},
    sensex: {},
    bankex: {},
    indiaVix: {
        instrument: 264969
    },
    giftNifty: {
        instrument: 291849
    },
    positions: {},
    basketPositions: {},
    previousTicks: {},
    instruments: {},
    currentIndex: "nifty",
    isMarketOpen: false,
    oiFactor: 0
}

export const MarketReducer = createSlice({
    name: 'market',
    initialState,
    reducers: {
        setOIFactor: (state, action) => {
            state.oiFactor = action.payload;
        },
        setMarketCurrentIndex: (state, action) => {
            state.currentIndex = action.payload;
        },
        setPositionsData: (state, action) => {
            state.positions = action.payload
        },
        setBasketPositionsData: (state, action) => {
            state.basketPositions = action.payload
        },
        setInstrumentsData: (state, action) => {
            state.instruments = action.payload;
        },
        setPreviousTicksData: (state, action) => {
            state.previousTicks = action.payload;
        },
        setMarketIndexData: (state, action) => {
            const { index, data } = action.payload; // Destructure the index and data from action.payload
            // console.log('index data ---', action.payload);

            if (state[index]) { // Ensure the index exists in the state
                state[index] = data; // Update the corresponding index data
            }
        },
        // New reducer to update the entire market state
        setAllMarketData: (state, action) => {

            return {
                ...state,
                ...action.payload, // Replace the current state with the new market data
                currentIndex: state.currentIndex
            };
        }

    }
});


export const {
    setMarketCurrentIndex,
    setPositionsData,
    setBasketPositionsData,
    setInstrumentsData,
    setPreviousTicksData,
    setMarketIndexData,
    setAllMarketData,
    setOIFactor
} = MarketReducer.actions;

export default MarketReducer.reducer;