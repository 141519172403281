import React from "react";
import { Chart } from "react-google-charts";
import downTriangle from './down_triangle.svg'; // Ensure the SVG file is accessible

export const data = [
    ['X', 'Y', { type: 'string', role: 'style' }],
    [1, 3, null],
    [2, 2.5, null],
    [3, 3, null],
    [4, 4, null],
    [5, 4, null],
    [
        6,
        3,
        `point { size: 40; shape-type: image; shape-image: "https://placehold.co/600x400"; }`
    ],
    [7, 5, null],
    [8, 2.5, null],
];

const ComboChart = () => {
    const chartEvents = [
        {
            eventName: "select",
            callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();

                if (selection.length === 1) {
                    const [selectedItem] = selection;
                    const dataTable = chartWrapper.getDataTable();
                    const { row, column } = selectedItem;

                    console.log("You selected:", {
                        row,
                        column,
                        value:
                            row !== null
                                ? dataTable.getValue(row, column)
                                : dataTable.getDistinctValues(column),
                    });
                }
            },
        },
        {
            eventName: "error",
            callback: ({ chartWrapper, eventArgs }) => {
                console.error("Error:", eventArgs);
            },
        },
        {
            eventName: "ready",
            callback: ({ chartWrapper }) => {
                console.log("Chart ready:", chartWrapper);
            },
        },
    ];

    return (
        <Chart
            chartType="ScatterChart"
            width="100%"
            height="400px"
            data={data}
            chartEvents={chartEvents}
            options={{
                legend: { position: "bottom" },
                hAxis: {
                    title: 'X Axis Title',
                },
                vAxis: {
                    title: 'Y Axis Title',
                },
                pointSize: 40, // Increased size for visibility
                pointShape: 'circle', // This is a fallback if images don't load
            }}
        />
    );
};

export default ComboChart;
