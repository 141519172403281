import React, { useEffect, useState } from "react";
import { useThemeContext } from "../../Theme/ThemeContext";
import { H4, H6, P } from "../../Theme/ThemeComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from '@mui/material/Button';
import moment from "moment";
import { Box, Stack, styled, Switch } from "@mui/material";
import CompanyTable from "../../EquityComponents/CompanyTable";
import { useNavigate } from "react-router-dom";
import { http } from "../../Services/api.services";
import { BASE_URL } from "../../default.config";
import { useDispatch, useSelector } from "react-redux";
import EquityOrderDetails from "../../EquityComponents/EquityOrderDetails";

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 38,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#1890ff',
                ...theme.applyStyles('dark', {
                    backgroundColor: '#177ddc',
                }),
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
        ...theme.applyStyles('dark', {
            backgroundColor: 'rgba(255,255,255,.35)',
        }),
    },
}));

const viewOptions = [
    { key: 'DAILY', value: 'daily' },
    { key: 'WEEKLY', value: 'weekly' },
    { key: 'MONTHLY', value: 'monthly' },
    { key: 'QUARTERLY', value: 'quarterly' },
    { key: 'YEARLY', value: 'yearly' }
]

const PortfolioReport = () => {
    const dispatch = useDispatch();
    const { theme } = useThemeContext();
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [totalRows, setTotalRows] = React.useState(100);
    const [loadingData, setLoadingData] = React.useState(true);
    const [reportData, setReportData] = React.useState(null);
    const [currentView, setCurrentView] = React.useState('daily');
    const [data, setData] = React.useState(null);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [showInnerOrders, setShowInnerOrders] = React.useState(false);
    const [currentOrder, setCurrentOrder] = React.useState(null);
    const [innerOrders, setInnerOrders] = React.useState([]);

    //  TABLE COLUMN 
    const columns = [
        {
            field: "period",
            headerName: "Date",
            minWidth: 180,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                let formattedDate;
                if (currentView === 'daily') {
                    formattedDate = {
                        startDate: moment(params?.row?.period).format('D MMM YYYY'),
                    };
                } else {
                    formattedDate = {
                        startDate: moment(params?.row?.period_range?.from).format('D MMM YYYY'),
                        endDate: moment(params?.row?.period_range?.to).format('D MMM YYYY'),
                    };
                }
                return (
                    <div className="flex w-fit p-2  items-start justify-start gap-1">
                        <H6
                            title={formattedDate?.startDate}
                            color={theme.palette.text.charcolGray}
                            font='normal'
                        />
                        {formattedDate?.endDate && (
                            <H6
                                title={'- ' + formattedDate?.endDate}
                                color={theme.palette.text.charcolGray}
                                font='normal'
                            />
                        )}

                    </div>
                );
            },
        },
        {
            field: "investment_pnl", // Ensure this is unique
            headerName: "Investment Profit P/L",
            renderHeader: () => (
                <Box textAlign="center">
                    <div className="flex w-fit items-center justify-center gap-1">
                        <H6 title={'Investment'} color={theme.palette.text.charcolGray} />
                        <H6 title={'P'} color={theme.palette.text.green} />
                        <H6 title={'/'} />
                        <H6 title={'L'} color={theme.palette.text.red} />
                    </div>
                </Box>
            ),
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? params?.value?.toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
        {
            field: "investment_roi", // Change field name here
            headerName: "Investment ROI",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? params?.value?.toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
        {
            field: "investment_beta", // Change field name here
            headerName: "Investment Beta",
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "position_pnl", // Change field name here
            headerName: "Position P/L",
            renderHeader: () => (
                <Box textAlign="center">
                    <div className="flex w-fit items-center justify-center gap-1">
                        <H6 title={'Position'} color={theme.palette.text.charcolGray} />
                        <H6 title={'P'} color={theme.palette.text.green} />
                        <H6 title={'/'} />
                        <H6 title={'L'} color={theme.palette.text.red} />
                    </div>
                </Box>
            ),
            type: "number",
            minWidth: 110,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? params?.value?.toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
        {
            field: "total_pnl", // Change field name here
            headerName: "Total P/L",
            renderHeader: () => (
                <Box textAlign="center">
                    <div className="flex w-fit items-center justify-center gap-1">
                        <H6 title={'Total'} color={theme.palette.text.charcolGray} />
                        <H6 title={'P'} color={theme.palette.text.green} />
                        <H6 title={'/'} />
                        <H6 title={'L'} color={theme.palette.text.red} />
                    </div>
                </Box>
            ),
            type: "number",
            minWidth: 110,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                const formattedValue = params.value !== undefined ? params?.value?.toFixed(2) : '0.00';
                const color = params?.value >= 0 ? 'green' : 'red';
                return (
                    <div style={{ color }}>
                        {formattedValue}
                    </div>
                );
            },
        },
    ];

    // GO BACK TO THE PREVIOUS PAGE 
    const goBack = () => {
        navigate(-1);
    };

    // FETCH PORTFOLIO REPORT DATA 
    React.useEffect(() => {
        if (user) {
            getportfolioReportData(user?.id, currentView, skip, limit)
        }
    }, [user, currentView, limit, skip])

    const getportfolioReportData = async (id, view, skip, limit) => {
        setReportData(null)
        try {
            const response = await http.get(`${BASE_URL}portfolio_report/${id}/${view}?skip=${skip}&limit=${limit}`);
            if (response?.status == 200) {
                setReportData(response?.data?.data)
                setTotalRows(response?.data?.total_count);
            } else {
                setReportData([])
            }
        } catch (e) {
            setReportData([])
            console.log(`ERROR WHILE FETCHING PORTFOLIO REPORT DATA ${e}`);
        }
    }

    // ADD ID IN THE ALL ROWS 
    useEffect(() => {
        // Ensure rows is always an array
        const rowsWithId = Array.isArray(reportData)
            ? reportData.map((row, index) => ({ id: index + 1, ...row }))
            : null;
        setData(rowsWithId)
    }, [reportData])

    // FETCG INNER ORDER
    const fetchInnerorder = async (_order) => {
        try {
            console.log('heyy');

            setShowInnerOrders(true);
            setCurrentOrder(_order);
            // const response = await http.get(`/orders/private/inner/${_order?.id}`);
            // if (response?.status == 200) {
            //     setExchangeOrders(response?.data);
            // }
        } catch (e) {
            console.log("ERROR WHILE FETCHIN INNER ORDER" + e);
        }
    };

    return (
        <>
            <div className="w-full flex-1 flex flex-col gap-4  p-4">
                <div style={{ background: theme.palette.background.white }} className="w-full flex-1 p-4 flex flex-col gap-6  border border-[#9E9E9E24]">
                    <div className="flex items-center gap-2 lg:gap-5">
                        <div className="flex items-center gap-3 lg:gap-4">
                            <ArrowBackIcon
                                sx={{ cursor: "pointer" }}
                                onClick={goBack}
                            />
                            <div className="flex items-center gap-1">
                                <H4
                                    title={'Portfolio Report'}
                                    color={theme.palette.text.charcolGray}
                                    font="bold"
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-3 lg:gap-2">
                            {viewOptions && viewOptions.map((item, index) => (
                                <Button
                                    key={index}
                                    variant={currentView == item.value ? 'contained' : 'outlined'}
                                    size="small"
                                    onClick={() => setCurrentView(item.value)}
                                >
                                    {item.key}
                                </Button>
                            ))}
                        </div>
                    </div>
                    <div className="w-full flex-1 flex-col flex">
                        <CompanyTable
                            columns={columns}
                            rows={data}
                            isPagination={true}
                            setLimit={setLimit}
                            limit={limit}
                            skip={skip}
                            setSkip={setSkip}
                            totalRows={totalRows}
                            onRowClickFunction={(params) => {
                                // console.log('heyyyyy');
                                // console.log(params, 'row');
                                navigate(`/equity/portfolio/detailed-report/${params.row.id}`)
                                // fetchInnerorder(params.row);
                            }}
                        />
                    </div>
                    <EquityOrderDetails
                        open={showInnerOrders}
                        orders={innerOrders || []}
                        masterOrder={currentOrder}
                        onClose={() => setShowInnerOrders(false)}
                    />
                </div>
            </div >
        </>
    )
}

export default PortfolioReport;