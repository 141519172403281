import * as React from "react";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { Skeleton, Tooltip } from "@mui/material";
import { PositionIcon } from "./Icons";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useDispatch, useSelector } from "react-redux";
import { http } from "../Services/api.services";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import moment from "moment/moment";

const todayDate = moment().format("YYYY-MM-DD");
const showIndiaTime = (time) => {
  return new Date(time).getTime() + 19800000;
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 14,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#2A3B43",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    // backgroundColor:
    //     theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: "border-box",
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 100,
    },
  },
};

const indexes = [
  { type: "nifty", title: "NIFTY" },
  { type: "banknifty", title: "BANK N" },
  { type: "finnifty", title: "FIN N" },
  { type: "midcpnifty", title: "MIDCP N" },
  { type: "sensex", title: "SENSEX" },
  { type: "bankex", title: "BANKEX" },
];

const Header = ({
  expiry,
  equillibrium,
  isScrolling,
  setIsScrolling,
  activeSL,
  allCurrentExpiry,
  onUpdateStatus,
  rankings,
  RANK_OPTIONS,
  onRankSelect,
  onIndexChange,
  onFormChange,
  currentIndex,
  onExpiryChange,
  market,
  currentExpiry,
}) => {
  const { admin } = useSelector((state) => state.admin);
  const theme = useTheme();
  const [alignment, setAlignment] = React.useState("web");
  const { positionType } = useSelector((state) => state.orders);

  // console.log(rankings)

  // FIND CURRENT SELECTED INDEX
  const findIndexByType = (type) => {
    return indexes.findIndex((item) => item.type === type);
  };

  const selectedIndex = findIndexByType(currentIndex);

  const updateCurrentIndex = (data, expData) => {
    // console.log(expiry[data][0], "its erpc");

    onIndexChange(data);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Create a ref to access the Switch component
  const switchRef = React.useRef();

  // Function to handle div click and trigger switch click
  const handleDivClick = () => {
    // Check if the Switch component reference exists
    if (switchRef.current) {
      // Programmatically trigger a click on the Switch
      switchRef.current.click();
      onUpdateStatus();
    }
  };

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center gap-5 lg:flex-row min-h-[64px] relative my-2">
        <div className="lg:absolute lg:left-0 flex items-center justify-center gap-2 w-full lg:w-fit">
          <Tabs
            sx={{
              ".css-1yeb3ou-MuiButtonBase-root-MuiTab-root": {
                padding: "4px 0px",
                borderRight: "1px solid #00000021",
                "&:hover": {
                  backgroundColor: "#eeeeee",
                },
                minWidth: "80px",
              },
              "& .css-kxhebr-MuiTabs-indicator": {
                display: "none",
              },
              "& .Mui-selected": {
                backgroundColor: "#1C1C1C",
                color: "#FFFFFF !important",
                "&:hover": {
                  backgroundColor: "#1C1C1C !important",
                },
              },
              "& .css-wy1qx7": {
                padding: "4px 0px",
                minWidth: "80px",
                textTransform: "capitalize",
                borderRight: "1px solid #00000021",
                "& .css-6w7hx6-MuiTabs-root": {
                  padding: "4px 0px",
                },
                "&:hover": {
                  backgroundColor: "#eeeeee",
                },
              },
              "& .css-p5x7jj": {
                display: "none",
              },
              background: "#fff",
              border: "1px solid #00000021",
              width: "fit-content",
              height: "fit-content",
              minHeight: "20px",
              // maxWidth: "100px",
              padding: 0,
              borderRadius: 0,
              padding: 0,
            }}
            size="small"
            value={selectedIndex}
            // onChange={handleChange}
            variant="scrollable"
            aria-label="basic tabs example"
          >
            {/* {console.log(market)} */}
            {indexes?.map((_index, index) => (
              <Tab
                key={index}
                onClick={() => updateCurrentIndex(_index.type)}
                label={
                  <div>
                    <p
                      className={`text-xs ${allCurrentExpiry[_index.type] === todayDate
                        ? "text-red-600"
                        : ""
                        }`}
                    >
                      {_index.title}
                    </p>
                    <p className="text-xs text-gray-400">
                      {market[_index?.type]?.strikePrice?.toLocaleString(
                        undefined,
                        { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                      )}
                    </p>
                    {market[_index?.type]?.change ? (
                      market[_index?.type]?.change ? (
                        market[_index?.type]?.change >
                          market[_index?.type]?.strikePrice ? (
                          <p className="text-[10px] text-red-600">
                            {(
                              market[_index?.type]?.change -
                              market[_index?.type]?.strikePrice
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                            &nbsp; (
                            {Math.abs(
                              market[_index?.type]?.changePercent
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            %)
                          </p>
                        ) : (
                          <p className="text-[10px] text-green-600">
                            {(
                              market[_index?.type]?.strikePrice -
                              market[_index?.type]?.change
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                            &nbsp; (
                            {Math.abs(
                              market[_index?.type]?.changePercent
                            )?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            %)
                          </p>
                        )
                      ) : (
                        <p className="text-[10px] text-green-600">
                          0.00 (0.00%)
                        </p>
                      )
                    ) : (
                      <p className="text-[10px] text-green-600">0.00 (0.00%)</p>
                    )}
                  </div>
                }
                {...a11yProps(0)}
              />
            ))}
          </Tabs>
        </div>
        {admin ? (
          <div className="lg:absolute lg:right-0 flex items-center justify-center gap-2 w-full lg:w-fit cursor-pointer">
            <Tooltip title="Default SL&T">
              <div
                onClick={handleDivClick}
                className="flex items-center gap-2 border border-[#DFDFDF] rounded p-2"
              >
                <p
                  style={{ color: activeSL != "ltp" ? "#373737" : "#3737376E" }}
                  className=" text-sm font-semibold "
                >
                  Index
                </p>
                <AntSwitch ref={switchRef} checked={activeSL === "ltp"} />
                {/* <AntSwitch  ref={switchRef} onClick={() => onUpdateStatus()} checked={activeSL === 'ltp'} /> */}
                <p
                  style={{
                    color: activeSL === "ltp" ? "#373737" : "#3737376E",
                  }}
                  className="text-sm font-semibold"
                >
                  LTP
                </p>
              </div>
            </Tooltip>
            <>
              <div className="w-[150px]">
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Ranking
                  </InputLabel>
                  {/* {console.log(currentIndex)} */}
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={rankings.ranking[currentIndex]}
                    onChange={(e) => onRankSelect(e?.target?.value)}
                    input={<OutlinedInput label="Ranking" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {RANK_OPTIONS.map((rank) => (
                      <MenuItem key={rank?.key} value={rank?.value}>
                        <Checkbox
                          checked={
                            rankings.ranking[currentIndex || "nifty"]?.indexOf(
                              rank?.value
                            ) > -1
                          }
                        />
                        <ListItemText primary={rank?.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="w-[150px]">
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Expiry</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentExpiry}
                    // size='small'
                    label="Expiry"
                    // MenuProps={MenuProps}
                    onChange={(e) => onExpiryChange(e?.target?.value)}
                  >
                    {expiry?.map((exp, idx) => (
                      <MenuItem key={idx} value={exp}>
                        {moment(showIndiaTime(exp)).format("DD-MM-YYYY")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </>
          </div>
        ) : null}
        {/* </div> */}
        {/* <div className='lg:absolute'>
                    <div className='shrink-0'>
                        <h6 className='text-[#817E7E] font text-[14px] m-0 text-center'>
                            <b className='text-[#5367FF]'>{currentIndex?.toUpperCase()}</b>
                            &nbsp;
                            {moment(currentExpiry).format('DD MMM')}
                        </h6>
                        <div className='flex items-center gap-2'>
                            <div className={`w-28 flex ${equillibrium ? 'justify-end pr-2' : 'justify-center'}`}>
                                {market[currentIndex]?.strikePrice ?
                                    <h6 className='text-[#373737] font-semibold m-0 text-[14px] text-center'>
                                        {market[currentIndex]?.strikePrice?.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                    </h6> :
                                    <Skeleton height={16} width={100} />
                                }
                            </div>
                            {equillibrium ?
                                <>
                                    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.20)' }} className='h-[20px] w-[1px] rounded-sm'></div>
                                    <div className='w-28'>
                                        <p className='text-[#00A880C7] text-[12px] text-center'>
                                            Equilibrium Price
                                        </p>
                                        <p className='text-[#bdbdbd] text-[10px] text-center'>Coming Soon</p>
                                    </div>
                                </> : null
                            }
                        </div>
                    </div>
                </div> */}
        <div className="w-full 50 px-3  flex items-end justify-end lg:hidden">
          <div onClick={onFormChange} className="w-fit ">
            <PositionIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
