import React, { useEffect, useState } from "react";

const EventSourceComponent = () => {
  const [message, setMessage] = useState("");
  const [message1, setMessage1] = useState("");

  useEffect(() => {
    const eventSource = new EventSource(
      `http://192.168.1.16:8000/api/ds/index/SLC/${"nifty"}/${"minute"}`
    );
    const eventSource1 = new EventSource(
      "http://192.168.1.16:8000/stream/bank/3"
    );

    eventSource.addEventListener("nifty", (data) => {
      console.log("MESSAGE FROM SSE -- ", data);
      setMessage(data.data);
    });

    eventSource1.addEventListener("nifty", (data) => {
      console.log("MESSAGE FROM SSE -- ", data);
      setMessage1(data.data);
    });
    eventSource.onMessage = (event) => {
      console.log("MESSAGE FROM SSE -- ", event);
      setMessage(event.data); // Update state with the received message data
    };

    eventSource.onOpen = (event) => {
      console.log("OPEN SSE -- ", event);
    };

    eventSource.onError = (event) => {
      console.log("ERROR SSE -- ", event);
      if (eventSource.readyState === EventSource.CLOSED) {
        console.log("EventSource connection closed.");
      }
    };

    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <div>
      <h2>Server-Sent Events Example</h2>
      <p>Last Message: {JSON.stringify(message)}</p>
      <p> 2 Last Message: {JSON.stringify(message1)}</p>
    </div>
  );
};

export default EventSourceComponent;
