import React from "react";
import { BasketPositionCard, HistoryLegsCard, LegsCard } from "./BasketCard";
import { http } from "../../Services/api.services";
import { useDispatch, useSelector } from "react-redux";
import BasketForm from "./BasketForm";
import { setBasketPositions, updateAllBasketsLegs, updateAllCurrentBasketFormFileds, updateBasketPositions, updateCurrentBasket, updateCurrentPosition, updateModifyFormData, } from "../../store/Reducer/BasketReducer";
import CloseIcon from "@mui/icons-material/Close";
import { useMarketContext } from "../../Contexts/MarketContext/MarketContext";
import { showToast } from "../../store/Actions/Action";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { BasketSkelatons, OpenOrderSkeletons } from "./BasketSkelatons";
import AddOrderForm from "./AddOrderForm";
import IconButton from "@mui/material/IconButton";
import { useWebSocket } from "../../Contexts/WebsocketContext/WebSocketContext";
import moment from "moment";
import { current } from "@reduxjs/toolkit";

const positionsInstruments = {};
const index = 0;

const BasketLegs = ({ currentSelectedType }) => {
	const dispatch = useDispatch();
	const { admin } = useSelector((state) => state.admin);
	const user = useSelector((state) => state.user);
	const id = admin ? admin?.id : user?.id;
	const [orders, setOrders] = React.useState(null);
	const [historyOrders, setHistoryOrders] = React.useState(null);
	const [openOrders, setOpenOrders] = React.useState(null);
	const [openModifyForm, setOpenModifyForm] = React.useState(false);
	const [openOrderModifyForm, setOpenOrderModifyForm] = React.useState(false);
	const [orderModifyStatus, setOrderModifyStatus] = React.useState("");
	const { allLegs, currentBasket, basketStatus, currentPosition, basketPositions, currentHistory, basketHistory, modifyFormData, } = useSelector((state) => state.Baskets);


	const [executionType, setExecutionType] = React.useState('')
	const [pendingIndex, setPendingIndex] = React.useState(null)

	//   const market = useMarketContext();
	const contextMarket = useMarketContext();
	const market = useSelector((state) => state.market);
	const memoizedMarket = React.useMemo(() => market, [market]);

	const _currentPosition = { ...currentPosition }

	React.useEffect(() => {
		console.log(basketPositions)
		basketPositions?.forEach((position) => {
			if (position?.id == currentPosition?.id) {
				if (position?.quantity == 0) {
					setOpenModifyForm(false);
				}
			}
			// market.basketPositions[position?.instrument_token] = position
		});
	}, [basketPositions]);

	React.useEffect(() => {
		setOpenModifyForm(false)
	}, [currentPosition])

	// GET CURRENT POSITION LEGS
	React.useEffect(() => {
		// console.log(basketPositions)
		const orders = currentPosition?.orders ? currentPosition.orders : [];
		const openOrders = currentPosition?.open_orders ? currentPosition.open_orders : [];
		const pendings = currentPosition?.pendings ? currentPosition.pendings : [];
		setOrders(orders);
		console.log(openOrders)
		setOpenOrders([...openOrders, ...pendings]);
	}, [currentPosition, basketPositions]);

	// GET CURRENT HISTORY LEGS
	React.useEffect(() => {
		if (currentHistory) {
			setHistoryOrders(currentHistory?.orders || []);
		}
	}, [currentHistory, basketHistory]);

	// CALCULATE POSITIONS CURRENT LTP FUNCTION
	const returnLTP = (instrument) => {
		// console.log(instrument, market.basketPositions)
		try {
			console.log(market.basketPositions[instrument], ' -- instrument')
			return market.basketPositions && market.basketPositions?.[instrument] ? market.basketPositions?.[instrument]?.last_price : 0;
		} catch (e) {
			console.log(e);
		}
	};

	// ADD INDIVIDUAL ORDER
	const onAdd = async (pos, actionType) => {
		setOpenOrderModifyForm(false);
		setExecutionType(actionType)
		const instrument = pos?.instrument_token;
		if (memoizedMarket && memoizedMarket?.instruments && memoizedMarket[memoizedMarket?.instruments[`'${instrument}'`]?.index]) {
			const _currentOrder = memoizedMarket[memoizedMarket?.instruments[`'${instrument}'`]?.index][memoizedMarket?.instruments[`'${instrument}'`]?.expiry]?.options[memoizedMarket?.instruments[`'${instrument}'`]?.type][`'${instrument}'`];
			const _data = {
				index: _currentOrder?.name,
				expiry: _currentOrder?.expiry,
				strike: _currentOrder?.strike,
				quantity: pos?.total_quantity,
				price: pos.limit ? pos.limit : 0,
				instrument_type: _currentOrder?.instrument_type,
				transaction_type: actionType == 'exit' ? pos?.last_transaction_type == 'SELL' ? 'BUY' : 'SELL' : pos?.last_transaction_type,
				stoploss: currentPosition?.stoploss,
				tradingsymbol: pos?.tradingsymbol,
				instrument_token: pos?.instrument_token,
			};
			// if (actionType == "exit") {
			// 	let txnType = pos?.last_transaction_type;
			// 	_data.transaction_type = txnType;
			// }
			dispatch(updateModifyFormData(_data));
			setOpenModifyForm(true);
			setOrderModifyStatus(actionType);
		}
	};

	const exitIndividualOrder = async (order) => {
		try {
			// currentPosition IS BASKET
			const _order = currentPosition?.orders.find(o => o.tradingsymbol == order.tradingsymbol)
			const basketQuantity = orders.map(o => parseInt(o.total_quantity)).reduce((x, y) => Math.abs(x) + Math.abs(y), 0)
			// console.log(basketQuantity)
			setExecutionType('exit')
			const orderBody = {
				"exchange": _order.exchange,
				"index": _order.index,
				"instrument_token": _order.instrument_token,
				"price": order.price || 0,
				"order_type": parseFloat(order.price) > 0 ? "LIMIT" : "MARKET",
				"product": "NRML",
				"quantity": order.transaction_type?.toLowerCase() == 'buy' ? Math.abs(parseInt(order.quantity)) : -Math.abs(parseInt(order.quantity)),
				"tradingsymbol": _order.tradingsymbol,
				"transaction_type": order.transaction_type,
				"expiry": _order.expiry,
				"validity": "DAY",
			}
			const body = {
				buy: [],
				sell: [],
				basket_id: currentPosition.id,
				stoploss: order?.stoploss,
				user_id: admin?.id,
				type: 'pexit',
				pid: currentPosition?.pid
			}
			if (order.transaction_type?.toLowerCase() == 'buy') {
				body.buy.push(orderBody)
			}
			else body.sell.push(orderBody)
			const currQty = Math.abs(orderBody.quantity)
			// console.log(basketQuantity, currQty)
			if (parseInt(basketQuantity) == currQty) {
				//LAST LEG OF THE BASKET - UPDATE type TO exit INSTEAD OF pexit
				body.open_orders = currentPosition?.open_orders
				body.type = 'exit'
			}
			const response = await http.post(`orders/basket/executeorders`, body)
			if (response?.status == 200) {
				// getAllPositions();
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
				setOpenModifyForm(false);
				setOrderModifyStatus("");
			}
		} catch (e) {
			console.log(`ERROR WHILE EXITING INDIVIDUAL ORDER ${e}`);
			const _snackbar = {
				message: e?.response?.data?.error || e.message,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
		}
	};

	// EXIT INDIVIDUAL ORDER
	const _exitIndividualOrder = async (order) => {
		try {
			// FORMER EXIT FUNCTION - USED TO GET CALLED IN PREVIOUS IMPLEMENTATION
			const instrument = order?.instrument_token;
			const _index = memoizedMarket?.instruments[`'${instrument}'`]?.index;
			const _expiry = memoizedMarket?.instruments[`'${instrument}'`]?.expiry;
			const _insType = memoizedMarket?.instruments[`'${instrument}'`]?.type;
			if (memoizedMarket && memoizedMarket?.instruments) {
				const _currentOrder = memoizedMarket[_index][_expiry]?.options[_insType][`'${instrument}'`];
				const _body = {
					tradingsymbol: _currentOrder?.tradingsymbol,
					price: order?.limit ? order?.limit : null,
					quantity: order?.quantity,
				};

				const response = await http.post(`orders/basket/${currentPosition?.id}/order/exit`, _body);
				if (response?.status == 200) {
					// getAllPositions();
					const _snackbar = {
						message: response?.data?.message,
						open: true,
						duration: 3000,
						type: "success",
					};
					dispatch(showToast(_snackbar));
					setOpenModifyForm(false);
					setOrderModifyStatus("");
				}
			}
		} catch (e) {
			console.log(`ERROR WHILE EXITING INDIVIDUAL ORDER ${e}`);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
		}
	};

	// GET ALL POSITIONS
	const getAllPositions = async () => {
		try {
			const response = await http.get(`orders/${id}/basket/position`);
			if (response?.status == 200) {
				dispatch(setBasketPositions(response?.data?.data));
				contextMarket.updateBasketPositionsWS({
					updatePositions: true,
					basketPositions: await contextMarket.setMarketBasketPositions(response.data.data),
				});
			}
		} catch (e) {
			console.log(`ERROR WHILE FETCHING POSITIONS ${e}`);
		}
	};

	// COLLECT MODIFY OPEN  ORDER DATA
	const ModifyOrder = async (order, index) => {
		const op = currentPosition?.open_orders.length
		// console.log(op, index)
		if (order.order_id) setExecutionType('modify')
		else {
			setPendingIndex((op - index))
			setExecutionType('pmodify')
		}
		setOpenModifyForm(false);
		const instrument = order;
		const response = await http.post("ds/search-symbol", { symbol: order?.tradingsymbol });
		const _body = {
			index: response?.data[0]?.name,
			expiry: response?.data[0]?.expiry,
			strike: response?.data[0]?.strike,
			instrument_type: response?.data[0]?.instrument_type,
			transaction_type: order?.transaction_type,
			quantity: order?.quantity,
			price: parseInt(order.limit ? order.limit : 0, 10),
			stoploss: currentPosition?.stoploss,
			order_id: order?.order_id,
			tradingsymbol: order?.tradingsymbol,
		};


		dispatch(updateModifyFormData(_body));
		setOpenOrderModifyForm(true);
	};

	// CANCEL OPEN ORDERS
	const cancelOpenOrder = async (id, order, index) => {
		try {
			console.log(order, modifyFormData)
			if (order?.tradingsymbol == modifyFormData?.tradingsymbol) {
				setOpenOrderModifyForm(false)
			}
			const op = currentPosition?.open_orders.length
			if (order.order_id) setExecutionType('cancel')
			else {
				setExecutionType('pcancel')
				setPendingIndex(op - index)
			}
			// console.log('CANCEL ORDERS', id, order)
			const orders = currentPosition?.[order.order_id ? 'open_orders' : 'pendings']
			const basketQuantity = orders.map(o => parseInt(o.total_quantity)).reduce((x, y) => x + y, 0)
			const body = {
				buy: [],
				sell: [],
				basket_id: currentPosition.id,
				stoploss: currentPosition?.stoploss,
				user_id: admin?.id,
				type: 'cancel',
				pid: currentPosition?.pid
			}
			if (order.order_id) {
				body.open_orders = [order]
			}
			else {
				const filtered = orders.filter(o => o.id == order.id)
				const _pindex = filtered.findIndex(o => o.tradingsymbol == order.tradingsymbol)
				// console.log(filtered, _pindex)
				filtered.splice(_pindex, 1)
				body[order.transaction_type?.toLowerCase()] = filtered
				body.bh_id = order.id
				body.type = 'pcancel'
			}

			// console.log(body)
			const response = await http.post(`orders/basket/executeorders`, body)
			// setExecutionType('')
			if (response?.status == 200) {
				// console.log(order)
				if (order.id) {
					// UPDATE PENDING
					const _pendings = currentPosition?.pendings ? JSON.parse(JSON.stringify(currentPosition?.pendings)) : []
					_pendings.splice(pendingIndex, 1)
					dispatch(updateCurrentPosition({ ...currentPosition, pendings: _pendings }));
				}
				else {
					// UPDATE OPEN ORDERS
					const open = currentPosition?.open_orders ? JSON.parse(JSON.stringify(currentPosition?.open_orders)) : []
					// // console.log(index)
					// console.log(open, currentPosition, index)
					open.splice(index, 1)
					// const orders = currentPosition?.orders ? currentPosition.orders : [];
					const pendings = currentPosition?.pendings ? currentPosition.pendings : [];
					dispatch(updateCurrentPosition({ ...currentPosition, open_orders: open }));
					setOpenOrders([...open, ...pendings])
					console.log(open, pendings, openOrders)
				}
				// getAllPositions();
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));

			}
		} catch (e) {
			console.log(`ERROR WHILE CANCEL ORDER ${e}`, e);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
		}
	};

	//FORMER - CANCEL OPEN ORDER
	const _cancelOpenOrder = async (id, orderId) => {
		try {
			const response = await http.post(`orders/${id}/basket/order/${orderId}/cancel`);
			if (response?.status == 200) {
				// getAllPositions();
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "error",
				};
				dispatch(showToast(_snackbar));
			}
		} catch (e) {
			console.log(`ERROR WHILE CANCEL ORDER ${e}`);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
		}
	};

	const returnExecutionType = (type) => {
		switch (type) {
			case 'exit': return 'Basket Exit';
			case 'entry': return 'Basket Entry';
			case 'pentry': return 'Partial Entry';
			case 'pexit': return 'Partial Exit';
			case 'modify': return 'Modified';
			default: return 'Cancelled';
		}
	}

	const returnExecutionTypeColor = (type) => {
		switch (type) {
			case 'exit': return 'text-red-700';
			case 'entry': return 'text-green-700';
			case 'pentry': return 'text-green-400';
			case 'pexit': return 'text-red-400';
			case 'modify': return 'text-blue-700';
			default: return 'text-slate-400';
		}
	}

	return (
		<>
			<div className="w-full  relative h-full px-0">
				{currentBasket && basketStatus !== "notSelected" ? (
					<BasketForm />
				) : currentPosition && basketStatus !== "notSelected" ? (
					<>
						<div className="w-full flex-1 overflow-y-auto h-full flex flex-col items-start gap-4 p-4">
							<div className="w-full flex gap-1 items-center ">
								<h6 className="text-[#000000] text-sm lg:text-lg font-bold text-ellipsis w-fit max-w-3/4 overflow-hidden">
									{currentPosition?.name}
								</h6>
								<span className="text-[#A4A4A4] text-sm font-medium">
									{currentPosition?.orders !== undefined ?
										`${currentPosition?.orders?.length} Leg` :
										null
									}
								</span>
							</div>

							{/* OPEN ORDERS  */}
							{openOrders ? (
								openOrders?.length > 0 ? (
									<div className="w-full py-6 border-b border-dashed border-b-[#5367FF80]">
										{openOrders?.map((order, index) => {
											return (
												<div key={index} className={`w-full flex items-center justify-between ${index != openOrders?.length - 1 ? "border-b" : ""} border-b-[#eee]  rounded py-2`}>
													<div className="flex items-center gap-3">
														<div className={`flex items-center gap-3 ${order?.tradingsymbol?.includes("PE") ? "bg-[#FFF4F4]" : " bg-[#F1FBF6]"} border ${order?.tradingsymbol?.includes("PE") ? "border-[#D4151585]" : "border-[#00B38652]"} `}>
															<p className={`text-sm tracking-wide font-semibold cursor-pointer ${order.tradingsymbol?.includes("PE") ? "text-[#D41515]" : "text-[#00B386]"}  p-2`}>
																{order?.tradingsymbol}
															</p>
														</div>
														<div className="flex items-center gap-2">
															<p className="text-green-600 text-xs lg:text-xs font-bold">
																{order?.transaction_type}
															</p>
															<p className="text-[#000000] text-xs lg:text-xs font-bold">
																{order?.quantity}
															</p>
														</div>
													</div>
													<div className="flex items-center gap-3">
														<h6 className="text-[#000000] font-semibold text-sm lg:text-xs">
															{order?.limit ? `${order?.limit?.toFixed(2)}` : ''}
														</h6>
														<IconButton onClick={() => ModifyOrder(order, index)} aria-label="delete">
															<BorderColorIcon sx={{ color: "#989898", fontSize: "16px" }} />
														</IconButton>
														<IconButton onClick={() => cancelOpenOrder(id, order, index)} aria-label="delete">
															<CloseIcon sx={{ color: "#989898", fontSize: "16px" }} />
														</IconButton>
													</div>
												</div>
											);
										})}
									</div>
								) : null
							) : (
								<OpenOrderSkeletons />
							)}

							{openOrderModifyForm && (
								<AddOrderForm
									open={openModifyForm}
									closeForm={() => {
										setOpenOrderModifyForm(false);
										getAllPositions();
									}}
									executionType={executionType}
									isModifying={true}
									pendingIndex={pendingIndex}
								/>
							)}

							{/* LEGS ORDER  */}
							{orders?.length > 0 ? (
								orders?.map((order, idx) => (
									<div key={idx} className="w-full flex flex-col items-start gap-2" >
										{/* {console.log('BASKET LEG -- ', order)} */}
										<LegsCard
											leg={order}
											market={memoizedMarket}
											returnLTP={(instrument) => returnLTP(instrument)}
											onAdd={(pos) => onAdd(pos, "add")}
											// onExitOrder={(order) => exitIndividualOrder(order)}
											onExitOrder={(pos) => onAdd(pos, "exit")}
										/>
										{openModifyForm && modifyFormData?.tradingsymbol == order?.tradingsymbol ? (
											<AddOrderForm
												open={openModifyForm}
												closeForm={() => {
													setOpenModifyForm(false);
													setOrderModifyStatus("");
													getAllPositions();
												}}
												executionType={executionType}
												isModifying={false}
												orderModifyStatus={orderModifyStatus}
												onExitOrder={(order) => exitIndividualOrder(order)}
											/>
										) : null}
									</div>
								))
							) : (
								<div className="w-full h-full flex justify-center items-center">
									<>
										<p className="text-center">NO LEGS FOUND!</p>
									</>
								</div>
							)}
						</div>
					</>
				) : basketStatus == "creating" ? (
					<BasketForm />
				) : currentHistory && basketStatus !== "notSelected" ? (
					<>
						<div className="w-full flex-1 overflow-y-auto h-full flex flex-col items-start gap-4 p-4">
							<div className="w-full flex gap-1 items-center">
								<h6 className="text-[#000000] text-sm lg:text-lg font-bold whitespace-nowrap w-fit max-w-3/4 overflow-hidden text-ellipsis">
									{currentHistory?.name}
								</h6>
								<span className="text-[#A4A4A4] whitespace-nowrap text-sm font-medium">
									{/* {currentHistory?.orders !== undefined ? `${JSON.parse(currentHistory?.orders)?.length} Leg` : null} */}
								</span>
							</div>

							{/* LEGS ORDER  */}
							{historyOrders?.length > 0 ? (
								historyOrders.map((order, idx) => (
									<div key={idx} className="w-full">
										<div className="px-2 py-1 w-full rounded-sm border-b border-gray-300 flex justify-between items-center">
											<p className={`${returnExecutionTypeColor(order.status)} text-md font-extrabold uppercase`}>
												{returnExecutionType(order.status)}
											</p>
											<p className="text-[#8e8e8e] text-md font-semibold">{moment(order.createdatist).format('hh:mm a')}</p>
										</div>
										<div className="flex flex-col">
											{order.orders ? order.orders.map((o, i) => (
												<HistoryLegsCard key={i} leg={o} />
											)) : null}
										</div>
									</div>
								))
							) : (
								<div className="w-full h-full flex justify-center items-center">
									<>
										<p className="text-center">NO LEGS FOUND!</p>
									</>
								</div>
							)}
						</div>
					</>
				) : (
					<div className="w-full h-full flex items-center justify-center">
						<div className="flex px-3 flex-col items-center justify-center gap-4">
							<h6 className="text-[#000000] text-sm lg:text-2xl font-bold">
								Basket Legs
							</h6>
							<p className="text-[#666666] text-sm lg:text-sm font-medium text-center">
								All the legs of the basket will be displayed here when you save
								as draft and execute.
							</p>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default BasketLegs;
