import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from "@mui/material";
import { Provider } from 'react-redux';
import { store } from './store/Store';
import { adminStore } from './store/AdminStore';
import { DimensionsProvider } from './Contexts/DimensionContext/DimensionsContext';
import PublicRoutes from './Routes/PublicRoutes';
import PrivateRoutes from './Routes/PrivateRoutes';
import { CustomThemeProvider } from './Theme/ThemeContext';
import { material } from './Theme/Theme';

function App() {
  return (
    <DimensionsProvider>
      <ThemeProvider theme={material}>
        <CustomThemeProvider>
          <Provider store={store}>
            <BrowserRouter>
              <PublicRoutes />
            </BrowserRouter>
          </Provider>
        </CustomThemeProvider>
        <Provider store={adminStore}>
          <BrowserRouter>
            <PrivateRoutes />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </DimensionsProvider>
  );
}

export default App; 