import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import Skeleton from '@mui/material/Skeleton'; // MUI Skeleton component for placeholder

// Define all months from Feb to Dec
const allMonths = [
  "Feb 2024", "Mar 2024", "Apr 2024", "May 2024", "Jun 2024", "Jul 2024", "Aug 2024",
  "Sep 2024", "Oct 2024", "Nov 2024", "Dec 2024"
];

// Function to process the API data and fill in missing months
const processData = (apiData) => {
  // Create a map from the API response for quick lookups
  const apiDataMap = new Map(apiData.map(item => [item[0], item]));

  // Generate the full dataset
  return allMonths.map(month => {
    // Check if the month is in the API data
    if (apiDataMap.has(month)) {
      return apiDataMap.get(month); // Use the data from the API
    } else {
      return [month, 0, "green"]; // Default value 0, with a green bar
    }
  });
};

export const BarCharts = ({ data }) => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // console.log(data, 'data ---');


  useEffect(() => {
    if (data) {
      // Process the API data whenever new props are received
      const formattedData = processData(data);
      setChartData(formattedData);
      setIsLoading(false); // Data is loaded, so stop loading
    } else {
      setIsLoading(true);
    }
  }, [data]);

  const options = {
    hAxis: {
      gridlines: {
        color: "transparent",
      },
    },
    vAxis: {
      gridlines: {
        color: "transparent",
      },
      format: "decimal",
    },
    backgroundColor: "transparent",
    legend: { position: "none" },
    bar: { groupWidth: "75%" },
  };

  const finalChartData = [
    ["Month", "Value", { role: "style" }],
    ...chartData
  ];

  return (
    <>
      {isLoading ? (
        // Display a skeleton loader when chart data is still loading
        <Skeleton variant="rectangular" width="100%" height={400} />
      ) : (
        // Render the actual chart when data is available
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={finalChartData}
          options={options}
        />
      )}
    </>
  );
};
