import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	asyngetCurrentIndex,
	setOpenOrders,
	setPositions,
	showToast,
	updateAdminAciveSL,
} from "../store/Actions/Action";
import OptionHeader from "./OptionHeader";
import OptionTable from "./OptionTable";
import MobileTable from "./MobileTable";
import Position from "./Position";
import AddBroker from "../Public/Options/AddBroker";
import { http } from "../Services/api.services";
import MobilePositions from "./MobilePositions";
import { Alert, IconButton, Snackbar, Tab, Tabs } from "@mui/material";
import { updatePositionProfitLoss, } from "../Services/update.service";
import OpenOrders from "./OpenOrders";
import RefreshIcon from "@mui/icons-material/Refresh";
import { expiryReducer } from "../store/Reducer/ExpiryReducer";
import { rankingReducer } from "../store/Reducer/RankingReducer";
import moment from "moment";
import BottomSheet from "./BottomSheet";
import { slReducer } from "../store/Reducer/SLReducer";
import { updateActiveSL } from "../store/Reducer/Reducer";
import BasketForm from "../Private/Market/BasketForm";
import { useMarketContext } from "../Contexts/MarketContext/MarketContext";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Menu from "@mui/material/Menu";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { setPositionType } from "../store/Reducer/OrderReducer";
import Badge from "@mui/material/Badge";
import { setMarketCurrentIndex } from "../store/Reducer/MarketReducer";



const Strikes = () => {

	const SL = useSelector((state) => state.SL);
	// console.log(___market, '___market___market');
	const marketContext = useMarketContext();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const market = useSelector((state) => state.market)
	// console.log(market, 'strikes market ---');

	const memoizedMarket = React.useMemo(() => market, [market]);
	const { currentExpiry, activeSL } = useSelector(
		(state) => state.optionHome
	);
	const { nifty, finnifty, bankex, banknifty, sensex, midcpnifty, currentIndex } = useSelector((state) => state.market)
	const { positionType } = useSelector((state) => state.orders);
	const allCurrentExpiry = useSelector((state) => state.allCurrentExpiry);
	// console.log(allCurrentExpiry, 'allCurrentExpiryallCurrentExpiry---', currentIndex, 'cuirreber index ---');

	const user = useSelector((state) => state.user);
	const home = useSelector((state) => state.optionHome);
	const features = useSelector((state) => state.features);
	let rankings = useSelector((state) => state.rankings);
	const { ticks } = useSelector((state) => state.ticks);
	const { positions, openOrders } = useSelector((state) => state.orders);

	const [open, setOpen] = React.useState(false);
	const [openBroker, setOpenBroker] = React.useState(false);
	const [openMobilePosition, setOpenMobilePosition] = React.useState(false);
	const [refresh, setRefresh] = React.useState(false);
	const [error, setErrors] = React.useState({
		open: false,
		type: "",
		message: "",
	});
	const [brokers, setBrokers] = React.useState([]);
	const [currentTab, setCurrentTab] = React.useState(0);
	const [placingOrder, setPlacingOrder] = React.useState(false);
	const [isModifying, setIsModifying] = React.useState(null);
	const { admin } = useSelector((state) => state.admin);
	const [isScrolling, setIsScrolling] = React.useState(false);
	const [orderInstrument, setOrderIntrument] = React.useState(0);
	const [isBasketPosition, setIsBasketPosition] = React.useState(false);
	const role = admin ? "admin" : "user";
	const id = admin ? admin?.id : user?.id;

	// console.log(market[currentIndex], 'mdata');


	const updatePositions = async (_positions) => {
		_positions = _positions || positions;
		const pos = await updatePositionProfitLoss(ticks, _positions);
		// console.log('POSTIONS --- ', pos)
		// setPositions(pos)
	};

	// CALL SLDATA FUNCTION
	React.useEffect(() => {
		fetchSLData();
	}, [activeSL]);

	// FETCH  SL DATA
	const fetchSLData = async () => {
		if (admin) {
			const _type = activeSL === "ltp" ? "ltp" : "index";
			const STOPLOSS_TARGET_URL = `/settings/sltp/${role}/${id}`;
			try {
				const response = await http.get(`${STOPLOSS_TARGET_URL}?type=${_type}`);
				if (response?.status == 200) {
					dispatch(slReducer({ type: "updateSL", payload: response.data[0] }));
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		}
	};

	const [formData, setFormData] = React.useState({
		orderType: "",
		txnType: "",
		symbol: "",
		tradingsymbol: "",
		exchange: "",
		instrument: null,
		strike: null,
		currentOption: null,
	});

	// FETCH BROKER DETAILS

	const fecthBrokers = async () => {
		try {
			const response = await http.get(`/brokers`);
			if (response.status == 200) {
				setBrokers(response.data);
			}
		} catch (e) {
			console.log("errow while fetching brokers");
		}
	};

	// CALL POSITION , BROKER AND ORDERS DATA FUNCTION
	React.useEffect(() => {
		if (user && user.id) {
			fetchPositions();
			fetchOpenOrders();
		}
		fecthBrokers();
	}, [user]);

	// FETCH OPEN ORDERS
	const fetchOpenOrders = async () => {
		try {
			if (id) {
				const apiUrl = `/orders/open/${id}${admin ? "?r=a" : ""}`;
				const response = await http.get(apiUrl);
				if (response.status == 200) {
					dispatch(setOpenOrders(response.data));
					// setOpenOrders(response.data)
				}
				// else setOpenOrders([])
			}
		} catch (e) {
			// setOpenOrders([])
			console.log("error while orders ", e);
		}
	};

	// FETCH POSITION
	const fetchPositions = async () => {
		try {
			// setRefresh(true)
			const userId = localStorage.getItem("user_id");
			const targetUserId = admin ? admin.id : user?.id ?? userId;
			const apiUrl = `/orders/positions/${targetUserId}${admin ? "?r=a" : ""}`;
			const response = await http.get(apiUrl);
			if (response.status === 200) {
				// console.log(response.data.data)
				// setPositions(response?.data?.data)
				// setRefresh(false)
				if (response?.data?.data) dispatch(setPositions(response?.data?.data));
				// setErrors({ message: 'Positions Updated!', open: true, type: 'success' })
				// DISABLED DUE TO SOCKET.IO REMOVAL
				// if (ticks && response?.data?.data) {
				// 	updatePositions(response?.data?.data)
				// }
			}
		} catch (e) {
			// setRefresh(false)
			console.log("ERROR WHILE FETCH POSITIONS -- ", e);
		}
	};

	// IBDEX CHANGE EVENT
	const onIndexChangeEvent = (value) => {
		try {
			// console.log(value, 'VALUE ---');

			// market.setMarketCurrentIndex(value);
			// dispatch(setMarketCurrentIndex(value));
			marketContext.setCurrentIndex(value);
			setIsScrolling(false);
			dispatch(asyngetCurrentIndex(value));
			// dispatch(asyngetCurrentExpiry(expiry[value][0]))
		} catch (e) {
			console.log(`ERROR-- - ${e}`);
		}
	};

	const isPartialExit = (qty, sym, typ) => {
		try {

		}
		catch (e) {
			console.log(`ERROR WHILE CHECKING PARTIAL EXIT--- ${e}`);
		}
	}

	const params =
		"action=login&type=login&status=success&request_token=pRTxaBjsn3YOKk2bGwOGXYV0VUKUKZuo";

	// ON form change function
	const onFormChange = (txnType, orderType, strike, data, tradingsymbol) => {
		// console.log(txnType, orderType, strike, data, tradingsymbol)
		if (user?.broker?.length || admin) {
			if (!home?.brokerConnected && !admin) {
				const _snackbar = {
					message: "Broker Not Connected!",
					open: true,
					duration: 10000,
					type: "error",
				};
				dispatch(showToast(_snackbar));
				return;
			}
			try {
				setOpen(!open);
				setFormData({
					orderType,
					txnType,
					strike,
					tradingsymbol,
					exchange: data.exchange,
					instrument: data.instrument_token,
					symbol: data.tradingsymbol,
					currentOption: data,
					currentExpiry: allCurrentExpiry[currentIndex],
				});
				console.log(formData);
			} catch (e) {
				console.log(`ERROR-- - ${e}`);
			}
		} else {
			setOpenBroker(true);
		}
	};

	// ON ADD FUNCTION
	const onAdd = async (pos) => {
		try {
			const instrument = `'${pos.instrumenttoken}'`;
			if (market.instruments && market.instruments[instrument]) {
				const index = market.instruments[`'${pos.instrumenttoken}'`]?.index;
				const type = market.instruments[`'${pos.instrumenttoken}'`]?.type;
				console.log(pos, index, type);
				const strike =
					market[index][allCurrentExpiry[index]]["options"][type][instrument];
				// const strike = await findStrikeInMarket(market, pos?._id)
				// console.log(index, type, market)
				setOpen(!open);
				setIsModifying(null);
				setFormData({
					orderType: strike.instrument_type == "CE" ? "call" : "put",
					txnType: pos?.currentquantity > 0 ? "buy" : "sell",
					// txnType: pos?.lasttxntype == "BUY" ? "buy" : "sell",
					strike: strike.strike,
					tradingsymbol: strike?.tradingsymbol,
					exchange: pos?.exchange,
					instrument: pos?.instrumenttoken,
					symbol: strike.tradingsymbol,
					currentOption: strike,
					quantity: pos?.currentquantity,
					currentIndex: strike.name?.toLowerCase(),
					currentExpiry: allCurrentExpiry[index],
				});
				// console.log(market)
			} else {
				const response = await http.post("ds/search-symbol", {
					symbol: pos?._id,
				});
				if (response.status == 200) {
					if (response.data && response.data.length) {
						const index = response.data[0].name?.toLowerCase();
						const type = response.data[0].instrument_type;
						// const strike = market[index][allCurrentExpiry[currentIndex]]["options"][type][instrument];
						setOpen(!open);
						setIsModifying(null);
						setFormData({
							orderType: type == "CE" ? "call" : "put",
							txnType: pos?.lasttxntype == "BUY" ? "sell" : "buy",
							strike: response.data[0].strike,
							tradingsymbol: response.data[0]?.tradingsymbol,
							exchange: pos?.exchange,
							instrument: pos.instrumenttoken,
							symbol: response.data[0].tradingsymbol,
							currentOption: response.data[0],
							quantity: pos?.currentquantity,
							currentIndex: index,
							currentExpiry: allCurrentExpiry[index],
							notinoc: true,
						});
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	// ON EXIT FUNCTION
	const onExit = async (pos) => {
		const instrument = `'${pos.instrumenttoken}'`;
		if (market.instruments && market.instruments[instrument]) {
			const index = market.instruments[`'${pos.instrumenttoken}'`]?.index;
			const type = market.instruments[`'${pos.instrumenttoken}'`]?.type;
			const strike =
				market[index][allCurrentExpiry[index]]["options"][type][instrument];
			// const strike = await findStrikeInMarket(market, pos?._id)
			// console.log(index, type, market)
			// console.log(pos);
			// console.log(strike);
			setOpen(!open);
			setIsModifying(null);
			setFormData({
				orderType: strike.instrument_type == "CE" ? "call" : "put",
				// txnType: pos?.lasttxntype == "BUY" ? "sell" : "buy",
				txnType: pos?.currentquantity > 0 ? "sell" : "buy",
				strike: strike.strike,
				tradingsymbol: strike?.tradingsymbol,
				exchange: pos?.exchange,
				instrument: pos.instrumenttoken,
				symbol: strike.tradingsymbol,
				currentOption: strike,
				quantity: pos?.currentquantity,
				currentIndex: index,
				currentExpiry: allCurrentExpiry[index],
			});
		} else {
			// THE SYMBOL IS NOT IN OPTION CHAIN - CALL API TO GET THE DETAILS OF THE SYMBOL
			try {
				const response = await http.post("ds/search-symbol", {
					symbol: pos?._id,
				});
				if (response.status == 200) {
					if (response.data && response.data.length) {
						console.log(response.data[0]);
						const index = response.data[0].name?.toLowerCase();
						const type = response.data[0].instrument_type;
						// const strike = market[index][allCurrentExpiry[currentIndex]]["options"][type][instrument];
						setOpen(!open);
						setIsModifying(null);
						setFormData({
							orderType: type == "CE" ? "call" : "put",
							txnType: pos?.lasttxntype == "BUY" ? "sell" : "buy",
							strike: response.data[0].strike,
							tradingsymbol: response.data[0]?.tradingsymbol,
							exchange: pos?.exchange,
							instrument: pos.instrumenttoken,
							symbol: response.data[0].tradingsymbol,
							currentOption: response.data[0],
							quantity: pos?.currentquantity,
							currentIndex: index,
							currentExpiry: allCurrentExpiry[index],
							notinoc: true,
						});
					}
				}
			} catch (e) {
				console.log("ERROR WHILE EXIT ORDER - ", e);
			}
		}
		// const strike = await findStrikeInMarket(market, pos?._id)
		// console.log(market)
	};

	// PLACE ORDER FORM
	const onPlaceOrder = async (body) => {
		try {
			setPlacingOrder(true);
			setOrderIntrument(body?.instrumentToken);
			let url;
			if (admin) {
				url = isModifying
					? "/orders/private/modify-order"
					: "/orders/private/place-order";
			} else {
				url = isModifying ? "/order/modify-order" : "/placeorder";
			}
			if (isModifying) {
				body["orderId"] = admin
					? isModifying?.masterorderid
					: isModifying?.order_id;
			}
			// console.log(body)
			const response = await http.post(url, body);
			// console.log(response);
			setPlacingOrder(false);
			setIsModifying(null);
			setOrderIntrument(0);
			fetchPositions()
			fetchOpenOrders()

			if (response.status == 201) {
				// SUCCESS
				setOpen(false);
				const _success = {
					message: response.data.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_success));
				// setErrors({
				// 	open: true,
				// 	message: response.data.message,
				// 	type: 'success'
				// })
			} else {
				const _error = {
					message: response.data.message,
					open: true,
					duration: 3000,
					type: "error",
				};
				dispatch(showToast(_error));
			}
		} catch (e) {
			setPlacingOrder(false);
			setOrderIntrument(0);
			console.log(e);
			// setIsModifying(null)
			const _error = {
				message: e?.response?.data?.message || "Failed to place ordre",
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_error));
			// setErrors({
			// 	open: true,
			// 	message: e.response.data.message || "Failed to place order",
			// 	type: 'error'
			// })
		}
	};

	// REFRESH TO GET POSTION
	const onRefresh = async () => {
		try {
			const _info = {
				message: `Please wait...`,
				open: true,
				duration: 5000,
				type: "primary",
			};
			dispatch(showToast(_info));
			setRefresh(true);
			await fetchPositions();
			await fetchOpenOrders();
			setRefresh(false);
			const _success = {
				message: `Done`,
				open: true,
				duration: 1000,
				type: "success",
			};
			dispatch(showToast(_success));
		} catch (e) {
			console.log(e);
		}
	};

	// HANDLE TAB CHANGE
	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	// MODIFY ORDER FUNCTION
	const onModify = async (order) => {
		try {
			console.log(order, market.instruments)
			setIsModifying(order);
			const instrument = `'${order.instrument_token}'`;
			if (market.instruments && market.instruments[instrument]) {
				const index = market.instruments[`'${order.instrument_token}'`]?.index;
				const type = market.instruments[`'${order.instrument_token}'`]?.type;
				const strike = market[index][allCurrentExpiry[index]]["options"][type][instrument];
				console.log(index, type, strike)
				setFormData({
					orderType: strike.instrument_type == "CE" ? "call" : "put",
					txnType: order?.transaction_type?.toLowerCase() || order?.lasttxntype?.toLowerCase(),
					strike: strike.strike,
					tradingsymbol: order?.tradingsymbol,
					exchange: order?.exchange,
					instrument: order.instrument_token,
					symbol: strike.tradingsymbol,
					currentOption: strike,
					quantity: order?.quantity,
					currentIndex: strike.name?.toLowerCase(),
					currentExpiry: strike.expiry,
					amount: order?._limit,
					modify: true
				});
				setOpen(!open);
			}
		} catch (e) {
			console.log(`ERROR-- ${e}`);
		}
	};

	// CANCEL ORDER FUNCTION
	const onCancel = async (order) => {
		try {
			console.log(order);
			setPlacingOrder(true);
			setIsModifying(null);
			setOrderIntrument(order?.instrumentToken);
			const url = admin
				? "/orders/private/cancel-order"
				: "/order/cancel-order";
			const orderId = admin ? order?.masterorderid : order?.order_id;
			const userId = admin ? admin?.id : user?.id;
			const response = await http.post(url, {
				userId: userId,
				orderId: orderId,
			});
			setPlacingOrder(false);
			setOrderIntrument(0);
			if (response.status == 200) {
				const _snackbar = {
					message: "Order Cancelled!",
					open: true,
					duration: 5000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
				fetchOpenOrders();
			} else {
				const _snackbar = {
					message: "Failed to cancel order",
					open: true,
					duration: 5000,
					type: "error",
				};
				dispatch(showToast(_snackbar));
			}
		} catch (e) {
			setPlacingOrder(false);
			setOrderIntrument(0);
			const _snackbar = {
				message: "Failed to cancel order",
				open: true,
				duration: 5000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
			console.log(`ERROR-- ${e}`);
		}
	};

	// ON EXPIRY CHANGE FUNCTION
	const onExpiryChange = async (expiry) => {
		try {
			// const response = await http.get(`${ENDPOINTS.OPTIONDATA}/${id}?expiry=${expiry}&index=${currentIndex}`)
			// dispatch(marketReducer({ type: currentIndex, payload: response?.data[currentIndex] }))
			const _snack = {
				message: "changing Expiry",
				type: "info",
				duration: 500,
				open: true,
			};
			dispatch(showToast(_snack));
			// console.log(market[currentIndex][expiry]);
			dispatch(expiryReducer({ type: currentIndex, payload: expiry }));
			dispatch(
				expiryReducer({
					type: "timeToExpire",
					key: currentIndex,
					payload: moment(expiry).valueOf(),
				})
			);
			market.setCurrentExpiry(expiry);
		} catch (e) {
			console.log("ERR WHILE FECTHING OPTN", e);
		}
	};

	// RANK OPRIONS
	const RANK_OPTIONS = [
		{ key: "pcr", value: "OI PCR" },
		{ key: "cpr", value: "OI CPR" },
		{ key: "index_ltp", value: "INDEX/LTP" },
		{ key: "vol_percent", value: "VOLUME %" },
		{ key: "vol_pcr_cpr", value: "VOL PCR" },
		{ key: "vol_cpr_pcr", value: "VOL CPR" },
		{ key: "vol_1_5_m", value: "VOL 1/5 Min" },
		{ key: "tbso", value: "TBO/TSO" },
		{ key: "theta", value: "Theta" },
		{ key: "bsbb", value: "BS-BB" },
		{ key: "oi_call_put", value: "OI" },
	];

	// SELECT RANK
	const onRankSelect = (value) => {
		try {
			dispatch(rankingReducer({ type: currentIndex, payload: value }));
			// console.log(currentIndex, value)
		} catch (e) {
			console.log(`ERROR --- ${e}`);
		}
	};

	// PLACE INSTANT ORDER
	const placeInstantOrder = async (
		txnType,
		ordType,
		strikePrice,
		row,
		instrument
	) => {
		// setPlacingOrder(true)
		console.log("BEFORE SENDING ORDER -- ", new Date().getTime());
		const _index = currentIndex?.toUpperCase();
		try {
			// console.log(row)
			setPlacingOrder(true);
			const _body = {
				index: market[currentIndex]["tradingsymbol"],
				tradingsymbol: row?.tradingsymbol,
				transaction_type: txnType?.toUpperCase(),
				quantity: market[currentIndex]["qty"],
				product: "MIS",
				order_type: "MARKET",
				instrumentToken: instrument.includes("'")
					? parseInt(instrument.replaceAll("'", ""))
					: instrument,
				// "instrumentToken": market[currentIndex]['instrumentToken'],
				exchange: row?.exchange,
				userId: id,
				...(activeSL === "ltp"
					? {
						ltp_sl: {
							stoploss: SL?.options?.[_index]?.[ordType]?.[txnType]?.sl,
							target: SL?.options?.[_index]?.[ordType]?.[txnType]?.t,
						},
					}
					: {
						stoploss: {
							stoploss: SL?.options?.[_index]?.[ordType]?.[txnType]?.sl,
							target: SL?.options?.[_index]?.[ordType]?.[txnType]?.t,
						},
					}),
			};
			setOrderIntrument(_body?.instrumentToken);
			// console.log(_body);
			const url = "/orders/private/place-order";
			setIsModifying(null);
			// fetchPositions()
			fetchOpenOrders()
			const _snackbar = {
				message: "Placing Order Please wait!",
				open: true,
				duration: 3000,
				type: "info",
			};
			dispatch(showToast(_snackbar));
			const response = await http.post(url, _body);
			setPlacingOrder(false);
			// SHOW SNACKBAR
			const _success = response?.data?.success;
			const _error = response?.data?.error;
			let _orderMessage =
				_error > _success
					? _error + " Orders failed!"
					: _success + " Orders Placed!";
			let _orderStatus = _error > _success ? "error" : "success";
			const _snackbar1 = {
				message: _orderMessage,
				open: true,
				duration: 3000,
				type: _orderStatus,
			};
			dispatch(showToast(_snackbar1));
			setOrderIntrument(0);
		} catch (e) {
			console.log(e, "ERROR WHILE PLACING ORDER!");
			const errorMessage = e?.response?.data?.message?.split(".")[0];
			const _snackbar = {
				message: errorMessage,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
			setPlacingOrder(false);
			setOrderIntrument(0);
		}
	};

	// UPDATE ACTIVE SL STATUS
	const updateStatus = async () => {
		dispatch(updateActiveSL(activeSL === "ltp" ? "index" : "ltp"));
		const _data = activeSL === "ltp" ? "index" : "ltp";
		const _snackbar = {
			message: "SL & T Updated to " + _data,
			open: true,
			duration: 1000,
			type: "success",
		};
		dispatch(showToast(_snackbar));
		const _body = {
			active_sl: activeSL === "ltp" ? "index" : "ltp",
		};
		dispatch(updateAdminAciveSL(id, _body));
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const openMenu = Boolean(anchorEl);

	return (
		<>
			{/* {console.log(market[currentIndex])} */}
			<div className="w-full flex gap-3 px-0 h-full">
				<div className="w-full lg:w-[80%] flex flex-col h-full">
					<OptionHeader
						equillibrium={features?.EQUILIBRIUM_PRICE}
						expiry={memoizedMarket[currentIndex]?.expiry?.slice(0, 5) || []}
						onIndexChange={onIndexChangeEvent}
						currentIndex={currentIndex}
						// market={market[currentIndex]}
						market={memoizedMarket}
						isScrolling={isScrolling}
						setIsScrolling={setIsScrolling}
						activeSL={activeSL}
						onUpdateStatus={() => updateStatus()}
						RANK_OPTIONS={RANK_OPTIONS}
						rankings={rankings}
						onRankSelect={onRankSelect}
						onExpiryChange={onExpiryChange}
						currentExpiry={allCurrentExpiry[currentIndex]}
						allCurrentExpiry={allCurrentExpiry}
						onFormChange={() => {
							setOpenMobilePosition(true);
						}}
					/>
					<div className="hidden lg:flex gap-3 flex-1 overflow-hidden shadow-sm">
						{/* <OptionTable data={market[currentIndex]} onFormChange={onFormChange} /> */}
						<div className="w-full border border-[#9E9E9E24] rounded">
							<OptionTable
								orderInstrument={orderInstrument}
								placingInstantOrder={placingOrder}
								msExpiry={allCurrentExpiry?.timeToExpire[currentIndex]}
								currentExpiry={allCurrentExpiry[currentIndex]}
								data={memoizedMarket[currentIndex]}
								onFormChange={onFormChange}
								admin={admin}
								isScrolling={isScrolling}
								setIsScrolling={setIsScrolling}
								placeInstantOrder={(
									txnType,
									ordType,
									strikePrice,
									row,
									instrument
								) =>
									placeInstantOrder(
										txnType,
										ordType,
										strikePrice,
										row,
										instrument
									)
								}
								currentValue={memoizedMarket[currentIndex]}
								previousTicks={
									memoizedMarket?.previousTicks
										? market?.previousTicks[currentIndex]
										: {}
								}
								isConnected={
									user?.broker?.length ? user?.broker[0]?.active : false
								}
								oiFactor={market.oiFactor}
								onOIFactorUpdated={(value) => marketContext?.setOIFactorContext(value)}
								// onOIFactorUpdated={(value) => { }}
							/>
						</div>
					</div>
					<div className="flex lg:hidden flex-1 px-2 py-1 overflow-hidden shadow-sm">
						<MobileTable
							data={memoizedMarket[currentIndex]}
							onFormChange={onFormChange}
						/>
					</div>
				</div>
				<div className="w-[20%] bg-white hidden py-2 overflow-hidden lg:flex lg:flex-col">
					<div className="relative w-full">
						<Tabs
							value={currentTab}
							onChange={handleTabChange}
							aria-label="position-order-tabs"
						>
							<Tab label="Positions" {...a11yProps(0)} />
							<Tab label="Open Orders" {...a11yProps(1)} />
						</Tabs>

						{currentTab == 0 || currentTab == 1 ? (
							<div className="absolute right-0 top-0">
								<Badge
									badgeContent={positionType == "basket" ? "B" : "S"}
									color="primary"
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
								>
									<IconButton
										onClick={(event) => {
											setAnchorEl(event.currentTarget);
										}}
										aria-label="delete"
										sx={{
											padding: 0
										}}
									>
										<FilterListOutlinedIcon />
									</IconButton>
								</Badge>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={openMenu}
									onClose={() => {
										setAnchorEl(null);
									}}
									MenuListProps={{
										"aria-labelledby": "basic-button",
									}}
								>
									<div className="flex items-center gap-2 p-3">
										<Stack direction="row" spacing={1}>
											<Chip
												onClick={() => {
													dispatch(setPositionType("basket"));
													setAnchorEl(null);
												}}
												color="primary"
												label="Basket"
												size="small"
												variant={
													positionType == "basket" ? "filled" : "outlined"
												}
											/>
											<Chip
												onClick={() => {
													dispatch(setPositionType("strike"));
													setAnchorEl(null);
												}}
												label="Options"
												color="primary"
												size="small"
												variant={
													positionType == "strike" ? "filled" : "outlined"
												}
											/>
										</Stack>
									</div>
								</Menu>
								<IconButton
									className={refresh ? "animate-spin" : ""}
									aria-label="refresh"
									color="buy"
									onClick={() => onRefresh()}
								>
									<RefreshIcon />
								</IconButton>
							</div>
						) : null}
					</div>
					<TabPanel value={currentTab} index={0}>
						{home.activePath == "basket" ? (
							<BasketForm />
						) : (
							<Position
								data={positions}
								onAdd={(pos) => onAdd(pos)}
								onExit={(pos) => onExit(pos)}
								onRefresh={() => onRefresh()}
								refresh={refresh}
								admin={admin}
								market={memoizedMarket}
								activeSL={activeSL}
								lossMsgClick={() => navigate("/home/learn-with-us")}
							/>
						)}
					</TabPanel>
					<TabPanel value={currentTab} index={1}>
						<OpenOrders
							data={openOrders}
							activeSL={activeSL}
							placingOrder={placingOrder}
							onModify={(order) => onModify(order)}
							onCancel={(order) => onCancel(order)}
						/>
					</TabPanel>
				</div>
			</div>

			{openBroker ? (
				<AddBroker
					open={openBroker}
					user={user}
					brokers={brokers}
					response={(err) => setErrors({ ...err })}
					onClose={() => {
						setOpenBroker(false);
					}}
				/>
			) : null}

			{open ? (
				<BottomSheet
					open={open}
					form={formData}
					admin={admin}
					currentIndex={currentIndex}
					currentExpiry={allCurrentExpiry[currentIndex]}
					isLoading={placingOrder}
					onClose={() => {
						setOpen(false);
						setIsModifying(null);
					}}
					isModifying={isModifying}
					placeOrder={(body) => onPlaceOrder(body)}
				/>
			) : null}

			{openMobilePosition ? (
				<MobilePositions
					positions={positions}
					currentTab={currentTab}
					handleTabChange={handleTabChange}
					openOrders={openOrders}
					onAdd={onAdd}
					onExit={onExit}
					open={openMobilePosition}
					onClose={() => setOpenMobilePosition(false)}
					onRefresh={() => onRefresh()}
					refresh={refresh}
					onModify={(order) => onModify(order)}
					onCancel={(order) => onCancel(order)}
				/>
			) : null}

			<Snackbar
				open={error?.open}
				autoHideDuration={5000}
				onClose={() => setErrors({ open: false, message: "" })}
			>
				<Alert
					onClose={() => setErrors({ open: false, message: "", type: "" })}
					severity={error?.type || "error"}
					sx={{ width: "100%" }}
				>
					{error?.message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default Strikes;

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tab - ${index}`}
			aria-labelledby={`tab - ${index}`}
			className="flex-1 overflow-auto"
			{...other}
		>
			{children}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `tab - ${index}`,
		"aria-controls": `tabpanel - ${index}`,
	};
}
