import { Skeleton } from '@mui/material'
import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const SigngleCard = ({
  title,
  index,
  nifty
}) => {
  const current = nifty?.strikePrice - (index?.strikePrice || index?.last_price);
  const close = nifty?.closeStrike - index?.closeStrike;
  const percentValue = ((current - close) / close) * 100;
  const value = (index?.strikePrice || index?.last_price) / nifty?.strikePrice;

  // if (title.includes('INDIA VIX/NIFTY')) {
  //   console.log(current, close, percentValue, value);
  //   console.log(nifty);
  //   console.log(index);
  // }



  return (
    <>
      <div className='bg-white shadow min-w-72 min-h-36 flex items-center justify-center rounded-md shrink-0 transition'>
        <div className=''>
          <h5 className='font-semibold text-[18px] text-[#1C244B94]'>{title}</h5>
          <div className='flex items-center gap-1 '>
            <small className={`${percentValue < 0 ? 'text-[#EB5B3C]' : 'text-[#00A880C7]'} text-[15px] font-semibold`}>
              {percentValue ?
                Math.abs(value).toLocaleString(undefined, { maximumFractionDigits: 2 })
                :
                <Skeleton width={50} variant='text' />
              }
            </small>
            {percentValue ? percentValue < 0 ?
              <ArrowDownwardIcon
                fontSize='small'
                color='sell'
              />
              :
              <ArrowUpwardIcon
                fontSize='small'
                color='buy'
              />
              : <Skeleton width={20} variant='text' />}
            <span className={`${percentValue < 0 ? 'text-[#EB5B3C]' : 'text-[#00A880C7]'} text-[11px] font-semibold`}>
              {percentValue ?
                Math.abs(percentValue).toLocaleString(undefined, { maximumFractionDigits: 2 }) + '%'
                :
                <Skeleton width={50} variant='text' />
              }
            </span>
          </div>
        </div >
      </div >
    </>
  )
}

export default SigngleCard