import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { http } from "../../Services/api.services";
import IhmGraph from "../../PrivateComponents/IhmGraph";
import IHMCHart from "../../PrivateComponents/IHMCHart";
import SLChartComponent from "../../PrivateComponents/SLChartComponent";
import TopVolume from "../../PrivateComponents/TopVolume";
import IndexTable from "../../PrivateComponents/IndexTable";
import CompaniesList from "../../PrivateComponents/CompaniesList";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import { Divider } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import {
  updateCompaniesStockData,
  updateCprPcrtData,
  updateCurrentToolsIndex,
  updateEmaData,
  updateEmaVolumeData,
  updateIhmChartData,
  updateRsiData,
  updateSlChartData,
} from "../../store/Reducer/ToolsReducer";
import { BASE_URL, EVENT_SOURCE_URL } from "../../default.config";
import { useMarketContext } from "../../Contexts/MarketContext/MarketContext";

const RSI = require("technicalindicators").RSI;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const indexes = [
  { type: "nifty", title: "NIFTY" },
  { type: "banknifty", title: "BANK N" },
  { type: "finnifty", title: "FIN N" },
  { type: "midcpnifty", title: "MIDCP N" },
  { type: "sensex", title: "SENSEX" },
  { type: "bankex", title: "BANKEX" },
];
const _intervalTimer = {};

const IndexIndicatorScreen = () => {
  const dispatch = useDispatch();
  const allCurrentExpiry = useSelector((state) => state.allCurrentExpiry);
  const {
    slChartData,
    ihmChartData,
    currentToolsIndex,
    slcInterval,
    ihmInterval,
    rsiData,
    emaVolumeData,
    emaData,
    cprpcrData,
  } = useSelector((state) => state.tools);
  //   const market = useMarketContext();
  // const market = useMarketContext();
  const market = useSelector(state => state.market)
  const spotRef = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];
  const tc = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ];

  const [graphType, setGraphType] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [companiesData, setCompaniesData] = React.useState(null);
  const [emaChange, setEmaChange] = React?.useState(false);
  const [loadingData, setLoadingData] = React?.useState({
    ema: false,
    rsi: false,
    pcr: false,
    volEMA: false
  });
  const [slc, setSLC] = React.useState({
    data: [],
    interval: 1,
    fullscreen: false,
  });

  const [highVolume, setHighVolume] = React.useState({
    call: 0,
    put: 0,
  });


  //   UPDATE THE IHM CHART DATA
  const updateIhmChartDataFunction = (field, value, innerField) => {
    dispatch(updateIhmChartData({ field, value, innerField }));
  };

  //   UPDATE THE SLC CHART DATA
  const updateSlcChartDataFunction = (field, value, innerField) => {
    dispatch(updateSlChartData({ field, value, innerField }));
  };

  //   UPDATE THE EMA  DATA
  const updateEmaDataFunction = (field, value, innerField) => {
    dispatch(updateEmaData({ field, value, innerField }));
  };

  //   UPDATE THE EMA VOLUME  DATA
  const updateEmaVolumeDataFunction = (field, value) => {
    dispatch(updateEmaVolumeData({ field, value }));
  };

  //   UPDATE THE RSI  DATA
  const updateRsiDataFunction = (field, value) => {
    dispatch(updateRsiData({ field, value }));
  };

  //   UPDATE THE CPR/PCR  DATA
  const updateCprPcrDataFunction = (field, value) => {
    dispatch(updateCprPcrtData({ field, value }));
  };

  const fetchIHM = async () => {
    try {
      const _expiry = allCurrentExpiry[currentToolsIndex];
      const response = await http.get(
        `/ds/index/IHM?expiry=${_expiry}&index=${currentToolsIndex}&interval=${ihmChartData?.interval > 1 && ihmChartData?.interval
        }minute`
      );
      if (response.status === 200) {
        // setIHM({ ...ihm, data: response?.data });
        updateIhmChartDataFunction("data", response?.data);
      } else {
        // setIHM({ ...ihm, data: [] });
        updateIhmChartDataFunction("data", []);
      }
    } catch (e) {
      console.log("FAILED TO FETCH IHM DATA");
    }
  };

  /** IHM  CLOSED */

  // GET SLC DATA FOR COMPANIES
  useEffect(() => {
    getSlcData();
  }, []);

  const getSlcData = async () => {
    try {
      const response = await http.get(`${BASE_URL}ds/slc_hitoric`);
      if (response.status == 200) {
        setCompaniesData(response?.data);
        dispatch(updateCompaniesStockData(response?.data));
      }
    } catch (e) {
      console.log(`ERROR WHILE FETCHING SLC DATA ${e}`);
    }
  };

  // GET SLC DATA BY EVENT SOURCE
  useEffect(() => {
    let slcEventSource;
    let _indexes = [
      "NIFTY",
      "BANKNIFTY",
      "FINNIFTY",
      "MIDCPNIFTY",
      "SENSEX",
      "BANKEX",
    ];
    let _intervals = [1, 3, 5, 10, 15, 30, 60];
    try {
      const baseUrl = `${EVENT_SOURCE_URL}ds`;
      const interval = slcInterval > 1 ? slcInterval + "minute" : "minute";
      const slcEventSourceUrl = `${baseUrl}/index/SLC`;

      slcEventSource = new EventSource(slcEventSourceUrl);
      for (let i = 0; i < _indexes.length; i++) {
        for (let j = 0; j < _intervals.length; j++) {
          let eventName = `SLC${_indexes[i]}minute${_intervals[j]}`;
          // console.log(eventName, "evnname");
          slcEventSource.addEventListener(eventName, (event) => {
            const data = JSON.parse(event.data); // Ensure the data is parsed correctly
            // console.log(JSON.parse(event.data), "ssss", eventName);
            // console.log("MESSAGE FROM SSE -- ", data);
            // console.log(data, "data");

            updateSlcChartDataFunction("data", data, eventName);
          });
        }
        // console.log(element, "element");
      }
      // slcEventSource.addEventListener("slc", (event) => {
      //   const data = JSON.parse(event.data); // Ensure the data is parsed correctly
      //   updateSlcChartDataFunction("data", data?.value);
      // });

      slcEventSource.onmessage = (event) => {
        // console.log("MESSAGE FROM SSE -- ", event);
      };

      slcEventSource.onopen = (event) => {
        // console.log("OPEN SSE -- ", event);
      };

      slcEventSource.onerror = (event) => {
        console.log("ERROR SSE -- ", event);
        if (slcEventSource.readyState === EventSource.CLOSED) {
          // console.log("EventSource connection closed.");
        }
      };
    } catch (e) {
      console.log(`ERROR WHILE FETCHING SLC DATA: ${e}`);
    }

    return () => {
      if (slcEventSource) {
        slcEventSource.close();
      }
    };
  }, []);

  // GET IHM DATA BY EVENT SOURCE
  useEffect(() => {
    let ihmEventSource;
    let _indexes = [
      "NIFTY",
      "BANKNIFTY",
      "FINNIFTY",
      "MIDCPNIFTY",
      "SENSEX",
      "BANKEX",
    ];
    let _intervals = [1, 3, 5, 10, 15, 30, 60];
    try {
      const baseUrl = `${EVENT_SOURCE_URL}ds`;
      const ihmEventSourceUrl = `${baseUrl}/ihm`;
      ihmEventSource = new EventSource(ihmEventSourceUrl);

      // const eventName = `${currentToolsIndex?.toUpperCase()}minute${
      //   ihmChartData?.interval
      // }`;
      for (let i = 0; i < _indexes.length; i++) {
        for (let j = 0; j < _intervals.length; j++) {
          let eventName = `${_indexes[i]}minute${_intervals[j]}`;
          // console.log(eventName, "evnname");
          ihmEventSource.addEventListener(eventName, (event) => {
            const data = JSON.parse(event.data); // Ensure the data is parsed correctly
            // console.log(JSON.parse(event.data), "ssss", eventName);
            // console.log("MESSAGE FROM SSE -- ", data);
            updateIhmChartDataFunction("data", data, eventName);
          });
        }
        // console.log(element, "element");
      }

      ihmEventSource.onmessage = (event) => {
        console.log("MESSAGE FROM SSE -- ", event);
      };

      ihmEventSource.onopen = (event) => {
        console.log("OPEN SSE -- ", event);
      };

      ihmEventSource.onerror = (event) => {
        console.log("ERROR SSE -- ", event);
        if (ihmEventSource.readyState === EventSource.CLOSED) {
          console.log("EventSource connection closed.");
        }
      };
    } catch (e) {
      console.log(`ERROR WHILE FETCHING IHM DATA: ${e}`);
    }

    return () => {
      if (ihmEventSource) {
        ihmEventSource.close();
      }
    };
  }, []);

  // console.log(emaData,'shshhsshsh');

  // GET EMA DATA BY EVENT SOURCE
  useEffect(() => {
    let emaEventSource;
    let _indexes = [
      "NIFTY",
      "BANKNIFTY",
      "FINNIFTY",
      "MIDCPNIFTY",
      "SENSEX",
      "BANKEX",
    ];
    let _intervals = [1, 3, 5, 10, 15, 30, 60];
    try {
      const baseUrl = `${EVENT_SOURCE_URL}ds`;
      const emaEventSourseUrl = `${baseUrl}/index/EMA`;
      emaEventSource = new EventSource(emaEventSourseUrl);
      console.log(emaEventSource);

      for (let i = 0; i < _indexes.length; i++) {
        for (let j = 0; j < _intervals.length; j++) {
          let eventName = `EMA${_indexes[i]?.toLocaleUpperCase()}minute${_intervals[j]}`;
          // console.log(eventName, "evnname");
          emaEventSource.addEventListener(eventName, (event) => {
            const data = JSON.parse(event.data); // Ensure the data is parsed correctly
            updateEmaDataFunction("data", data, eventName);
          });
        }
      }

      emaEventSource.onmessage = (event) => {
        console.log("MESSAGE FROM SSE -- ", event);
      };

      emaEventSource.onopen = (event) => {
        console.log("OPEN SSE -- ", event);
      };

      emaEventSource.onerror = (event) => {
        console.log("ERROR SSE -- ", event);
        if (emaEventSource.readyState === EventSource.CLOSED) {
          console.log("EventSource connection closed.");
        }
      };
    } catch (e) {
      console.log(`ERROR WHILE FETCHING IHM DATA: ${e}`);
    }

    return () => {
      if (emaEventSource) {
        emaEventSource.close();
      }
    };
  }, [
    // emaData.interval,
    // currentToolsIndex,
    // allCurrentExpiry[currentToolsIndex],
    // emaChange,
  ]);

  React.useEffect(() => {
    if (allCurrentExpiry[currentToolsIndex]) fetchRSI();
  }, [
    rsiData.interval,
    currentToolsIndex,
    allCurrentExpiry[currentToolsIndex],
  ]);

  const fetchRSI = async () => {
    try {
      setLoadingData({ ...loadingData, rsi: true })
      const _expiry = allCurrentExpiry[currentToolsIndex];
      if (_expiry) {
        const response = await http.get(
          `/ds/index/RSI?expiry=${_expiry}&index=${currentToolsIndex}&${rsiData.interval == 1 ? "" : `interval=${rsiData.interval}minute`
          }`
        );
        if (response?.status === 200) {
          onrsiChange(response?.data);
        }
      }
    } catch (e) {
      console.log("ERROR WHILE FINDING RSI -- ", e);
    }
  };

  const onrsiChange = (data) => {
    try {
      updateRsiDataFunction("data", null);
      const rsiBody = {
        values: [...data, market[currentToolsIndex]?.strikePrice],
        period: data?.length || 14,
      };
      rsiBody.values = rsiBody.values?.reverse();
      if (rsiBody.values.length === data.length + 1) {
        let results = RSI.calculate(rsiBody);
        updateRsiDataFunction("data", results[0]);
        setLoadingData({ ...loadingData, rsi: false })
      }
    } catch (e) {
      updateRsiDataFunction("data", []);
      setLoadingData({ ...loadingData, rsi: false })
      console.log(`RSI CALCULATION ERROR ${e}`);
    }
  };

  React.useEffect(() => {
    if (allCurrentExpiry[currentToolsIndex]) fetchPRC_CPR();
  }, [
    cprpcrData.interval,
    currentToolsIndex,
    allCurrentExpiry[currentToolsIndex],
  ]);

  const fetchPRC_CPR = async () => {
    try {
      updateCprPcrDataFunction("data", null);
      const response = await http.get(
        `/ds/index/CPR/PCR?index=${currentToolsIndex}${cprpcrData.interval > 1
          ? `&interval=${cprpcrData.interval}minute`
          : ""
        }`
      );
      if (response?.status === 200) {
        updateCprPcrDataFunction("data", response?.data);
      }
    } catch (e) {
      updateCprPcrDataFunction("data", null);
      console.log("ERROR WHILE FETCHING PCR CPR -- ", e);
    }
  };

  React.useEffect(() => {
    if (market[currentToolsIndex]?.[allCurrentExpiry[currentToolsIndex]]?.callVolume && market[currentToolsIndex]?.[allCurrentExpiry[currentToolsIndex]]?.putVolume) {
      setHighVolume({
        call: findMaxStrike(
          market[currentToolsIndex]?.[allCurrentExpiry[currentToolsIndex]]?.callVolume,
          market[currentToolsIndex]?.[allCurrentExpiry[currentToolsIndex]]?.options?.CE
        ),
        put: findMaxStrike(
          market[currentToolsIndex]?.[allCurrentExpiry[currentToolsIndex]]?.putVolume,
          market[currentToolsIndex]?.[allCurrentExpiry[currentToolsIndex]]?.options?.PE
        ),
      });
    }
  }, [market[currentToolsIndex]?.[allCurrentExpiry[currentToolsIndex]]?.options]);

  const findMaxStrike = (max, oc) => {
    try {
      const optionChain = Object.keys(oc);
      for (let index = 0; index < optionChain.length; index++) {
        const option = optionChain[index];
        // console.log(option)
        // console.log(oc[option]?.volume == max, oc[option]?.volume < max)
        if (oc[option]?.volumeRank == 1)
          // console.log(oc[option])
          return oc[option];
      }
    } catch (e) {
      console.log("FIND STRIKE ERROR MAX VOLUME -- ", e);
    }
  };

  const refreshMaxVolume = () => {
    if (
      market[currentToolsIndex]?.callVolume &&
      market[currentToolsIndex]?.putVolume
    ) {
      setHighVolume({
        call: findMaxStrike(
          market[currentToolsIndex]?.callVolume,
          market[currentToolsIndex]?.options?.CE
        ),
        put: findMaxStrike(
          market[currentToolsIndex]?.putVolume,
          market[currentToolsIndex]?.options?.PE
        ),
      });
    }
  };

  //EMA VOLUME
  React.useEffect(() => {
    fetchVolumeEma();
  }, [emaVolumeData.interval, currentToolsIndex]);

  const fetchVolumeEma = async () => {
    try {
      setLoadingData({ ...loadingData, volEMA: true })
      const response = await http.get(
        `/ds/index/volume-ema?index=${currentToolsIndex}${emaVolumeData.interval > 1
          ? `&interval=${emaVolumeData.interval}minute`
          : ""
        }`
      );
      if (response?.status === 200) {
        updateEmaVolumeDataFunction("data", response?.data);
        setLoadingData({ ...loadingData, volEMA: false })

      }
    } catch (e) {
      updateEmaVolumeDataFunction("data", []);
      console.log("ERROR WHILE FETCHING VOLUME EMA -- ", e);
      setLoadingData({ ...loadingData, volEMA: false })

    }
  };

  // UPDATE CURRENT SELECTED INDEX
  const updateCurrentIndex = (value, indexType) => {
    dispatch(updateCurrentToolsIndex(indexType));
    setSelectedIndex(value);
  };

  return (
    <>
      <div className="bg-[#FFFFFF] px-2 py-1 flex-1 overflow-hidden flex flex-col">
        {/* <p>{market[currentIndex]?.callVolume}</p> */}
        <div className="w-full flex items-center justify-center gap-3 h-12 ">
          <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
            <Select
              size="small"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedIndex}
            // onChange={handleChange}
            >
              {indexes?.map((_index, index) => (
                <MenuItem
                  key={index}
                  value={index}
                  onClick={() => updateCurrentIndex(index, _index?.type)}
                >
                  {_index.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <h6 className=" text-blue-500 font-bold">Index Indicator</h6>
        </div>
        <div className="w-full flex border border-[#e4dfdf] flex-1 max-h-[60%]">
          {/* IHM CHART */}
          <div className="flex-1 bg-[#FCFAFA] h-full border-r border-r-[#e4dfdf] flex flex-col">
            <IHMCHart
              ihmData={ihmChartData.data}
              interval={ihmChartData.interval}
              setIntervalFunction={(value) =>
                updateIhmChartDataFunction("interval", value)
              }
              setIsFullScreen={() =>
                updateIhmChartDataFunction(
                  "fullscreen",
                  !ihmChartData.fullscreen
                )
              }
              isFullScreen={ihmChartData.fullscreen}
              graphType={graphType}
              setGraphType={setGraphType}
              currentIndex={currentToolsIndex}
            />
          </div>

          {/* <SLChart /> */}
          <div className="flex-1 overflow-hidden flex flex-col">
            <div className="w-full flex mb-2 flex-1">
              <div className="w-3/5  h-full fle flex-col bg-[#F9FBFC]">
                <SLChartComponent
                  slInterval={slChartData.interval}
                  // setIntervalFunction={(value) => {
                  //   setSLC({ ...slc, interval: value });
                  //   updateSlcChartDataFunction("interval", value);
                  // }} 
                  setIntervalFunction={(value) =>
                    updateSlcChartDataFunction("interval", value)
                  }
                  isFullScreen={slChartData.fullscreen}
                  setIsFullScreen={() =>
                    updateSlcChartDataFunction(
                      "fullscreen",
                      !slChartData.fullscreen
                    )
                  }
                  graphType={graphType}
                  setGraphType={setGraphType}
                  slcData={slChartData?.data}
                  companiesData={companiesData}
                  currentIndex={currentToolsIndex}
                />
              </div>
              <div className="flex-1">
                <TopVolume
                  call={highVolume?.call?.strike}
                  callVolume={highVolume?.call?.volume}
                  put={highVolume?.put?.strike}
                  putVolume={highVolume?.put?.volume}
                  onRefresh={() => refreshMaxVolume()}
                />
              </div>
            </div>
            <Divider />
            <div className="w-full overflow-hidden flex-1">
              <IndexTable
                setEmaChange={(value) => setEmaChange(value)}
                loadingData={loadingData}
              />
            </div>
          </div>
        </div>
        <div className="overflow-hidden border my-1 border-[#e4dfdf] flex flex-col flex-1">
          <CompaniesList spotRef={spotRef} tc={tc} />
        </div>

        <div
          className={`w-${ihmChartData?.fullscreen || slChartData?.fullscreen ? "full" : 0
            } h-full bg-[#FFFFFF] flex items-center justify-center flex-col z-auto right-0 fixed top-0`}
        >
          <div className="w-full bg-[#FCFAFA] h-full border-r  border-r-[#e4dfdf] flex flex-col ">
            {ihmChartData.fullscreen ? (
              <IHMCHart
                isFullScreen={ihmChartData?.fullscreen}
                graphType={graphType}
                setGraphType={setGraphType}
                currentIndex={currentToolsIndex}
                interval={ihmChartData.interval}
                setIntervalFunction={(value) =>
                  updateIhmChartDataFunction("interval", value)
                }
                setIsFullScreen={() =>
                  updateIhmChartDataFunction(
                    "fullscreen",
                    !ihmChartData.fullscreen
                  )
                }
                ihmData={ihmChartData?.data}
              />
            ) : slChartData?.fullscreen ? (
              <SLChartComponent
                slInterval={slChartData.interval}
                setIntervalFunction={(value) => {
                  setSLC({ ...slc, interval: value });
                  updateSlcChartDataFunction("interval", value);
                }}
                isFullScreen={slChartData.fullscreen}
                setIsFullScreen={() =>
                  updateSlcChartDataFunction(
                    "fullscreen",
                    !slChartData.fullscreen
                  )
                }
                graphType={graphType}
                setGraphType={setGraphType}
                slcData={slChartData?.data}
                companiesData={companiesData}
                currentIndex={currentToolsIndex}
              />
            ) : null}
          </div>
          {ihmChartData?.fullscreen || slChartData?.fullscreen ? (
            <div className="w-full  flex items-center justify-end pr-1 pb-1">
              <IconButton
                onClick={() => {
                  if (ihmChartData?.fullscreen) {
                    updateIhmChartDataFunction("fullscreen", false);
                  } else if (slChartData?.fullscreen) {
                    updateSlcChartDataFunction("fullscreen", false);
                  }
                }}
                aria-label="delete"
              >
                <FullscreenExitIcon color="gray" className="cursor-pointer" />
              </IconButton>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default IndexIndicatorScreen;
