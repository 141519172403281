import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    nifty: '',
    banknifty: '',
    finnifty: '',
    midcpnifty: '',
    sensex: '',
    bankex: '',
    timeToExpire: {
        nifty: 0,
        banknifty: 0,
        finnifty: 0,
        midcpnifty: 0,
        sensex: 0,
        bankex: 0
    }
}

const ExpiryReducer = (state = initialState, action) => {
    switch (action.payload.type) {
        case 'nifty': return { ...state, nifty: action.payload.payload };
        case 'banknifty': return { ...state, banknifty: action.payload.payload }
        case 'finnifty': return { ...state, finnifty: action.payload.payload }
        case 'midcpnifty': return { ...state, midcpnifty: action.payload.payload }
        case 'sensex': return { ...state, sensex: action.payload.payload }
        case 'bankex': return { ...state, bankex: action.payload.payload }
        case 'timeToExpire': return { ...state, timeToExpire: { ...state.timeToExpire, [action.payload.key]: action.payload.payload / 1000 / 60 / 60 / 24 / 365 } }
    }
}

const ExpriryData = createSlice({
    name: 'allCurrentExpiry',
    initialState: initialState,
    reducers: {
        expiryReducer: ExpiryReducer
    }
})

export const { expiryReducer } = ExpriryData.actions;
export default ExpriryData.reducer;