import { useSelector } from "react-redux";
import { http } from "../../Services/api.services";
import { setAdmin, updateSnackbar } from "../Reducer/AdminReducer";
import { getCurrentIndex, getStatus, getExpiry, getCurrentExpiry, getBrokerConnected, getBrokers, updateActiveSL } from "../Reducer/Reducer";
import { slReducer, updateCurrentSL } from "../Reducer/SLReducer";
import { updateOpenOrders, updatePositions } from "../Reducer/OrderReducer";


export const asyngetCurrentIndex = (data) => async (dispatch, getState) => {
    try {
        dispatch(getCurrentIndex(data));
    } catch (e) {
        console.log(`ERRRO IN UPDATE MAX VOLUME OI --- ${e}`)
    }
}

export const asyngetStatus = (data) => async (dispatch, getState) => {
    try {
        dispatch(getStatus(data));
    } catch (e) {
        console.log(`ERRRO IN UPDATE MAX VOLUME OI --- ${e}`)
    }
}

export const asyngetCurrentExpiry = (data) => async (dispatch, getState) => {
    try {
        dispatch(getCurrentExpiry(data));
    } catch (e) {
        console.log(`ERRRO IN UPDATE MAX VOLUME OI --- ${e}`)
    }
}

export const asyngetExpiry = (data) => async (dispatch, getState) => {
    try {
        dispatch(getExpiry(data));
    } catch (e) {
        console.log(`ERRRO IN UPDATE ALL EXPIRY --- ${e}`)
    }
}

export const asyngetJsonData = (data) => async (dispatch, getState) => {
    try {
        const response = await fetch('../option.data.json');
        // console.log(response);
        // dispatch(getCurrentIndex(data));
    } catch (e) {
        console.log(`ERRRO IN UPDATE MAX VOLUME OI --- ${e}`)
    }
}

export const asyncupdateBroker = (data) => async (dispatch, getState) => {
    try {
        dispatch(getBrokerConnected(data))
    } catch (e) {
        console.log(`ERRRO IN UPDATE BROCKER CONNECTED --- ${e}`)
    }
}

export const reduxUpdateBrokers = (data) => async (dispatch, getState) => {
    try {
        dispatch(getBrokers(data))
    } catch (e) {
        console.log(`ERRRO IN UPDATE BROKERS --- ${e}`)
    }
}


export const showToast = (data) => async (dispatch, getState) => {
    try {
        dispatch(updateSnackbar(data))
    }
    catch (e) {
        console.log('ERROR IN ACTION.JSX UPDATETOAST')
    }
}

export const setAdminRedux = (data) => async (dispatch, getState) => {
    try {
        dispatch(setAdmin(data))
    }
    catch (e) {
        console.log('ERROR WHILE SETTING ADMIN IN REDUX')
    }
}

export const updateAdminAciveSL = (_id, _data) => async (dispatch, getState) => {
    try {
        const { activeSL } = getState().optionHome;
        const response = await http.post(`/admin/fetch-admin/${_id}`, _data)
        if (response?.status == 200) {
            dispatch(updateActiveSL(response?.data[0]?.active_sl));
        } else {
            dispatch(updateActiveSL(activeSL === 'ltp' ? 'index' : 'ltp'))
        }
    }
    catch (e) {
        console.log('ERROR WHILE UPDATING ACTIVESL', e)
    }
}

export const updateUserAciveSL = (_id, _data) => async (dispatch, getState) => {
    try {
        const response = await http.post(`/user/${_id}`, _data)
        dispatch(updateActiveSL(response?.data[0]?.active_sl));
    }
    catch (e) {
        console.log('ERROR WHILE UPDATING ACTIVESL', e)
    }
}

export const updateSL = (role, id, type) => async (dispatch, getState) => {
    try {
        const STOPLOSS_TARGET_URL = `/settings/sltp/${role}/${id}`
        const response = await http.get(`${STOPLOSS_TARGET_URL}?type=${type}`)
        dispatch(slReducer({ type: 'updateSL', payload: response.data[0] }));
        // console.log(response.data[0].options);
        // dispatch(updateCurrentSL(response.data[0]));
    }
    catch (e) {
        console.log('ERROR WHILE UPDATING SL', e)
    }
}

export const saveSL = (_body) => async (dispatch, getState) => {
    try {
        const response = await http.post(`/sltp`, _body)
        dispatch(slReducer({ type: 'updateSL', payload: response.data[0] }));
        // console.log(response.data[0].options);
        // dispatch(updateCurrentSL(response.data[0]));
    }
    catch (e) {
        console.log('ERROR WHILE UPDATING SL', e)
    }
}


export const setPositions = (positions) => async (dispatch, getState) => {
    try {
        dispatch(updatePositions(positions))
    }
    catch (e) {
        console.log('ERROR WHILE POSITION REDUX SET -- ', e)
    }
}

export const setOpenOrders = (positions) => async (dispatch, getState) => {
    try {
        dispatch(updateOpenOrders(positions))
    }
    catch (e) {
        console.log('ERROR WHILE POSITION REDUX SET -- ', e)
    }
}